import React, { useEffect, useState } from "react";
import { Icon, Typography, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Collapse, Divider, IconButton, LinearProgress } from "@mui/material";
import BoxDX from "../../layout/boxdx";
import GridDX from "../../layout/griddx";
import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import DeviceHubOutlinedIcon from '@mui/icons-material/DeviceHubOutlined';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { useTranslation } from "react-i18next";
import useCabinetService from "../../../shared/services/cabinetservice";
import { useNotificationContext } from "../../../context/notificationcontext";
import useFolderService from "../../../shared/services/folderservice";
import Loading from "../../loading";
import useSharingService from "../../../shared/services/sharedservice";

const NavigationPanel = (props: any) => {
  const { t, i18n } = useTranslation();
  const { setError } = useNotificationContext();
  const { getCabinets, getArchivedContentByCabinetID } = useCabinetService();
  const { getFolderTreeByCabinet } = useFolderService();
  const { getSharedContent } = useSharingService();

  const [selectedIndex, setSelectedIndex] = useState<any>(null);
  const [cabinets, setCabinets] = useState<any>([]);
  const [cabinetTree, setCabinetTree] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingShared, setIsLoadingShared] = useState(false);

  useEffect(()=>{
    getData();
  }, []);

  useEffect(()=>{
    refreshTree(selectedIndex);
  }, [props.tree]);  

  const getData = async () => {
    // setIsLoading(true);
    getCabinets()
      .then( async (res: any) => {
        setCabinets(res);
        setSelectedIndex(res[0].cabinetId);
        props.toOpenTrash(null);
        props.toOpenShared(null);
        getTree(res[0]);
      })
      .catch((err: any) => setError(err));
    // .finally(() => setIsLoading(false));
  };

  const getTree = async (cabinet: any) => {
    setIsLoading(true);
    getFolderTreeByCabinet(cabinet.cabinetId)
      .then((res: any) => {
        props.onFolderChange(null);
        props.onCabinetChange(cabinet, res);
        props.toOpenTrash(null);
        props.toOpenShared(null);
      })
      .catch((err: any) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const refreshTree = async (cabinetId: number) => {
    if (selectedIndex) {
      setIsLoading(true);
      getFolderTreeByCabinet(cabinetId)
        .then((res: any) => setCabinetTree(res))
        .catch((err: any) => setError(err))
        .finally(() => setIsLoading(false));}
  };

  const getTrash = async (cabinetId: number) => {
    setIsLoading(true);
    getArchivedContentByCabinetID(cabinetId)
      .then((res: any) => {
        props.onFolderChange(null);
        props.toOpenTrash(res);
      })
      .catch((err: any) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const getShared = async () => {
    setIsLoadingShared(true);
    getSharedContent()
      .then((res: any) => {
        props.onFolderChange(null);
        props.toOpenShared(res);
      })
      .catch((err: any) => setError(err))
      .finally(() => setIsLoadingShared(false));
  };

  return (
    <GridDX
      item
      xs={2}
      sx={{
        overflowX: "hidden",
        flexDirection: "column",
        height: "90%",
        borderRight: (i18n.language === "en" ? "1px solid #dcdcdc" : "0"),
        borderLeft: (i18n.language === "en" ? 0 : "1px solid #dcdcdc")
      }}
    >
      <BoxDX
        sx={{
          height: 50,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          borderBottom: "1px solid #dcdcdc",
        }}
      >
        <Icon>
          <DeviceHubOutlinedIcon />
        </Icon>
        <Typography fontSize={18} fontWeight={"bold"} textAlign={"center"}>
          {t("Directories")}
        </Typography>
      </BoxDX>
      <List
        sx={{ width: "100%", direction: i18n.dir(), maxHeight: 800 }}
        disablePadding
      >
        {(
          cabinets.map((item: any, index: number) => (
            <React.Fragment key={index}>
              <ListItem disablePadding>
                <ListItemButton
                  selected={item.cabinetId === selectedIndex && !props.shared}
                  onClick={() => {
                    setSelectedIndex(item.cabinetId);
                    getTree(item);
                  }}
                >
                  <ListItemIcon>
                    <DnsOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      i18n.language === "en" ? item.enName : item.arName
                    }
                    primaryTypographyProps={
                      (item.cabinetId === selectedIndex)
                        ? { textAlign: "start", fontWeight: "bold" }
                        : { textAlign: "start" }
                    }
                  />
                </ListItemButton>
              </ListItem>
              <Collapse in={item.cabinetId === selectedIndex} timeout="auto" unmountOnExit sx={i18n.language === "en" ? {pl: 3} : {pr: 3}}>
                <List component="div" disablePadding sx={i18n.language === "en" ? {borderLeft: 1} : {borderRight: 1}}>
                  {isLoading ? (
                    <LinearProgress />
                  ) : (
                    cabinetTree.map((folder: any,index:number) => (
                      <SubDirectories key={index} directory={folder} folder={props.folder} onFolderChange={props.onFolderChange} />
                    ))
                  )}
                  <ListItem disablePadding>
                    <ListItemButton
                      selected={props.trash}
                      onClick={() => getTrash(selectedIndex)}
                    >
                      <ListItemText
                        primary={t("Trash")}
                        primaryTypographyProps={
                          (props.trash)
                            ? { textAlign: "start", fontWeight: "bold" }
                            : { textAlign: "start" }
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Collapse>
              <Divider />
            </React.Fragment>
          ))
        )}
        <ListItem disablePadding>
          <ListItemButton
            selected={props.shared}
            onClick={getShared}
          >
            <ListItemIcon>
              <ShareOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primary={t("Shared with me")}
              primaryTypographyProps={
                (props.shared)
                  ? { textAlign: "start", fontWeight: "bold" }
                  : { textAlign: "start" }
              }
              secondaryTypographyProps={{ textAlign: "start" }}
            />
          </ListItemButton>
        </ListItem>
        {isLoadingShared && <LinearProgress />}
      </List>
    </GridDX>
  )
};

const SubDirectories = (props: any) => {
  const { i18n } = useTranslation();
  const [open, setOpen] = useState(false);

  if (props.directory.length === 0) return (<></>);
  else return (
    <>
      <ListItem disablePadding>
        <ListItemButton
          selected={props.folder?.folderId === props.directory.folderId}
          onClick={() => {
            props.onFolderChange(props.directory);
          }}
        >
          <ListItemText
            primary={
              i18n.language === "en" ? props.directory.enName : props.directory.arName
            }
            primaryTypographyProps={
              (props.folder?.folderId === props.directory.folderId)
                ? { textAlign: "start", fontWeight: "bold" }
                : { textAlign: "start" }
            }
          />
        </ListItemButton>
        <IconButton
          edge="end"
          sx={{mx: 1}}
          onClick={() => {
            setOpen(!open);
          }}
        >
          {props.directory.folders.length > 0 && (open ? <ExpandLess /> : <ExpandMore />)}
        </IconButton>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit sx={i18n.language === "en" ? {pl: 3} : {pr: 3}}>
        <List component="div" disablePadding sx={i18n.language === "en" ? {borderLeft: 1} : {borderRight: 1}}>
          {props.directory.folders.map((subDirectory: any, index: number) => (
            <SubDirectories key={index} directory={subDirectory} folder={props.folder} onFolderChange={props.onFolderChange} />
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default NavigationPanel;
