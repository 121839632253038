import { GridColDef } from "@mui/x-data-grid";
import ListPageDX from "../../../business/listpagedx";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import UsersModal from "../../../editmodals/DMS/usersmodal";
import { useNotificationContext } from "../../../../context/notificationcontext";
import useFolderSharingService from "../../../../shared/services/foldershareservice";
import { DateSorter, DateTimeFormatter } from "../../../../shared/globals";
import { useAuthContext } from "../../../../context/authcontext";

const FolderShare = (props: any) => {
  const { t, i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";
  const { userData } = useAuthContext();
  const { calendarId, gregorianDateFormatId, hijriDateFormatId } = userData;
  const { setError, setInfo } = useNotificationContext();
  const { addFolderSharing, getSharingsByFolderID, deleteFolderSharing } = useFolderSharingService();
  
  const columns: GridColDef[] = [
    {
      field: "user",
      headerName: `${t("User")}`,
      flex: 1,
      valueGetter: (params: any) => {
        return languageIsEn
          ? params.row.sharedToUser.enFullName
          : params.row.sharedToUser.arFullName;
      },
    },
    {
      field: "createdByUser",
      headerName: `${t("Created By")}`,
      flex: 1,
      valueGetter: (params: any) => {
        return languageIsEn
          ? params.row.sharedByUser.enFullName
          : params.row.sharedByUser.arFullName;
      },
    },
    {
      field: "createdDate",
      headerName: `${t("Date Added")}`,
      flex: 1,
      valueGetter(params) {
        return DateTimeFormatter(
          params.row.createdDate,
          calendarId,
          gregorianDateFormatId,
          hijriDateFormatId
        );
      },
      sortComparator: DateSorter,
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [rows, setRows] = useState([]);
  const [sharings, setSharings] = useState<any>([]);
  const [openUsers, setOpenUsers] = useState(false);

  useEffect(()=>{
    getData();
  }, []);

  const getData = async () => {
    setIsLoading(true);
    getSharingsByFolderID(props.folderId)
    .then((response) => {
      setRows(response);
      setSharings(response);
    })
    .catch((err) => setError(err))
    .finally(() => setIsLoading(false));
  };

  const onDelete = (id: number) => {
    setIsLoading(true);
    deleteFolderSharing(id)
      .then(() => {
        setInfo(t("User deleted successfully"));
        getData();
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const toCreate = () => {
    setOpenUsers(!openUsers);
  };

  const toSave = async (users: any) => {
    const data = users.map((user: any) => {
      return user.value;
    }); 
    
    setIsSaving(true);
      addFolderSharing(props.folderId, data).then(() => {
        setInfo(t("Folder shared successfully"));
        onClose(true);
      })
      .catch((err) => setError(err))
      .finally(() => setIsSaving(false));
  };

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(sharings);
      return;
    }

    const lowercasedValue = value.toLowerCase();

    const newRows = sharings.filter(({ sharedToUser, sharedByUser }: any) =>
      languageIsEn
        ? sharedToUser.enFullName.toLowerCase().includes(lowercasedValue) ||
          sharedByUser.enFullName.toLowerCase().includes(lowercasedValue)
        : sharedToUser.arFullName.includes(value) ||
          sharedByUser.arFullName.includes(value)
    );
    setRows(newRows);
  };

  const onClose = (refreshPage: boolean = false) => {
    setOpenUsers(false);

    if (refreshPage) getData();
  };

  return(
    <>
    {openUsers && (
      <UsersModal
        open={openUsers}
        handleClose={() => setOpenUsers(false)}
        followers={rows.map((item: any) => item.sharedToUser.id)}
        toSave={toSave}
        isSaving={isSaving}
      />
    )}
    <ListPageDX
      listTitle={t("Folder Share")}
      name={t("Folder Share")}
      createLabel={t("Share")}
      rows={rows}
      columns={columns}
      getRowId={(row: any) => row.folderSharingId}
      isLoading={isLoading}
      exportToPDF={false}
      exportToCSV={false}
      toCreate={toCreate}
      onDelete={onDelete}
      setGridFilterCriteria={setGridFilterCriteria}
    />
  </>
  );
};

export default FolderShare;