import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import ListPageDX from "../../../components/business/listpagedx";
import { useNotificationContext } from "../../../context/notificationcontext";
import { DateSorter, DateTimeFormatter } from "../../../shared/globals";
import StructureTemplateModal from "../../../components/editmodals/DMS/structureTemplateModal";
import DocumentProfileModal from "../../../components/editmodals/DMS/documentProfileModal";
import useDocumentProfileService from "../../../shared/services/documentprofileservice";
import { useAuthContext } from "../../../context/authcontext";

const DocumentProfiles = () => {
  const { t, i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";
  const { getDocumentProfiles, deleteDocumentProfile } =
    useDocumentProfileService();

  const { userData } = useAuthContext();
  const { calendarId, gregorianDateFormatId, hijriDateFormatId } = userData;
  const { setInfo, setError } = useNotificationContext();
  const [documentProfileId, setDocumentProfileId] = useState<number | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState<any>([]);
  const [documentProfiles, setDocumentProfiles] = useState<any>([]);

  const columns: GridColDef[] = [
    {
      field: languageIsEn ? "enName" : "arName",
      headerName: `${t("Name")}`,
      flex: 1,
    },
    {
      field: "createdDate",
      headerName: `${t("Created at")}`,
      flex: 1,
      sortComparator: DateSorter,
    },
    {
      field: "modifiedDate",
      headerName: `${t("Last modified at")}`,
      flex: 1,
      sortComparator: DateSorter,
    },
  ];

  const getData = () => {
    setIsLoading(true);
    getDocumentProfiles()
      .then((profiles) => {
        const preprocessedData = profiles.map((entity: any) => {
          return {
            ...entity,
            createdDate: DateTimeFormatter(
              entity.createdDate,
              calendarId,
              gregorianDateFormatId,
              hijriDateFormatId
            ),
            modifiedDate: DateTimeFormatter(
              entity.modifiedDate,
              calendarId,
              gregorianDateFormatId,
              hijriDateFormatId
            ),
          };
        });

        setDocumentProfiles(preprocessedData);
        setRows(preprocessedData);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onDelete = (id: number) => {
    setIsLoading(true);

    deleteDocumentProfile(id)
      .then(() => {
        setInfo(t("Document profile deleted successfully"));
        getData();
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onEdit = (documentProfile: any) => {
    setOpen(!open);
    setDocumentProfileId(documentProfile.documentProfileId);
  };

  const toCreate = () => {
    setOpen(!open);
  };

  const onClose = (refreshPage: boolean = false) => {
    setOpen(!open);
    setDocumentProfileId(null);

    if (refreshPage) getData();
  };

  useEffect(() => {
    getData();
  }, [i18n.language]);

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(documentProfiles);
      return;
    }
    const lowercasedValue = value.toLowerCase();
    const newRows = documentProfiles.filter(
      ({ enName, arName, createdByUser }: any) =>
        languageIsEn
          ? enName?.toLowerCase().includes(lowercasedValue)
          : // ||  createdByUser?.enFullName?.toLowerCase().includes(lowercasedValue)
            arName?.includes(value)
      // ||  createdByUser?.arFullName?.includes(value)
    );
    setRows(newRows);
  };
  return (
    <>
      {open && (
        <DocumentProfileModal
          open={open}
          handleClose={onClose}
          documentProfileId={documentProfileId}
          rows={rows}
          setRows={setRows}
        />
      )}
      <ListPageDX
        listTitle={t("Document Profiles")}
        name={t("Document Profile")}
        rows={rows}
        columns={columns}
        getRowId={(row: any) => row.documentProfileId}
        isLoading={isLoading}
        toCreate={toCreate}
        onEdit={onEdit}
        onDelete={onDelete}
      />
    </>
  );
};

export default DocumentProfiles;
