import JsBarcode from "jsbarcode";
import moment from "moment";
import momentHijri from "moment-hijri";
import arMoment from "../locales/arMoment";
import i18n from "../components/multilingual/i18n";
import { createWorker } from "tesseract.js";

export const DOC_SIZE = 20;
export const minimumDocs = 1;
export const maximumDocs = 3;

export enum CorrespondenceStatus {
  Draft = 1,
  Pending = 2,
  Reviewed = 3,
  Approved = 4,
  Rejected = 5,
  Recall = 6,
}

// gregorian date
export const DATE_FORMAT = "YYYY-MM-DD";
export const DATE_FORMAT_LONG = "MMM-DD-YYYY";

// gregorian date and time
export const DATETIME_FORMAT = "YYYY-MM-DD, HH:mm";
export const DATETIME_FORMAT_LONG = "MMM-DD-YYYY, HH:mm";

//hijri date
export const HIJRI_DATE_FORMAT = "iYYYY-iMM-iDD";
export const HIJRI_DATE_FORMAT_LONG = "iYYYY iMMMM iDD";

// hijri date and time
export const HIJRI_DATETIME_FORMAT = "iYYYY-iMM-iDD, HH:mm ";
export const HIJRI_DATETIME_FORMAT_LONG = "iMMMM iDD iYYYY, HH:mm ";

// format of date and time to pass in API
export const API_DATETIME_FORMAT = "YYYY-MM-DDTHH:mm:ss.SS";
export const API_DATE_FORMAT = "YYYY-MM-DD";

export function dataURLtoBlob(dataurl: any) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

export function validatePassword(pass: any) {
  const isContainsUppercase = /^(?=.*[A-Z])/;
  const isValidLength = /^.{8,}$/;
  const isContainsNumber = /^(?=.*[0-9])/;
  const isContainsSymbol = /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])/;
  const isContainsLowercase = /^(?=.*[a-z])/;

  if (!isValidLength.test(pass)) {
    return "Password must be 8 Characters Long.";
  } else if (!isContainsNumber.test(pass)) {
    return "Password must have at least one Digit.";
  } else if (!isContainsUppercase.test(pass)) {
    return "Password must have at least one Uppercase Character.";
  } else if (!isContainsLowercase.test(pass)) {
    return "Password must have at least one Lowercase Character.";
  } else if (!isContainsSymbol.test(pass)) {
    return "Password must contain at least one Special Symbol.";
  } else return "";
}

export function toBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export const formattedNumber = (numberValue: number) => {
  return Intl.NumberFormat().format(numberValue);
};

export const handleCall = (event: any, number: string = "021111825238") => {
  const win: any = window;

  if (win?.ReactNativeWebView)
    win.ReactNativeWebView.postMessage(
      JSON.stringify({ type: "call", data: number })
    );
  else window.alert("No dialer available");
};

export const requestLocationPermissionFromDevice = () => {
  const win: any = window;

  if (win?.ReactNativeWebView)
    win.ReactNativeWebView.postMessage(
      JSON.stringify({ type: "AskLocationPermission", data: "" })
    );
};

export const requestCameraStoragePermissionFromDevice = () => {
  const win: any = window;

  if (win?.ReactNativeWebView)
    win.ReactNativeWebView.postMessage(
      JSON.stringify({ type: "AskCameraStoragePermission", data: "" })
    );
};

export const intimateDeviceForFBSetup = () => {
  const win: any = window;

  if (win?.ReactNativeWebView)
    win.ReactNativeWebView.postMessage(
      JSON.stringify({ type: "setUpFirebase" })
    );
};

/**
 * Get the distance in kilometers between two coordinates.
 * @param lat1 Latitude of the first coordinate, in degrees
 * @param long1 Longitude of the first coordinate, in degrees
 * @param lat2 Latitude of the second coordinate, in degrees
 * @param long2 Longitude of the second coordinate, in degrees
 * @return Distance in kilometers
 */
export const getGeoDistance = (
  lat1: number,
  long1: number,
  lat2: number,
  long2: number
) => {
  const earthRadius: number = 6371; // Approximate radius of the earth in kilometers
  const distance =
    Math.acos(
      Math.sin((lat2 * Math.PI) / 180.0) * Math.sin((lat1 * Math.PI) / 180.0) +
        Math.cos((lat2 * Math.PI) / 180.0) *
          Math.cos((lat1 * Math.PI) / 180.0) *
          Math.cos(((long1 - long2) * Math.PI) / 180.0)
    ) * earthRadius;

  return Math.round(distance);
};

export const openURLInBrowser = (url: string) => {
  if (url) window.open(url, "_new");
};

export const pad = (num: any) => {
  return ("0" + num).slice(-2);
};

export const formatTimerToMinAndSecs = (seconds: any) => {
  return pad(Math.floor(seconds / 60)) + ":" + pad(seconds % 60);
};

export const EmailTypeList = [
  {
    text: "Work Email",
    value: 1,
  },
  {
    text: "Personal Email",
    value: 2,
  },
];

export const ContactTypeList = [
  {
    text: "Work Phone",
    value: 1,
  },
  {
    text: "Personal Phone",
    value: 2,
  },
];

export class RegularExpressions {
  static readonly emailAddress: RegExp =
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  static readonly onlyLettersAndSpacesEngArabic: RegExp =
    /^[A-Za-z\u0621-\u064A\s]*$/;
  static readonly onlyLettersAndSpaces: RegExp = /^[A-Za-z\s]*$/;
  static readonly onlyLettersAndSpacesAndNumbers: RegExp = /^[A-Za-z0-9\s]*$/;
  static readonly address: RegExp = /^[A-Za-z0-9\\\/\s]*$/;
  static readonly onlyLetters: RegExp = /^[A-Za-z]*$/;
}

export const base64toBlob = async (data: string) => {
  const base64Response = await fetch(data);
  const blob = await base64Response.blob();

  return blob;
};

export const DateTimeFormatter = (
  date: any,
  calendarId: number | undefined,
  gregorianDateFormatId: number | undefined,
  hijriDateFormatId: number | undefined
) => {
  //  moment.locale("ar", arMoment);

  if (date) {
    if (calendarId === 1 && gregorianDateFormatId === 1) {
      return moment(date).locale(i18n.language).format(DATETIME_FORMAT); // Gregorian date
    } else if (calendarId === 1 && gregorianDateFormatId === 2) {
      return moment(date).locale(i18n.language).format(DATETIME_FORMAT_LONG); // Gregorian date
    } else if (calendarId === 2 && hijriDateFormatId === 1) {
      return momentHijri(date)
        .locale(i18n.language)
        .format(HIJRI_DATETIME_FORMAT); // Gregorian date
    } else if (calendarId === 2 && hijriDateFormatId === 2) {
      console.log(momentHijri.locales());
      const hijriDate = momentHijri(date)
        .locale(i18n.language)
        .format(HIJRI_DATETIME_FORMAT_LONG);
      return hijriDate; // Hijri date
    }
  }
};

export const DateFormatter = (
  date: any,
  calendarId: number | undefined,
  gregorianDateFormatId: number | undefined,
  hijriDateFormatId: number | undefined
) => {
  //moment.locale("ar", arMoment);

  if (date) {
    if (calendarId === 1 && gregorianDateFormatId === 1) {
      return moment(date).locale(i18n.language).format(DATE_FORMAT); // Gregorian date
    } else if (calendarId === 1 && gregorianDateFormatId === 2) {
      return moment(date).locale(i18n.language).format(DATE_FORMAT_LONG); // Gregorian date
    } else if (calendarId === 2 && hijriDateFormatId === 1) {
      return momentHijri(date).locale(i18n.language).format(HIJRI_DATE_FORMAT); // Gregorian date
    } else if (calendarId === 2 && hijriDateFormatId === 2) {
      const hijriDate = momentHijri(date)
        .locale(i18n.language)
        .format(HIJRI_DATE_FORMAT_LONG);
      return hijriDate; // Hijri date
    }
  }
};

export const APIDateFormatter = (date: any) => {
  const thisDate = new Date(date);
  const year = date != null && thisDate?.getFullYear();
  const month =
    date != null && String(thisDate.getMonth() + 1).padStart(2, "0");
  const day = date != null && String(thisDate.getDate()).padStart(2, "0");
  return `${year}${month}${day}`;
};

export const DateSorter = (a: string, b: string) => {
  return new Date(a).getTime() - new Date(b).getTime();
};

export const downloadPDF = (props: any) => {
  const { setIsPdfGenerating, toPDF, setRowsPerPage } = props;

  try {
    setIsPdfGenerating(true); // Show the loader

    setTimeout(async () => {
      await toPDF();
      setIsPdfGenerating(false);
      setRowsPerPage && setRowsPerPage(10);
    }, 0);
  } catch (error) {
    // Handle any errors here
    console.error("Error generating PDF:", error);
    setIsPdfGenerating(false); // Ensure the loader is hidden in case of an error
  }
};

export const convertToTwoDigits = (n: number) => {
  let num = String(n);
  if (num.length === 1) num = "0" + num;
  return num;
};

export const textToBase64Barcode = (text: string) => {
  var canvas = document.createElement("canvas");
  JsBarcode(canvas, text, { displayValue: false });
  return canvas.toDataURL("image/png");
};

export const singleImageOCR = async (img: any) => {
  const worker = await createWorker("eng+ara", undefined, {
    workerPath: "/tesseract-resources/worker.min.js",
    corePath: "/tesseract-resources/tesseract-core-simd-lstm.wasm.js",
    langPath: "/tesseract-resources/traindata",
  });

  const {
    data: { text },
  } = await worker.recognize(img);

  console.log(text);
  await worker.terminate();

  return text;
};

export const multipleImageOCR = async (images: any) => {
  const worker = await createWorker("eng+ara", undefined, {
    workerPath: "/tesseract-resources/worker.min.js",
    corePath: "/tesseract-resources/tesseract-core-simd-lstm.wasm.js",
    langPath: "/tesseract-resources/traindata",
  });

  let content = "";

  for (let img of images) {
    const {
      data: { text },
    } = await worker.recognize(img);
    content += text;
  }

  console.log(content);
  await worker.terminate();

  return content;
};

export const statusColors = [
  "#FFC107",
  "#FF9800",
  "#2196F3",
  "#4CAF50",
  "#F44336",
  "#9C27B0",
  "#607D8B",
];

export const typeColors = ["#2196F3", "#4CAF50", "#FF9800"];

const setPrint = (file: any) => {
  const closePrint = () => {
    document.body.removeChild(file);
  };
  file.contentWindow.onbeforeunload = closePrint;
  file.contentWindow.onafterprint = closePrint;
  file.contentWindow.print();
};

export const printFile = async (file: any) => {
  const hideFrame = document.createElement("iframe");
  hideFrame.onload = () => setPrint(hideFrame);
  hideFrame.style.display = "none"; // hide iframe
  hideFrame.src = file;
  document.body.appendChild(hideFrame);
};

export const getMIMEType = (fileExtension: any) => {
  const officeMIME = "application/vnd.openxmlformats-officedocument";
  if (fileExtension === ".pdf") return "application/pdf";
  else if ([".png", ".jpg", ".jpeg"].includes(fileExtension))
    return `image/${fileExtension.split(".")[1]}`;
  else if (fileExtension === ".docx")
    return `${officeMIME}.wordprocessingml.document`;
  else if (fileExtension === ".xlsx")
    return `${officeMIME}.spreadsheetml.sheet`;
  else if (fileExtension === ".pptx")
    return `${officeMIME}.presentationml.presentation`;
};
