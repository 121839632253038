import useSecureAPI from "../hooks/usesecureapi";

const useEntityTypeService = () => {
  const secureAPI = useSecureAPI();

  const getEntityTypes = async () => {
    const response = await secureAPI.get("EntityType");
    return response.data;
  };

  const getEntityTypeById = async (id: number) => {
    const response = await secureAPI.get(`EntityType/${id}`);
    return response.data;
  };

  const addType = async (data: any) => {
    const response = await secureAPI.post("EntityType", data);
    return response.data;
  };

  const updateType = async (id: number, data: any) => {
    const response = await secureAPI.put(`EntityType`, data);
    return response.data;
  };

  const deleteType = async (id: number) => {
    const response = await secureAPI.delete(`EntityType/${id}`);
    return response.data;
  };

  return {
    getEntityTypes,
    getEntityTypeById,
    addType,
    updateType,
    deleteType,
  };
};

export default useEntityTypeService;
