import React from "react";
import { LoadingButton } from "@mui/lab";

const LoadingButtonDX = (props: any) => {
  return (
    <LoadingButton
      fullWidth
      variant="contained"
      {...props}
      sx={{
        maxWidth: props.fullWidth ? "100%" : 200,
        height: "max-content",
        ...props.sx,
      }}
    />
  );
};

export default LoadingButtonDX;
