import { GridColDef } from "@mui/x-data-grid";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ListPageDX from "../../../business/listpagedx";
import LinkedItemModal from "../../../editmodals/DMS/linkeditemmodal";

const FileLinkedItems = (props: any) => {
  const { t } = useTranslation();

  const rows = [
    {
      id: 0,
      name: "test 1",
      type: "Correspondence"
    },
  ];
  
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: `${t("Name")}`,
      flex: 1,
    },
    {
      field: "type",
      headerName: `${t("Type")}`,
      flex: 1,
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  return(
    <>
    {open && (
      <LinkedItemModal
        open={open}
        handleClose={() => setOpen(false)}
        correspondenceId={props.correspondenceId}
      />
    )}
    <ListPageDX
      listTitle={t("Linked Items")}
      name={t("Linked Items")}
      createLabel={t("Link Item")}
      rows={rows}
      columns={columns}
      getRowId={(row: any) => row.id}
      isLoading={isLoading}
      exportToPDF={false}
      exportToCSV={false}
      toCreate={() => setOpen(true)}
      onDelete={()=>{}}
    />
    </>
  );
};

export default FileLinkedItems;