import useSecureAPI from "../hooks/usesecureapi";

const useDocumentTemplateService = () => {
  const secureAPI = useSecureAPI();

  const getDocumentTemplates = async () => {
    const response = await secureAPI.get(`cts/DocumentTemplate`);
    return response.data;
  };

  const getDocumentTemplateByID = async (id: number) => {
    const response = await secureAPI.get(`cts/DocumentTemplate/${id}`);
    return response.data;
  };

  const addDocumentTemplate = async (data: any) => {
    const response = await secureAPI.post(`cts/DocumentTemplate`, data);
    return response.data;
  };

  const updateDocumentTemplate = async (data: any) => {
    const response = await secureAPI.put(`cts/DocumentTemplate`, data);
    return response.data;
  };

  const deleteDocumentTemplate = async (id: number) => {
    const response = await secureAPI.delete(`cts/DocumentTemplate/${id}`);
    return response.data;
  };

  const getActionTrailByDocumentTemplateID = async (id: number) => {
    const response = await secureAPI.get(
      `cts/DocumentTemplateActionTrail/fortemplate/${id}`
    );
    return response.data;
  };

  return {
    getDocumentTemplates,
    getDocumentTemplateByID,
    addDocumentTemplate,
    updateDocumentTemplate,
    deleteDocumentTemplate,
    getActionTrailByDocumentTemplateID,
  };
};

export default useDocumentTemplateService;
