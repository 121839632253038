import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GridColDef } from "@mui/x-data-grid";

import { useNotificationContext } from "../../../../context/notificationcontext";
import { useAuthContext } from "../../../../context/authcontext";

import ListPageDX from "../../../../components/business/listpagedx";
import CorrespondenceRoutesModal from "../../../../components/editmodals/correspondence/correspondenceroutemodal";

import {
  CorrespondenceStatus,
  DateTimeFormatter,
  DateSorter,
} from "../../../../shared/globals";
import useCorrespondenceRoutesService from "../../../../shared/services/correspondenceroutesservice";

const CorrespondenceRoutes = (props: any) => {
  const { status, hasWorkflow } = props;

  const { userData } = useAuthContext();
  const { calendarId, gregorianDateFormatId, hijriDateFormatId } = userData;
  const { t, i18n } = useTranslation();
  const { correspondenceId } = props;
  const languageIsEn = i18n.language === "en";
  const { setInfo, setError } = useNotificationContext();

  const { getRoutesByCorrespondenceID, deleteCorrespondenceRoute } =
    useCorrespondenceRoutesService();

  const [rows, setRows] = useState<any>([]);
  const [correspondenceRouteId, setCorrespondenceRouteId] = useState<any>(null);
  const [routes, setRoutes] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const columns: GridColDef[] = [
    {
      field: "correspondenceRouteId",
      headerName: `${t("Route ID")}`,
      flex: 1,
      maxWidth: 80,
    },
    {
      field: "destinationTypeId",
      headerName: `${t("Destination Type")}`,
      flex: 1,
      valueGetter: (params: any) => {
        return params.row.destinationTypeId === 1 ? t("User") : t("Entity");
      },
    },
    {
      field: "user",
      headerName: `${t("Receiving User")}`,
      flex: 1,
      valueGetter: (params: any) => {
        return (
          params.row.user &&
          (languageIsEn
            ? params.row.user.enFullName
            : params.row.user.arFullName)
        );
      },
    },
    {
      field: "entity",
      headerName: `${t("Receiving Entity")}`,
      flex: 1,
      valueGetter: (params: any) => {
        return (
          params.row.entity &&
          (languageIsEn ? params.row.entity.enName : params.row.entity.arName)
        );
      },
    },
    {
      field: "actionTypeId",
      headerName: `${t("Action Type")}`,
      flex: 1,
      valueGetter: (params: any) => {
        return params.row.actionTypeId === 1
          ? t("For Information")
          : t("For Approval");
      },
    },
    {
      field: "createdByUser",
      headerName: `${t("Created By")}`,
      flex: 1,

      valueGetter: (params: any) => {
        return (
          params.row.createdByUser &&
          (languageIsEn
            ? params.row.createdByUser.enFullName
            : params.row.createdByUser.arFullName)
        );
      },
    },
    {
      field: "createdDate",
      headerName: `${t("Date Added")}`,
      flex: 1,
      valueGetter(params) {
        return DateTimeFormatter(
          params.row.createdDate,
          calendarId,
          gregorianDateFormatId,
          hijriDateFormatId
        );
      },
      sortComparator: DateSorter,
    },
  ];

  const getData = () => {
    setIsLoading(true);
    getRoutesByCorrespondenceID(correspondenceId) // api to fetch data types from the server
      .then((response) => {
        setRows(response);
        setRoutes(response);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onDelete = (id: number) => {
    setIsLoading(true);
    deleteCorrespondenceRoute(id)
      .then(() => {
        setInfo(t("Correspondence route deleted successfully"));
        getData();
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onEdit = (correspondenceRouteData: any) => {
    setCorrespondenceRouteId(correspondenceRouteData.correspondenceRouteId);
    setOpen(!open);
  };

  const toCreate = () => {
    setOpen(!open);
  };

  const onClose = (refreshPage: boolean = false) => {
    setOpen(!open);
    setCorrespondenceRouteId(null);

    if (refreshPage) getData();
  };

  useEffect(() => {
    getData();
  }, []);

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(routes);
      return;
    }
    const lowercasedValue = value.toLowerCase();
    const newRows = routes.filter(
      ({ user, entity, createdByUser, destinationTypeId, actionTypeId }: any) =>
        languageIsEn
          ? user?.enFullName.toLowerCase().includes(lowercasedValue) ||
            entity?.enName?.toLowerCase().includes(lowercasedValue) ||
            createdByUser.enFullName.toLowerCase().includes(lowercasedValue) ||
            (destinationTypeId === 1 ? t("User") : t("Entity"))
              .toLowerCase()
              .includes(lowercasedValue) ||
            (actionTypeId === 1 ? t("For Information") : t("For Approval"))
              .toLowerCase()
              .includes(lowercasedValue)
          : user?.arFullName.includes(value) ||
            entity?.arName.includes(value) ||
            createdByUser.arFullName.includes(value) ||
            (destinationTypeId === 1 ? t("User") : t("Entity")).includes(
              value
            ) ||
            (actionTypeId === 1
              ? t("For Information")
              : t("For Approval")
            ).includes(value)
    );
    setRows(newRows);
  };
  return (
    <>
      {open && (
        <CorrespondenceRoutesModal
          open={open}
          handleClose={onClose}
          correspondenceId={correspondenceId}
          correspondenceRouteId={correspondenceRouteId}
        />
      )}
      <ListPageDX
        listTitle={t("Correspondence Routes")}
        name={t("Route")}
        createLabel={t("Add Route")}
        rows={rows}
        columns={columns}
        getRowId={(row: any) => row.correspondenceRouteId}
        isLoading={isLoading}
        toCreate={
          ((status == CorrespondenceStatus.Recall ||
          status == CorrespondenceStatus.Draft) && !hasWorkflow)
            ? toCreate
            : null
        }
        onDelete={
          ((status == CorrespondenceStatus.Recall ||
            status == CorrespondenceStatus.Draft) && !hasWorkflow)
            ? onDelete
            : null
        }
        onEdit={
          ((status == CorrespondenceStatus.Recall ||
            status == CorrespondenceStatus.Draft) && !hasWorkflow)
            ? onEdit
            : null
        }
        setGridFilterCriteria={setGridFilterCriteria}
        exportToPDF={false}
        exportToCSV={false}
        // searchAndFilter={false}
      />
    </>
  );
};

export default CorrespondenceRoutes;
