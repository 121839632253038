import { useEffect, useState } from "react";
import AddEditModalDX from "../../business/addeditmodaldx";
import { useTranslation } from "react-i18next";
import TextFieldDX from "../../controls/textfielddx";
import GridDX from "../../layout/griddx";
import { useNotificationContext } from "../../../context/notificationcontext";
import useDocumentTypeService from "../../../shared/services/documenttypeservice";
import AutoCompleteMultiple from "../../controls/autocompletemultiple";
import useDocumentProfileService from "../../../shared/services/documentprofileservice";

const DocumentTypeModal = (props: any) => {
  const { open, handleClose, documentTypeId } = props;
  const { t, i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";
  const { addDocumentType, updateDocumentType, getDocumentTypeByID } =
    useDocumentTypeService();
  const { getDocumentProfiles } = useDocumentProfileService();
  const { setError, setInfo } = useNotificationContext();

  const defaultValues = {
    enName: "",
    arName: "",
    documentProfiles: [],
  };

  const [documentTypeData, setDocumentTypeData] = useState<any>(defaultValues);
  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [documentProfilesDropDownData, setDocumentProfilesDropDownData] =
    useState<any>([]);

  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    if (documentTypeId) {
      getDocumentTypeByID(documentTypeId)
        .then((res: any) => {
          setDocumentTypeData(res);
        })
        .catch((err: any) => setError(err))
        .finally(() => setIsLoading(false));
    }
  }, []);
  useEffect(() => {
    setIsLoading(true);
    const p1 = getDocumentProfiles();
    const p2 = documentTypeId ? getDocumentTypeByID(documentTypeId) : null;
    Promise.all([p1, p2])
      .then(([docProfiles, documentType]) => {
        const dataForDocProfileDropDown = docProfiles.map((res: any) => ({
          text: languageIsEn ? res.enFullName : res.arFullName,
          value: res.id,
        }));
        setDocumentProfilesDropDownData(dataForDocProfileDropDown);

        if (documentTypeId) {
          const { documentProfiles, ...rest } = documentType;
          // to populate already selected profiles
          const dataForDocProfileDropDown = documentProfiles?.map(
            (res: any) => ({
              text: languageIsEn ? res.profileEnName : res.profileArName,
              value: res.documentProfileInDocumentTypeId,
            })
          );
          setDocumentTypeData({
            ...rest,
            documentProfiles: dataForDocProfileDropDown || [],
          });
        }
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  }, []);

  const validateForm = () => {
    const newErrors: any = {};

    if (documentTypeData.enName.length === 0)
      newErrors["enName"] = t("English name is required");
    if (documentTypeData.arName.length === 0)
      newErrors["arName"] = t("Arabic name is required");
    // if (documentTypeData?.documentProfiles?.length === 0)
    //   newErrors["documentProfiles"] = t("Document Profiles are required");
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = () => {
    if (validateForm()) {
      setIsLoading(true);
      if (documentTypeId)
        updateDocumentType({
          ...documentTypeData,
        })
          .then((res: any) => {
            setInfo("Document Type updated successfully");
            handleClose(true);
          })
          .catch((err: any) => setError(err))
          .finally(() => setIsLoading(false));
      else
        addDocumentType(documentTypeData)
          .then((res: any) => {
            setInfo("Document Type created successfully");
            handleClose(true);
          })
          .catch((err: any) => setError(err))
          .finally(() => setIsLoading(false));
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsChanged(true);
    setDocumentTypeData({
      ...documentTypeData,
      [name]: type == "checkbox" ? checked : value,
    });
  };

  return (
    <AddEditModalDX
      open={open}
      handleClose={handleClose}
      isSaving={isLoading}
      isChanged={isChanged}
      title={
        documentTypeId ? t("Update Document Type") : t("Create Document Type")
      }
      maxWidth="sm"
      onSaveClick={onSave}
      onYesClick={handleClose}
    >
      <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
        <GridDX item xs={12}>
          <TextFieldDX
            label={t("English Name")}
            name="enName"
            value={documentTypeData.enName}
            onChange={handleInputChange}
            error={errors["enName"]}
          />
        </GridDX>
        <GridDX item xs={12}>
          <TextFieldDX
            label={t("Arabic Name")}
            name="arName"
            value={documentTypeData.arName}
            onChange={handleInputChange}
            error={errors["arName"]}
          />
        </GridDX>
        {/* <GridDX item xs={12}>
          <AutoCompleteMultiple
            label={t("Document Profiles")}
            name="documentProfiles"
            placeholder={t("Document Profile")}
            list={documentProfilesDropDownData}
            value={documentTypeData?.documentProfiles}
            onChange={(newValue: any) => {
              setIsChanged(true);
              setDocumentTypeData({
                ...documentTypeData,
                roleIds: newValue,
              });
            }}
            error={errors["roleIds"]}
          />
        </GridDX> */}
      </GridDX>
    </AddEditModalDX>
  );
};

export default DocumentTypeModal;
