import { createContext, useContext, useState, ReactNode } from "react";

const NotificationContext = createContext<any | null>(null);
const useNotificationContext = () => useContext(NotificationContext);

interface AuxProps {
  children: ReactNode;
}

const NotificationContextProvider = ({ children }: AuxProps) => {
  const [error, setError] = useState<string | null>(null);
  const [info, setInfo] = useState<string | null>(null);

  return (
    <NotificationContext.Provider value={{ error, info, setError, setInfo }}>
      {children}
    </NotificationContext.Provider>
  );
};

export { NotificationContextProvider, useNotificationContext };
