import { Editor } from "react-draft-wysiwyg";
import { useTranslation } from "react-i18next";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import ar from "../../locales/arEditor";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useEffect, useRef } from "react";

const TextEditorDX = ({ editorState, setEditorState, disabled }: any) => {
  const isMounted = useRef(false);
  const { t, i18n } = useTranslation();
  const arEditorTranslation = {
    translations: ar,
  };

  useEffect(() => {
    // Component mounted
    isMounted.current = true;

    return () => {
      // Component will unmount
      isMounted.current = false;
    };
  }, []);

  const onEditorStateChange = (newEditorState: EditorState) => {
    // Check if the component is still mounted
    if (isMounted.current) {
      setEditorState(newEditorState);

      // You can access the content using convertToRaw(editorState.getCurrentContent())
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        editorStyle={{ minHeight: 200, overflow: "auto" }}
        toolbar={{
          options: ["inline", "list", "textAlign", "remove", "history"],
        }}
        toolbarStyle={{
          display: disabled ? "none" : "flex",
          border: "1px solid #c6c6c6",
          borderRadius: 5,
          direction: i18n.language === "en" ? "ltr" : "rtl",
        }}
        placeholder={t("Type something")}
        textAlignment={i18n.language === "en" ? "left" : "right"}
        localization={
          i18n.language === "en" ? { locale: "en" } : arEditorTranslation
        }
        readOnly={disabled}
      />
    </div>
  );
};

export default TextEditorDX;

// import { Editor } from "react-draft-wysiwyg";
// import { useTranslation } from "react-i18next";
// import { EditorState, convertToRaw } from "draft-js";
// import draftToHtml from "draftjs-to-html";
// import ar from "../../locales/arEditor";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import { useEffect, useRef } from "react";

// const EditorWrapper = ({ onEditorStateChange }: any) => {
//   const isMounted = useRef(true);

//   useEffect(() => {
//     return () => {
//       // Component will unmount
//       isMounted.current = false;
//     };
//   }, []);

//   const handleEditorStateChange = (newEditorState: any) => {
//     // Check if the component is still mounted
//     if (isMounted.current) {
//       onEditorStateChange(newEditorState);
//     }
//   };

//   const toolbarOptions = {
//     inline: {
//       options: [
//         "bold",
//         "italic",
//         "underline",
//         "strikethrough",
//         "monospace",
//         "superscript",
//         "subscript",
//       ],
//       fontSize: {
//         options: [8, 9, 10, 11, 12, 14, 18, 24, 30, 36, 48, 60, 72, 96],
//       },
//       fontFamily: {
//         options: [
//           "Arial",
//           "Georgia",
//           "Impact",
//           "Tahoma",
//           "Times New Roman",
//           "Verdana",
//         ],
//       },
//     },
//     blockType: {
//       options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"],
//     },
//     list: {
//       options: ["unordered", "ordered"],
//     },
//     textAlign: {
//       options: ["left", "center", "right", "justify"],
//     },
//     colorPicker: {
//       options: [
//         "default",
//         "red",
//         "orange",
//         "yellow",
//         "green",
//         "blue",
//         "indigo",
//         "violet",
//       ],
//     },
//     link: {
//       options: ["link", "unlink"],
//     },
//     embedded: {
//       options: ["embed"],
//     },
//     emoji: {
//       options: ["emoji"],
//     },
//     image: {
//       options: ["image"],
//     },
//     remove: {
//       options: ["remove"],
//     },
//     history: {
//       options: ["undo", "redo"],
//     },
//   };

//   return (
//     <Editor
//       onEditorStateChange={handleEditorStateChange}
//       toolbar={toolbarOptions}
//     />
//   );
// };

// const TextEditorDX = ({ editorState, setEditorState }: any) => {
//   const { t, i18n } = useTranslation();
//   const arEditorTranslation = {
//     translations: ar,
//   };

//   const onEditorStateChange = (newEditorState: EditorState) => {
//     setEditorState(newEditorState);

//     // You can access the content using convertToRaw(editorState.getCurrentContent())
//     console.log(draftToHtml(convertToRaw(newEditorState.getCurrentContent())));
//   };

//   return <EditorWrapper onEditorStateChange={onEditorStateChange} />;
// };

// export default TextEditorDX;
