import { Outlet } from "react-router-dom";
import GridDX from "../components/layout/griddx";
import PageTitle from "../components/pagetitle";

const ListPageTemplate = () => {
  return (
    <GridDX container sx={{ width: "100%" }}>
      <GridDX item xs={12}>
        <PageTitle />
      </GridDX>
      <GridDX item xs={12}>
        <Outlet />
      </GridDX>
    </GridDX>
  );
};

export default ListPageTemplate;
