import useSecureAPI from "../hooks/usesecureapi";

const useDocumentTypeService = () => {
  const secureAPI = useSecureAPI();

  const getDocumentTypes = async () => {
    const response = await secureAPI.get(`dms/DocumentType`);
    return response.data;
  };
  
  const getDocumentTypeByID = async (id: number) => {
    const response = await secureAPI.get(`dms/DocumentType/${id}`);
    return response.data;
  };

  const addDocumentType = async (data: any) => {
    const response = await secureAPI.post(`dms/DocumentType`, data);
    return response.data; 
  };

  const updateDocumentType = async (data: any) => {
    const response = await secureAPI.put(`dms/DocumentType`, data);
    return response.data;
  };

  const deleteDocumentType = async (id: number) => {
    const response = await secureAPI.delete(`dms/DocumentType/${id}`);
    return response.data;
  };

  return {
    getDocumentTypes,
    getDocumentTypeByID,
    addDocumentType,
    updateDocumentType,
    deleteDocumentType,
  };
};

export default useDocumentTypeService;