import { useState } from "react";
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";

const StaticDataGridDX = (props: any) => {
  const { t } = useTranslation();

  function CustomToolbar() {
    return (
      <GridToolbarContainer sx={{ display: "flex", justifyContent: "flex-end" }}>
        <GridToolbarExport
          printOptions={{
            hideFooter: false,
            hideToolbar: false,
          }}
        />
      </GridToolbarContainer>
    );
  }

  const CustomNoRowsOverlay = () => {
    return (
      <div style={{ display: 'flex', alignItems: "center", justifyContent: "center", height: "100%", textAlign: "center" }}>
        {t("No rows to display")}
      </div>
    );
  };

  return (
    <DataGrid
      pagination={false} // Disable pagination
      apiRef={props.apiRef}
      slots={{
        noRowsOverlay: CustomNoRowsOverlay,
      }}
      disableColumnFilter
      disableColumnSelector
      disableDensitySelector

      localeText={{
        MuiTablePagination: {
          labelRowsPerPage: t("Rows per page:"),
          labelDisplayedRows: ({ from, to, count }) => {
            return `${from}–${to} ${t("of")} ${count !== -1 ? count : `more than ${to}`}`;
          },
          hideFooter: true,
        },
        columnMenuSortAsc: t("Ascending order"),
        columnMenuSortDesc: t("Descending order"),
        footerTotalRows: t("Total Rows"),
        footerRowSelected: (count) =>
          count !== 1 ? `${count.toLocaleString()} ${t("rows selected")}` : `${count.toLocaleString()} ${t("row selected")}`,
      }}
      // getRowHeight={() => props.rowHeight || "100%"}
      {...props}
      sx={{
        ...props.sx,
        minHeight: 250,
        "& .MuiDataGrid-cell:focus": {
          outline: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
          minHeight: 250,
        },
        "& .MuiDataGrid-footerContainer": {
          display: "none"
        }
      }}
      loading={props.isLoading}
    />
  );
};

export default StaticDataGridDX;
