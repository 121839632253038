import { useEffect, useState } from "react";
import AddEditModalDX from "../business/addeditmodaldx";
import { useTranslation } from "react-i18next";
import TextFieldDX from "../controls/textfielddx";
import GridDX from "../layout/griddx";
import CheckBoxDX from "../controls/checkboxdx";
import useEntityTypeService from "../../shared/services/entitytypeservice";
import { useNotificationContext } from "../../context/notificationcontext";
import Loading from "../loading";

const EntityTypeModal = (props: any) => {

  const {handleClose,open ,entityTypeId } = props;
  const { t } = useTranslation();
  const { setError, setInfo } = useNotificationContext();
  const { addType, updateType, getEntityTypeById } = useEntityTypeService();
  const defaultValues = {
    enName: "",
    arName: "",
    // internal: false,
  };

  const [typeData, setTypeData] = useState(defaultValues);
  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [isInternal, setIsInternal] = useState(false);
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    if (entityTypeId) {
      setIsLoading(true);
      getEntityTypeById(entityTypeId)
        .then((res) => setTypeData(res))
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false))
    }
  }, []);

  const validateForm = () => {
    const newErrors: any = {};

    if (typeData.enName.length === 0)
      newErrors["enName"] = t("English name is required");
    if (typeData.arName.length === 0)
      newErrors["arName"] = t("Arabic name is required");

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = () => {
    if (validateForm()) {
      setIsLoading(true);
      entityTypeId
        ? updateType(entityTypeId, typeData)
          .then(() => {
            setInfo(t("Entity Type updated successfully"));
            handleClose(true);
          })
          .catch((err) => setError(err))
          .finally(() => setIsLoading(false))
        : addType(typeData)
          .then(() => {
            setInfo(t("Entity Type added successfully"));
            handleClose(true);
          })
          .catch((err) => setError(err))
          .finally(() => setIsLoading(false));
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setIsChanged(true);
    setTypeData({
      ...typeData,
      [name]: value,
    });
  };

  return (
    <AddEditModalDX
      open={open}
      handleClose={handleClose}
      isSaving={isLoading}
      isChanged={isChanged}
      title={entityTypeId ? t("Edit Entity Type") : t("Create Entity Type")}
      maxWidth="sm"
      onSaveClick={onSave}
      onYesClick={handleClose}
    >
      
      {isLoading && <Loading styles={{ height: "100%", width: "100%", left: 0 }} />}
      <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
        <GridDX item xs={12}>
          <TextFieldDX
            label={t("English Name")}
            name="enName"
            value={typeData.enName}
            onChange={handleInputChange}
            error={errors["enName"]}
          />
        </GridDX>
        <GridDX item xs={12}>
          <TextFieldDX
            label={t("Arabic Name")}
            name="arName"
            value={typeData.arName}
            onChange={handleInputChange}
            error={errors["arName"]}
          />
        </GridDX>
        {/* <GridDX item xs={12}>
          <CheckBoxDX
            label={t("Internal")}
            checked={isInternal}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setIsInternal(e.target.checked)
            }
          />
        </GridDX> */}
      </GridDX>
    </AddEditModalDX>
  );
};

export default EntityTypeModal;
