import useSecureAPI from "../hooks/usesecureapi";

const usePrivacyLevelsService = () => {
  const secureAPI = useSecureAPI();

  const getPrivacyLevels = async () => {
    const response = await secureAPI.get("PrivacyLevel");
    return response.data;
  };

  const getPrivacyLevelById = async (id: number) => {
    const response = await secureAPI.get(`PrivacyLevel/${id}`);
    return response.data;
  };
  
  const addPrivacyLevel = async (data: any) => {
    const response = await secureAPI.post("PrivacyLevel", data);
    return response.data;
  };

  const updatePrivacyLevel = async (id: number, data: any) => {

    const response = await secureAPI.put(`PrivacyLevel`, data);
    return response.data;
  };

  const deletePrivacyLevel = async (id: number) => {
    const response = await secureAPI.delete(`PrivacyLevel/${id}`);
    return response.data;
  };

  return {
    getPrivacyLevels,
    getPrivacyLevelById,
    addPrivacyLevel,
    updatePrivacyLevel,
    deletePrivacyLevel
  };
};

export default usePrivacyLevelsService;
