import useSecureAPI from "../hooks/usesecureapi";

const useFolderReminderService = () => {
  const secureAPI = useSecureAPI();

  const getRemindersByFolderID = async (id: number) => {
    const response = await secureAPI.get(`dms/FolderReminder/forfolder/${id}`);
    return response.data;
  };
  
  const addFolderReminder = async (data: any) => {
    const response = await secureAPI.post("dms/FolderReminder", data);
    return response.data;
  };

  const updateFolderReminder = async (data: any) => {
    const response = await secureAPI.put(`dms/FolderReminder`, data);
    return response.data;
  };

  const getReminderByReminderID = async (id: number) => {
    const response = await secureAPI.get(`dms/FolderReminder/${id}`);
    return response.data;
  };

  const deleteFolderReminder = async (id: number) => {
    const response = await secureAPI.delete(`dms/FolderReminder/${id}`);
    return response.data;
  };

  return {
   getRemindersByFolderID,
   addFolderReminder,
   deleteFolderReminder,
   updateFolderReminder,
   getReminderByReminderID
  };
};

export default useFolderReminderService;
