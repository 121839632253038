import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import ListPageDX from "../../../components/business/listpagedx";
import { useNotificationContext } from "../../../context/notificationcontext";
import { DateSorter, DateTimeFormatter } from "../../../shared/globals";
import ContentEditingRequestModal from "../../../components/editmodals/tools/contentEditingRequestModal";

const ContentEditingRequests = () => {
  const { t, i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";
  const { setInfo, setError } = useNotificationContext();
  const [contentEditingRequestId, setContentEditingRequestId] = useState<
    number | null
  >(null);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState<any>([
    {
      id: 1,
      requestType: t("Create"),
      workflowType: t("Automated"),
      state: t("Closed"),
      dueDate: new Date().toUTCString(),
      createdDate: new Date().toUTCString(),
      modifiedDate: new Date().toUTCString(),
    },
    {
      id: 2,
      requestType: t("Edit"),
      workflowType: t("Manual"),
      state: t("Open"),
      dueDate: new Date().toUTCString(),
      createdDate: new Date().toUTCString(),
      modifiedDate: new Date().toUTCString(),
    },
    {
      id: 3,
      requestType: t("Delete"),
      workflowType: t("Manual"),
      state: t("Closed"),
      dueDate: new Date().toUTCString(),
      createdDate: new Date().toUTCString(),
      modifiedDate: new Date().toUTCString(),
    },
    {
      id: 4,
      requestType: t("Create"),
      workflowType: t("Automated"),
      state: t("Rejected"),
      dueDate: new Date().toUTCString(),
      createdDate: new Date().toUTCString(),
      modifiedDate: new Date().toUTCString(),
    },
    {
      id: 5,
      requestType: t("Edit"),
      workflowType: t("Manual"),
      state: t("Closed"),
      dueDate: new Date().toUTCString(),
      createdDate: new Date().toUTCString(),
      modifiedDate: new Date().toUTCString(),
    },
  ]);
  const columns: GridColDef[] = [
    {
      field: "requestType",
      headerName: `${t("Request Type")}`,
      flex: 1,
    },
    {
      field: "workflowType",
      headerName: `${t("Workflow Type")}`,
      flex: 1,
    },
    {
      field: "state",
      headerName: `${t("State")}`,
      flex: 1,
    },
    {
      field: "dueDate",
      headerName: `${t("Due Date")}`,
      flex: 1,
      sortComparator: DateSorter,
    },
    {
      field: "createdDate",
      headerName: `${t("Created at")}`,
      flex: 1,
      sortComparator: DateSorter,
    },
    {
      field: "modifiedDate",
      headerName: `${t("Last modified at")}`,
      flex: 1,
      sortComparator: DateSorter,
    },
  ];

  const getData = () => {};

  const onDelete = (id: number) => {
    setRows(rows.filter((row: any) => row.id !== id));
    setInfo(t("Content edit request deleted successfully"));
  };

  const onEdit = (contentEditRequest: any) => {
    setOpen(!open);
    setContentEditingRequestId(contentEditRequest.id);
  };

  const toCreate = () => {
    setOpen(!open);
  };

  const onClose = (refreshPage: boolean = false) => {
    setOpen(!open);
    setContentEditingRequestId(null);

    if (refreshPage) getData();
  };

  useEffect(() => {
    getData();
  }, [i18n.language]);
  return (
    <>
      {open && (
        <ContentEditingRequestModal
          open={open}
          handleClose={onClose}
          contentEditingRequestId={contentEditingRequestId}
          rows={rows}
          setRows={setRows}
        />
      )}
      <ListPageDX
        listTitle={t("Content Editing Requests")}
        name={t("Content Editing Request")}
        rows={rows}
        columns={columns}
        getRowId={(row: any) => row.id}
        isLoading={isLoading}
        toCreate={toCreate}
        onEdit={onEdit}
        onDelete={onDelete}
        hideColumnsOption={true}
      />
    </>
  );
};

export default ContentEditingRequests;
