import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import ListPageDX from "../../../components/business/listpagedx";
import { useNotificationContext } from "../../../context/notificationcontext";
import useCategoryService from "../../../shared/services/categoryservice";

const Category = () => {
  const { t, i18n } = useTranslation();
  const { setError } = useNotificationContext();
  const { getCategories } = useCategoryService();
  const [rows, setRows] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  const columns: GridColDef[] = [
    {
      field: i18n.language === "en" ? "enName" : "arName",
      headerName: `${t("Type")}`,
      flex: 1,
    },
  ];

  const getData = () => {
    setIsLoading(true);
    getCategories() // api to fetch data types from the server
      .then((response) => setRows(response))
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <ListPageDX
      listTitle={t("Category")}
      name={t("Category")}
      rows={rows}
      columns={columns}
      getRowId={(row: any) => row.categoryId}
      isLoading={isLoading}
    />
  );
};

export default Category;
