import useSecureAPI from "../hooks/usesecureapi";

const useCorrespondenceActionService = () => {
  const secureAPI = useSecureAPI();

  const approveCorrespondence = async (id: number, userId: string) => {
    const response = await secureAPI.put(
      `Correspondence/markasapproved/${id}/${userId}`
    );
    return response.data;
  };

  const rejectCorrespondence = async (id: number, userId: string) => {
    const response = await secureAPI.put(
      `Correspondence/markasrejected/${id}/${userId}`
    );
    return response.data;
  };

  const reviewCorrespondence = async (id: number, userId: string) => {
    const response = await secureAPI.put(
      `Correspondence/markasreviewed/${id}/${userId}`
    );
    return response.data;
  };

  const recallCorrespondence = async (id: number, userId: string) => {
    const response = await secureAPI.put(
      `Correspondence/recall/${id}/${userId}`
    );
    return response.data;
  };

  const sendOTP = async (id: number) => {
    const response = await secureAPI.post(`Correspondence/sendactionotp/${id}`);
    return response.data;
  };

  const confirmOTP = async (id: number, otp: string) => {
    const response = await secureAPI.put(
      `Correspondence/confirmactionotp/${id}/${otp}`
    );
    return response.data;
  };

  return {
    approveCorrespondence,
    rejectCorrespondence,
    reviewCorrespondence,
    recallCorrespondence,
    sendOTP,
    confirmOTP,
  };
};

export default useCorrespondenceActionService;
