import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import ListPageDX from "../../../components/business/listpagedx";
import { useNotificationContext } from "../../../context/notificationcontext";
import DocumentCategoryModal from "../../../components/editmodals/DMS/documentCategoryModal";
import useDocumentCategoryTypeService from "../../../shared/services/documentcategory";

const DocumentCategories = () => {
  const { t, i18n } = useTranslation();
  const { getDocumentCategoryTypes, deleteDocumentCategoryType } = useDocumentCategoryTypeService();
  const languageIsEn = i18n.language === "en";
  const { setInfo, setError } = useNotificationContext();
  const [documentCategoryId, setDocumentCategoryId] = useState<number | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState<any>([]);

  const columns: GridColDef[] = [
    {
      field: languageIsEn ? "enName" : "arName",
      headerName: `${t("Name")}`,
      flex: 1,
    },
  ];

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setIsLoading(true);
    getDocumentCategoryTypes()
      .then((res: any) => setRows(res))
      .catch((err: any) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onDelete = (id: number) => {
    setIsLoading(true);
    deleteDocumentCategoryType(id)
      .then((res: any) => {
        setInfo(t("Document category deleted successfully"));
        getData();
      })
      .catch((err: any) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onEdit = (documentCategory: any) => {
    setOpen(!open);
    setDocumentCategoryId(documentCategory.documentCategoryTypeId);
  };

  const toCreate = () => {
    setOpen(!open);
  };

  const onClose = (refreshPage: boolean = false) => {
    setOpen(!open);
    setDocumentCategoryId(null);

    if (refreshPage) getData();
  };

  return (
    <>
      {open && (
        <DocumentCategoryModal
          open={open}
          handleClose={onClose}
          documentCategoryId={documentCategoryId}
          rows={rows}
          setRows={setRows}
        />
      )}
      <ListPageDX
        listTitle={t("Document Category")}
        name={t("Document category")}
        rows={rows}
        columns={columns}
        getRowId={(row: any) => row.documentCategoryTypeId}
        isLoading={isLoading}
        toCreate={toCreate}
        onEdit={onEdit}
        onDelete={onDelete}
      />
    </>
  );
};

export default DocumentCategories;
