import { Typography, Tooltip, InputAdornment } from "@mui/material";
import { formatTimerToMinAndSecs } from "../../shared/globals";
import ButtonDX from "../controls/buttondx";
import TextFieldDX from "../controls/textfielddx";
import GridDX from "../layout/griddx";
import AddEditModalDX from "./addeditmodaldx";
import { useNotificationContext } from "../../context/notificationcontext";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import EmailIcon from "@mui/icons-material/Email";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import useCorrespondenceActionService from "../../shared/services/correspondenceactionservice";

const OTPModal = (props: any) => {
  const { t, i18n } = useTranslation();
  const { setError } = useNotificationContext();
  const { sendOTP, confirmOTP } = useCorrespondenceActionService();

  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [seconds, setSeconds] = useState(60);
  const [errors, setErrors] = useState<any>({});

  let isSubscribed = true;
  let timeOutRef: any;

  useEffect(() => {
    if (seconds > 0) {
      timeOutRef = setTimeout(() => {
        if (isSubscribed)
          setSeconds(
            Number(seconds) - 1 < 10 ? 0 + (seconds - 1) : seconds - 1
          );
      }, 1000);
    }

    return () => {
      isSubscribed = false;
      if (timeOutRef) clearTimeout(timeOutRef);
    };
  });

  const validateForm = () => {

    const newErrors: any = {};

    if (otp.length === 0)
      newErrors["error"] = t("This field is required");

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (event: any) => {
    if (event.target.value.length <= 5)
      setOtp(event.target.value);
  };

  const OtpVerification = async (event: any) => {
    if (validateForm()) {
      confirmOTP(props.correspondenceId, otp)
      .then(() => props.toSave())
      .catch((err) => setError(err));
    }
  };

  const resendOTP = () => {
    sendOTP(props.correspondenceId)
    .then(() => setSeconds(60))
    .catch((err) => setError(err));
  };

  return(
    <AddEditModalDX
      open={props.open}
      handleClose={props.onClose}
      isSaving={isLoading}
      title={t("OTP Verification")}
      maxWidth="xs"
      saveLabel={t("Approve")}
      onSaveClick={OtpVerification}
      onYesClick={props.onClose}
    >
    <GridDX container rowSpacing={4}>
      <GridDX item xs={12} justifyContent="center" direction = "column">
        <Typography fontSize={14} sx={{textAlign:"center"}}>
          {t("Check your email for the OTP")}
        </Typography>
        <Typography fontSize={14} sx={{textAlign:"center"}} >
          {t("If you cannot find it")}
        </Typography>
      </GridDX>
      <GridDX item xs={12} justifyContent="center">
        <TextFieldDX
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailIcon />
              </InputAdornment>
            ),
          }}
          name="otp"
          value={otp}
          error={errors["error"]}
          onChange={handleChange}
          label={t("Enter OTP")}
        />
      </GridDX>
      <GridDX item xs={12} justifyContent="center">
        <ButtonDX
          variant={"text"}
          color={seconds !== 0 ? "secondary" : "primary"}
          loading={isLoading}
          disabled={seconds !== 0}
          sx={{ fontSize: 12 }}
          onClick={resendOTP}
        >
          {t("Resend OTP") +
            (seconds > 0
              ? " " + t("in") + " " + formatTimerToMinAndSecs(seconds)
              : "")}
        </ButtonDX>
      </GridDX>
    </GridDX>
    </AddEditModalDX>
  );
};

export default OTPModal;