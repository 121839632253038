import useSecureAPI from "../hooks/usesecureapi";

const useDocumentCategoryTypeService = () => {
  const secureAPI = useSecureAPI();

  const getDocumentCategoryTypes = async () => {
    const response = await secureAPI.get(`dms/DocumentCategoryType/`);
    return response.data;
  };
  
  const getDocumentCategoryTypeByID = async (id: number) => {
    const response = await secureAPI.get(`dms/DocumentCategoryType/${id}`);
    return response.data;
  };

  const addDocumentCategoryType = async (data: any) => {
    const response = await secureAPI.post(`dms/DocumentCategoryType/`, data);
    return response.data; 
  };

  const updateDocumentCategoryType = async (data: any) => {
    const response = await secureAPI.put(`dms/DocumentCategoryType/`, data);
    return response.data;
  };

  const deleteDocumentCategoryType = async (id: number) => {
    const response = await secureAPI.delete(`dms/DocumentCategoryType/${id}`);
    return response.data;
  };

  return {
    getDocumentCategoryTypes,
    getDocumentCategoryTypeByID,
    addDocumentCategoryType,
    updateDocumentCategoryType,
    deleteDocumentCategoryType,
  };
};

export default useDocumentCategoryTypeService;