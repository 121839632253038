import * as React from 'react';
import List from '@mui/material/List';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import ButtonDX from './buttondx';
import { KeyboardArrowDown } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';


export default function MenuDX(props: any) {
    const { t } = useTranslation()
    const { label, options, selectedIndex } = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLElement>,
        index: number,
    ) => {
        if (selectedIndex === null) {
            setAnchorEl(null);
            // setSelectedIndex(null);
            options[index].onclick();
            return;
        }
        options[index].onclick();
        // setSelectedIndex(index);
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <List
                component="nav"
                aria-label="Device settings"
                sx={{ bgcolor: 'primary' }}
            >
                <ButtonDX
                    id="lock-button"
                    aria-haspopup="listbox"
                    aria-controls="lock-menu"
                    aria-label="when device is locked"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClickListItem}
                    endIcon={<KeyboardArrowDown style={{ fontSize: '20px', }} />}
                    sx={{ ...props.sx }}
                >
                    {(selectedIndex || selectedIndex === 0) ? options[selectedIndex].label : label}
                </ButtonDX>
            </List>
            <Menu
                id="lock-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'lock-button',
                    role: 'listbox',
                }}
            >
                {options.map((option: any, index: number) => (
                    <MenuItem
                        key={option.label}
                        disabled={option.disabled}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}