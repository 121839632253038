import useSecureAPI from "../hooks/usesecureapi";

const useReportService = () => {
    const secureAPI = useSecureAPI();

    const getCorrespondenceByTypeCount = async (startDate: string, endDate: string) => {
        const response = await secureAPI.get(`Report/correspondencebytypecount/${startDate}/${endDate}`);
        return response.data;
    };
    const getCorrespondenceByStatusCount = async (startDate: string, endDate: string) => {
        const response = await secureAPI.get(`Report/correspondencebystatuscount/${startDate}/${endDate}`);
        return response.data;
    };

    return {
        getCorrespondenceByTypeCount,
        getCorrespondenceByStatusCount
    };
};

export default useReportService;