import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from "@mui/material";
import Dynamsoft from "dwt";
import { useTranslation } from "react-i18next";
import DWT from "../../../DynamsoftSDK";
import ButtonDX from "../../controls/buttondx";
import BoxDX from "../../layout/boxdx";
import TextFieldDX from "../../controls/textfielddx";
import { useState } from "react";
import useDocumentService from "../../../shared/services/documentservice";
import { getContentFromPdf } from "../../../shared/pdfoperations";
import { useNotificationContext } from "../../../context/notificationcontext";
import Loading from "../../loading";

const DocumentScanModal = (props: any) => {
  const { t, i18n } = useTranslation();
  const { setError, setInfo } = useNotificationContext();
  const { addDocument } = useDocumentService();
  const defaultValues = {
    enName: "scanned_doc",
    arName: "scanned_doc",
    documentTypeId: "",
    documentCategories: [],
    documentProfileInDocuments: [],
    file: null as null | File,
    profileData: null,
  };

  const [data, setData] = useState(defaultValues);
  const [ isLoading, setIsLoading ] = useState(false);
  
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const toSave = async (file: File) => {
    const fileData = new File([file], "scannedDoc.pdf", {
      type: "application/pdf",
    });

    setIsLoading(true);
    const formData = new FormData();
    formData.append("FolderId", props.folderId);
    formData.append("EnName", data.enName);
    formData.append("ArName", data.arName);
    formData.append("DocumentTypeId", data.documentTypeId);
    formData.append("File", fileData);

    getContentFromPdf(fileData)
      .then((content) => {
        formData.append("ContentAsText", content);
        saveDocument(formData);
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  };

  const saveDocument = async (data: FormData) => {
    addDocument(data)
      .then((res) => {
        setInfo(t("Document added successfully"))
        toClose(true);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const toClose = (isRefresh : boolean = false) => {
    Dynamsoft.DWT.DeleteDWTObject("dwtcontrolContainer");
    props.onClose(isRefresh);
  };

  return (
  <Dialog
    open={props.open}
    onClose={() => toClose()}
    fullWidth
    maxWidth={"lg"}
    disableEscapeKeyDown
    sx={{ direction: i18n.dir() }}
  >
    <DialogTitle sx={{ textAlign: "center" }}> {t("Scanner")} </DialogTitle>
    <DialogContent>
      {isLoading && (
        <Loading styles={{ height: "100%", width: "100%", left: 0 }} />
      )}
      <Typography fontSize={20}>{t("File Information")}</Typography>
      <Divider sx={{mb: 2, width: "100%"}}/>
      <BoxDX
        display="flex"
        flexDirection="row"
        sx={{ mb: 2 }}
      >
        <TextFieldDX
          label={t("English Name")}
          name="enName"
          value={data.enName}
          onChange={handleInputChange}
          sx = {{ mr: 1 }}
        />
        <TextFieldDX
          label={t("Arabic Name")}
          name="arName"
          value={data.arName}
          onChange={handleInputChange}
        />
      </BoxDX>
      <DWT
        features={[
          "scan"
        ]}
        onUpload={toSave}
      />
    </DialogContent>
    <DialogActions sx={{ flexWrap: "wrap", mx: 3 }}>
      <ButtonDX
        variant="outlined"
        onClick={toClose}
        sx={{ mb: { xs: 2, sm: "auto" } }}
      >
        {t("Discard")}
      </ButtonDX>
    </DialogActions>
  </Dialog>
  );
};

export default DocumentScanModal;