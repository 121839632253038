import useSecureAPI from "../hooks/usesecureapi";

const useSharingService = () => {
  const secureAPI = useSecureAPI();

  const getSharedContent = async () => {
    const response = await secureAPI.get(`dms/Sharing`);
    return response.data;
  };

  return {
   getSharedContent,
  };
};

export default useSharingService;
