import moment from "moment";
import momentHijri from "moment-hijri";
import DatePickerDX from "./datepickerdx";
import { useAuthContext } from "../../context/authcontext";
import { useTranslation } from "react-i18next";

const FilterRange = (props: any) => {
  const { userData } = useAuthContext();
  const { calendarId } = userData;
  const { t, i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";
  const {
    filterFromDate,
    handleChangeFromDate,
    filterToDate,
    handleChangeToDate,
  } = props;
  const DatePickerStyle = {
    height: "40px",
    width: "auto",
    mx: 1,
    "& .MuiInputBase-root": {
      height: "inherit",
      px: 1,
      py: 0,
      margin: 0,
    },
    "& .MuiButtonBase-root": {
      padding: 0,
      paddingRight: "10px",
    },
  };

  return (
    <>
      <DatePickerDX
        label={t("From")}
        value={filterFromDate}
        onChange={(date: any) => handleChangeFromDate(date)}
        minDate={
          calendarId == 1 ? moment("2010-01-01") : momentHijri("2010-01-01")
        }
        sx={{
          ...DatePickerStyle,
          "& .MuiInputLabel-root": {
            transform: "translate(80%, 30%)", // Move the label back to its original position
            transition: "transform 0.2s",
          },
          "& .MuiInputLabel-shrink": {
            transform: languageIsEn
              ? "translate(30%,-50%)"
              : "translate(75%,-55%)", // Adjust the value as needed
          },
        }}
      />
      <DatePickerDX
        label={t("To")}
        value={filterToDate}
        onChange={(date: any) => handleChangeToDate(date)}
        minDate={
          calendarId == 1 ? moment("2010-01-01") : momentHijri("2010-01-01")
        }
        sx={{
          ...DatePickerStyle,
          "& .MuiInputLabel-root": {
            transform: "translate(80%, 30%)", // Move the label back to its original position
            transition: "transform 0.2s",
          },
          "& .MuiInputLabel-shrink": {
            transform: "translate(60%,-50%)", // Adjust the value as needed
          },
        }}
      />
    </>
  );
};

export default FilterRange;
