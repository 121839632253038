import { useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import BoxDX from "../layout/boxdx";
import SelectListDX from "./selectlistdx";

const CKEditorDX = (props: any) => {
  const [contentLang, setContentLang] = useState("en");

  const handleInputChange = (e: any) => {
    const { value } = e.target;
    setContentLang(value);
  };

  return (
    <BoxDX
      component="fieldset"
      sx={{
        minWidth: 0,
        height: "100%",
        border: "1px solid #c6c6c6",
        borderRadius: 1,
        p: 2,
      }}
    >
      <legend style={{ color: "#808080", fontSize: 12 }}>{props.label}</legend>
      <SelectListDX
        sx={{ mb: 2, height: "max-content" }}
        label={props.langSelectorLabel}
        onChange={handleInputChange}
        value={contentLang}
        items={[
          { value: "en", text: "English" },
          { value: "ar", text: "Arabic" },
        ]}
      />
      <CKEditor
        key={contentLang}
        editor={Editor}
        config={{
          language: { content: contentLang },
        }}
        data={props.data}
        onChange={(event, editor) => {
          props.onChange(editor.getData());
        }}
        disabled={props.readOnly}
      />
    </BoxDX>
  );
};

export default CKEditorDX;
