import { InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/SearchOutlined";

import { useTranslation } from "react-i18next";
import TextFieldDX from "../controls/textfielddx";
import { useState } from "react";

const GridSearchFilterTextbox = (props: any) => {
  const { t } = useTranslation();

  const [filterValue, setFilterValue] = useState("");

  const handleInputChange = (e: any) => {
    const { value } = e.target;
    setFilterValue(value);
    props?.setGridFilterCriteria(value);
  };

  return (
    <TextFieldDX
      variant="outlined"
      placeholder={t("Search & Filter")}
      value={filterValue}
      sx={{...props.sx, 
        backgroundColor: "#F2F2F2",
        "& .MuiOutlinedInput-root": {
         
          "& fieldset": {
            border: "none",
          },
          "&:hover fieldset": {
            border: "none",
          },
          "&.Mui-focused fieldset": {
            border: "none",
          },
        },
        "& .MuiOutlinedInput-input": {
          padding: '8px',
        }
      }}
      onChange={handleInputChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      fullWidth
    />
  );
};

export default GridSearchFilterTextbox;
