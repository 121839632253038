import { useState } from "react";
import { useTranslation } from "react-i18next";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Tab } from "@mui/material";

import StatusTimeline from "./tabcontent/documenttemplatestatuses";
import BoxDX from "../../../../components/layout/boxdx";

const CorrespondenceTab = (props: any) => {
  const { refetchStatusData } = props;
  const { t } = useTranslation();
  const [tab, setTab] = useState("1");

  const handleTabChange = (event: any, newValue: any) => setTab(newValue);

  return (
    <BoxDX sx={{ typography: "body1" }}>
      <TabContext value={tab}>
        <BoxDX sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleTabChange} aria-label="lab API tabs example">
            <Tab
              icon={<AutorenewOutlinedIcon />}
              iconPosition="start"
              label={t("Statuses")}
              value={"1"}
            />
          </TabList>
        </BoxDX>
        <TabPanel value="1">
          <StatusTimeline
            correspondenceId={props.correspondenceId}
            refetchStatusData={refetchStatusData}
          />
        </TabPanel>
      </TabContext>
    </BoxDX>
  );
};

export default CorrespondenceTab;
