import { Navigate } from "react-router-dom";
import { useAuthContext } from "../../context/authcontext";
const ProtectedRoute = ({ children }: any) => {
  const { isLoggedIn } = useAuthContext();
  if (!isLoggedIn) {
    return <Navigate to={"/"} replace />;
  }

  return children;
};

export default ProtectedRoute;
