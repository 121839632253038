import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Tab } from "@mui/material";
import {
  TabContext,
  TabList,
  TabPanel,
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from "@mui/lab";

import moment from "moment";
import { useAuthContext } from "../../../../../context/authcontext";
import { useNotificationContext } from "../../../../../context/notificationcontext";
import useDocumentTemplateService from "../../../../../shared/services/documenttemplateservice";
import { DateFormatter } from "../../../../../shared/globals";
import Loading from "../../../../../components/loading";
import BoxDX from "../../../../../components/layout/boxdx";

const DocumentTemplateStatusTimeline = (props: any) => {
  const { correspondenceId, refetchStatusData } = props;

  const { t, i18n } = useTranslation();

  const languageIsEn = i18n.language === "en";

  const { userData } = useAuthContext();
  const { calendarId, gregorianDateFormatId, hijriDateFormatId } = userData;

  const { setError } = useNotificationContext();

  const [isLoading, setIsLoading] = useState(false);
  const [actionTrailData, setActionTrailData] = useState<any>([]);

  const { getActionTrailByDocumentTemplateID } = useDocumentTemplateService();

  const getData = () => {
    setIsLoading(true);
    getActionTrailByDocumentTemplateID(correspondenceId)
      .then((response: any) => {
        const preprocessedData = response.map((item: any) => {
          return {
            date: DateFormatter(
              item.createdDate,
              calendarId,
              gregorianDateFormatId,
              hijriDateFormatId
            ),
            time: moment(item.createdDate)
              .locale(i18n.language)
              .format("hh:mm a"),
            action: item.description,
            performedByUser: languageIsEn
              ? item?.performedByUser?.enFullName
              : item?.performedByUser?.arFullName,
          };
        });
        const transformedData = transformData(preprocessedData);

        setActionTrailData(transformedData);
      })
      .catch((error) => setError(error))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    correspondenceId && getData();
  }, [correspondenceId, i18n.language, refetchStatusData]);

  const transformData = (apiData: any[]) => {
    const groupedData: any = {};
    apiData.forEach(
      (item: { date: any; time: any; action: any; performedByUser: any }) => {
        // Extract the date from the item's date
        const date = item.date;
        if (!groupedData[date]) {
          groupedData[date] = [];
        }
        // Extract the time and action from the item's action
        const time = item.time;
        const performedByUser = item?.performedByUser && item.performedByUser;
        let action = item.action;

        action =
          t(item.action.split(":")[0]) 
          // + ": " + item.action.split(":")[1];
        // Add performedByUser on a new line
        groupedData[date].push({ time, action, performedByUser });
      }
    );
    // Convert the grouped data into the desired format
    const transformedData = Object.entries(groupedData).map(
      ([date, timeline]) => ({
        date,
        timeline,
      })
    );
    return transformedData;
  };

  const [tab, setTab] = useState("0");
  const [timeline, setTimeline] = useState<any>([]);

  useEffect(() => {
    setTimeline(actionTrailData[Number(tab)]?.timeline);
  }, [tab, actionTrailData]);

  const getTimeline = () => {
    return timeline?.map((item: any, index: number) => {
      return (
        <TimelineItem key={index} sx={{ direction: "ltr" }}>
          <TimelineOppositeContent color="primary" fontSize={16}>
            {item.time}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            {item.action}
            {item.performedByUser && (
              <div style={{ fontSize: 12 }}>{item.performedByUser}</div>
            )}
          </TimelineContent>
        </TimelineItem>
      );
    });
  };

  const getTabs = () => {
    return actionTrailData.map((day: any, index: number) => {
      return <Tab key={index} label={day.date} value={index.toString()} />;
    });
  };

  const getPanels = () => {
    return actionTrailData.map((day: any, index: number) => {
      return (
        <TabPanel value={index.toString()} key={index}>
          <Timeline>{getTimeline()}</Timeline>
        </TabPanel>
      );
    });
  };

  const handleTabChange = (event: any, newValue: any) => setTab(newValue);

  if (isLoading) {
    return (
      <div style={{ position: "relative", minHeight: "200px" }}>
        <Loading styles={{ height: "100%", width: "100%", left: 0 }} />
      </div>
    );
  }
  return (
    <BoxDX sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={tab}>
        <BoxDX sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleTabChange} aria-label="lab API tabs example">
            {getTabs()}
          </TabList>
        </BoxDX>
        {getPanels()}
      </TabContext>
    </BoxDX>
  );
};

export default DocumentTemplateStatusTimeline;
