import useSecureAPI from "../hooks/usesecureapi";

const useFolderActionTrailService = () => {
  const secureAPI = useSecureAPI();

  const getActionTrailByFolderID = async (id: number) => {
    const response = await secureAPI.get(`/dms/FolderActionTrail/forfolder/${id}`);
    return response.data;
  }; 

  return {
    getActionTrailByFolderID,
  };
};

export default useFolderActionTrailService;
