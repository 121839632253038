import { useTranslation } from "react-i18next";
import ButtonDX from "../../controls/buttondx";
import DiscussionDrawer from "../../business/discussiondrawer";
import { useEffect, useState } from "react";
import { useNotificationContext } from "../../../context/notificationcontext";
import useFolderCommentService from "../../../shared/services/foldercommentservice";

const FolderDrawer = (props: any) => {
  const { t } = useTranslation();
  const { setError, setInfo } = useNotificationContext();
  const { getFolderComments, addFolderComment, deleteFolderComment } = useFolderCommentService(); 

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [comments, setComments] = useState<any>([]);

  useEffect(()=>{
    getComments();
  }, [open])

  const getComments = async () => {
    setIsLoading(true);
    getFolderComments(props.folderId)
      .then((res: any) => setComments(res))
      .catch((err: any) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const addComment = async (comment: string) => {
    const data = {
      folderId: props.folderId,
      comment: comment
    };

    setIsLoading(true);
    addFolderComment(data)
      .then((res: any) => {
        setInfo(t("Comment added successfully"));
        getComments();
      })
      .catch((err: any) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const addReply = async (reply: any, parentComment: any) => {
    const data = {
      folderId: props.folderId,
      parentFolderCommentId: parentComment.folderCommentId,
      comment: reply
    };

    setIsLoading(true);
    addFolderComment(data)
      .then((res: any) => {
        setInfo(t("Reply added successfully"));
        getComments();
      })
      .catch((err: any) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const deleteComment = async (comment: any) => {
    setIsLoading(true);
    deleteFolderComment(comment.folderCommentId)
      .then((res: any) => {
        setInfo(t("Comment deleted successfully"));
        getComments();
      })
      .catch((err: any) => setError(err))
      .finally(() => setIsLoading(false));
  };

  return(
    <>
      <ButtonDX
        sx={{mx: 2}}
        onClick={() => setOpen(true)}
      >
        {t("Discussion")}
      </ButtonDX>
      <DiscussionDrawer
        open={open}
        toClose={()=>setOpen(false)}
        commentList={comments}
        isLoading={isLoading}
        toPublishComment={addComment}
        toDeleteComment={deleteComment}
        toPostReply={addReply}
      />
    </>
  );
};

export default FolderDrawer;