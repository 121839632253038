import { useEffect, useState } from "react";
import AddEditModalDX from "../../business/addeditmodaldx";
import { useTranslation } from "react-i18next";
import TextFieldDX from "../../controls/textfielddx";
import GridDX from "../../layout/griddx";
import { useNotificationContext } from "../../../context/notificationcontext";
import SelectListDX from "../../controls/selectlistdx";
import Loading from "../../loading";
import AutoCompleteMultiple from "../../controls/autocompletemultiple";
import CheckBoxDX from "../../controls/checkboxdx";
import useUserService from "../../../shared/services/userservices";
import useDocumentCategoryTypeService from "../../../shared/services/documentcategory";
import { MuiColorInput } from "mui-color-input";

const DocumentCategoryModal = (props: any) => {
  const { open, handleClose, documentCategoryId } = props;
  const { t, i18n } = useTranslation();
  const { getActiveUsers } = useUserService();
  const {
    getDocumentCategoryTypeByID,
    addDocumentCategoryType,
    updateDocumentCategoryType,
  } = useDocumentCategoryTypeService();
  const languageIsEn = i18n.language === "en";

  const { setError, setInfo } = useNotificationContext();

  const defaultValues = {
    enName: "",
    arName: "",
    labelColor: "",
    documentCategoryTypeRemindUsers: [],
  };

  const [documentCategoryData, setDocumentCategoryData] =
    useState<any>(defaultValues);
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    if (documentCategoryId) getDocumentCategoryData();
    else getUsers();
  }, []);

  const getUsers = async () => {
    setIsLoading(true);
    getActiveUsers()
      .then((res: any) => {
        const list = res.map((user: any) => ({
          text: languageIsEn ? user.enFullName : user.arFullName,
          value: user.id,
        }));
        setUserList(list);
      })
      .catch((err: any) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const getDocumentCategoryData = async () => {
    const p1 = getDocumentCategoryTypeByID(documentCategoryId);
    const p2 = getActiveUsers();

    setIsLoading(true);
    Promise.all([p1, p2])
      .then(([category, users]) => {
        const data = {
          ...category,
          documentCategoryTypeRemindUsers:
            documentCategoryData.documentCategoryTypeRemindUsers.map(
              (user: any) => {
                return {
                  text: languageIsEn
                    ? user.remindUser.enFullName
                    : user.remindUser.arFullName,
                  value: user.remindUser.id,
                };
              }
            ),
        };
        setDocumentCategoryData(data);

        const list = users.map((user: any) => ({
          text: languageIsEn ? user.enFullName : user.arFullName,
          value: user.id,
        }));
        setUserList(list);
      })
      .catch((err: any) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const validateForm = () => {
    const newErrors: any = {};

    if (documentCategoryData.enName.length === 0)
      newErrors["enName"] = t("English name is required");
    if (documentCategoryData.arName.length === 0)
      newErrors["arName"] = t("Arabic name is required");
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = () => {
    if (validateForm()) {
      const data = {
        ...documentCategoryData,
        documentCategoryTypeRemindUsers:
          documentCategoryData.documentCategoryTypeRemindUsers.map(
            (user: any) => {
              return { remindUserId: user.value, remindUser: {} };
            }
          ),
      };
      console.log(data);
      setIsSaving(true);
      if (documentCategoryId)
        updateDocumentCategoryType(data)
          .then((res: any) => {
            setInfo("Document category updated successfully");
            handleClose(true);
          })
          .catch((err: any) => setError(err))
          .finally(() => setIsSaving(false));
      else
        addDocumentCategoryType(data)
          .then((res: any) => {
            setInfo("Document category created successfully");
            handleClose(true);
          })
          .catch((err: any) => setError(err))
          .finally(() => setIsSaving(false));
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsChanged(true);
    setDocumentCategoryData({
      ...documentCategoryData,
      [name]: type == "checkbox" ? checked : value,
    });
  };

  const handleColorChange = (newValue: string) => {
    setDocumentCategoryData({
      ...documentCategoryData,
      labelColor: newValue,
    });
  };

  return (
    <AddEditModalDX
      open={open}
      handleClose={handleClose}
      isSaving={isSaving}
      isChanged={isChanged}
      title={
        documentCategoryId
          ? t("Update Document Category")
          : t("Create Document Category")
      }
      maxWidth="sm"
      onSaveClick={onSave}
      onYesClick={handleClose}
    >
      {isLoading && (
        <Loading styles={{ height: "100%", width: "100%", left: 0 }} />
      )}
      <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
        <GridDX item xs={12}>
          <TextFieldDX
            label={t("English Name")}
            name="enName"
            value={documentCategoryData.enName}
            onChange={handleInputChange}
            error={errors["enName"]}
          />
        </GridDX>
        <GridDX item xs={12}>
          <TextFieldDX
            label={t("Arabic Name")}
            name="arName"
            value={documentCategoryData.arName}
            onChange={handleInputChange}
            error={errors["arName"]}
          />
        </GridDX>

        <GridDX item xs={12}>
          <AutoCompleteMultiple
            sx={{ width: "100%" }}
            label={t("Remind on Expiry")}
            name="remindOnExpiry"
            value={documentCategoryData.documentCategoryTypeRemindUsers}
            onChange={(newValue: any) => {
              setIsChanged(true);
              setDocumentCategoryData({
                ...documentCategoryData,
                documentCategoryTypeRemindUsers: newValue,
              });
            }}
            error={errors["remindOnExpiry"]}
            list={userList}
          />
        </GridDX>
        <GridDX item xs={12}>
          <MuiColorInput
            format="hex"
            label={t("Label Color")}
            value={documentCategoryData.labelColor ?? ""}
            onChange={handleColorChange}
          />
        </GridDX>
      </GridDX>
    </AddEditModalDX>
  );
};

export default DocumentCategoryModal;
