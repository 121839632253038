import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useTranslation } from "react-i18next";
import DWT from "../../DynamsoftSDK";
import Dynamsoft from "dwt";

const ArrangeModal = (props: any) => {
  const { t, i18n } = useTranslation();
  
  const getFile = (file: any) => {
    props.setFile(file);
    toClose();
  };

  const toClose = () => {
    Dynamsoft.DWT.DeleteDWTObject("dwtcontrolContainer");
    props.onClose();
  };

  return (
  <Dialog
    open={props.open}
    onClose={toClose}
    fullWidth
    maxWidth={"md"}
    disableEscapeKeyDown
    sx={{ direction: i18n.dir() }}
  >
    <DialogTitle sx={{ textAlign: "center" }}> {t("Arrange PDF")} </DialogTitle>
    <DialogContent>
      <DWT
        features={[
          "load"
        ]}
        onUpload={getFile}
        onClose={toClose}
        file={props.file}
      />
    </DialogContent>
  </Dialog>
  );
};

export default ArrangeModal;