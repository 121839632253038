import useSecureAPI from "../hooks/usesecureapi";

const useDocumentVersionService = () => {
  const secureAPI = useSecureAPI();

  const getVersionsByDocumentID = async (id: number) => {
    const response = await secureAPI.get(`dms/DocumentVersion/fordocument/${id}`);
    return response.data;
  };
  
  const addDocumentVersion = async (data: any) => {
    const response = await secureAPI.post(`dms/DocumentVersion/`, data);
    return response.data;
  };

  const markAsMainVersion = async (id: number) => {
    const response = await secureAPI.put(`dms/DocumentVersion/markasmain/${id}`);
    return response.data;
  };

  const deleteDocumentVersion = async (id: number) => {
    const response = await secureAPI.delete(`dms/DocumentVersion/${id}`);
    return response.data;
  };

  return {
   getVersionsByDocumentID,
   addDocumentVersion,
   markAsMainVersion,
   deleteDocumentVersion,
  };
};

export default useDocumentVersionService;
