import useSecureAPI from "../hooks/usesecureapi";

const useFolderTemplateService = () => {
  const secureAPI = useSecureAPI();

  const getFolderTemplates = async () => {
    const response = await secureAPI.get("dms/FolderTemplate");
    return response.data;
  };

  const getFolderTemplateById = async (id: number) => {
    const response = await secureAPI.get(`dms/FolderTemplate/${id}`);
    return response.data;
  };
  
  const addFolderTemplate = async (data: any) => {
    const response = await secureAPI.post("dms/FolderTemplate", data);
    return response.data;
  };

  const updateFolderTemplate = async (id: number, data: any) => {
    const response = await secureAPI.put(`dms/FolderTemplate`, data);
    return response.data;
  };

  const deleteFolderTemplate = async (id: number) => {
    const response = await secureAPI.delete(`dms/FolderTemplate/${id}`);
    return response.data;
  };

  return {
    getFolderTemplates,
    getFolderTemplateById,
    addFolderTemplate,
    updateFolderTemplate,
    deleteFolderTemplate,
  };
};

export default useFolderTemplateService;