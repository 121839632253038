import useSecureAPI from "../hooks/usesecureapi";

const useDocumentCommentService = () => {
  const secureAPI = useSecureAPI();

  const getDocumentComments = async (id: number) => {
    const response = await secureAPI.get(`dms/DocumentComment/treefordocument/${id}`);
    return response.data;
  };
  
  const getDocumentCommentByID = async (id: number) => {
    const response = await secureAPI.get(`dms/DocumentComment/${id}`);
    return response.data;
  };

  const addDocumentComment = async (data: any) => {
    const response = await secureAPI.post(`dms/DocumentComment`, data);
    return response.data; 
  };

  const updateDocumentComment = async (data: any) => {
    const response = await secureAPI.put(`dms/DocumentComment`, data);
    return response.data;
  };

  const deleteDocumentComment = async (id: number) => {
    const response = await secureAPI.delete(`dms/DocumentComment/${id}`);
    return response.data;
  };

  return {
    getDocumentComments,
    getDocumentCommentByID,
    addDocumentComment,
    updateDocumentComment,
    deleteDocumentComment,
  };
};

export default useDocumentCommentService;