import { Grid} from "@mui/material";

const GridDX = (props: any) => {
  return (
    <Grid {...props} style={{ display: "flex", ...props.sx }} sx={props.sx}>
      {props.children}
    </Grid>
  );
};

export default GridDX;
