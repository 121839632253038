import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ListPageDX from "../../../components/business/listpagedx";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DownloadIcon from "@mui/icons-material/Download";

const AttachmentSearch = (props: any) => {
  const { t } = useTranslation();

  const rows = [
    {
      id: 0,
      name: "Object 1",
      createdAt: "Jan-02-2024, 13:30",
      objectType: "Correspondence",
      objectId: 1,
    },
    {
      id: 1,
      name: "Object 2",
      createdAt: "Jan-02-2024, 13:30",
      objectType: "Correspondence",
      objectId: 2,
    },
    {
      id: 2,
      name: "Object 3",
      createdAt: "Jan-02-2024, 13:30",
      objectType: "Document",
      objectId: 3,
    },
  ];

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: `${t("Name")}`,
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: `${t("Created At")}`,
      flex: 1,
    },
    {
      field: "objectType",
      headerName: `${t("Object Type")}`,
      flex: 1,
    },
    {
      field: "objectId",
      headerName: `${t("Object")}`,
      flex: 1,
    },
  ];

  const [isLoading, setIsLoading] = useState(false);

  return (
    <ListPageDX
      listTitle={t("Attachments")}
      name="attachments"
      rows={rows}
      columns={columns}
      editLabel={t("Preview")}
      getRowId={(row: any) => row.id}
      isLoading={isLoading}
      onEdit={() => {}}
      hideColumnsOption={true}
      sendEmail={() => {}}
      moreActions={[
        <GridActionsCellItem
          label={t("Related Object")}
          showInMenu
          onClick={() => {}}
          icon={<InsertDriveFileIcon />}
        />,
        <GridActionsCellItem
          label={t("Download Extracted Text")}
          showInMenu
          onClick={() => {}}
          icon={<DownloadIcon />}
        />,
      ]}
    />
  );
};

export default AttachmentSearch;
