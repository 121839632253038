import { Dialog, DialogContent } from "@mui/material";
import { useTranslation } from "react-i18next";
import FileViewer from "../../business/fileviewer";

const AttachmentViewerModal = (props: any) => {
  const { i18n } = useTranslation();

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      fullWidth
      fullScreen
      disableEscapeKeyDown
      sx={{ direction: i18n.dir() }}
    >
      <DialogContent>
        <FileViewer
          fileName={props.fileName}
          fileURL={props.fileURL}
          applyWatermark={props.applyWatermark}
          onCloseClick={props.handleClose}
        />
      </DialogContent>
    </Dialog>
  );
};

export default AttachmentViewerModal;