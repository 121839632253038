import { TextField } from "@mui/material";
import moment from "moment";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import Skeleton from "react-loading-skeleton";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useTranslation } from "react-i18next";
// for hijri calendar umm al qura
import { AdapterMomentHijri } from "@mui/x-date-pickers/AdapterMomentHijri";
import momentHijri from "moment-hijri";
import {
  DATETIME_FORMAT,
  DATETIME_FORMAT_LONG,
  HIJRI_DATETIME_FORMAT,
  HIJRI_DATETIME_FORMAT_LONG,
} from "../../shared/globals";
import { useAuthContext } from "../../context/authcontext";

const DateTimePickerDX = (props: any) => {
  const { userData } = useAuthContext();
  const { calendarId, hijriDateFormatId, gregorianDateFormatId } = userData;
  const { i18n, t } = useTranslation();
  const isError = props.error ? true : false;
  const errorText = props.error ?? "";
  const helperText = props.helperText;
  const dateFormat = props.format ?? DATETIME_FORMAT; //DATE_FORMAT;
  const loading = props.loading ?? false;
  const CalendarStyle =
    i18n.dir() === "rtl"
      ? {
        ...props.sx,
        "& label": {
          left: "unset",
          right: "1.75rem",
          transformOrigin: "right",
        },
        "& legend": {
          textAlign: "right",
        },
        "& .MuiFormHelperText-root": {
          textAlign: "right",
        },
        "& .MuiSelect-icon": {
          left: 0, // Change the position of the icon to the left
          right: "auto", // Reset the right property
        },
      }
      : { ...props.sx };
  const DialogBoxStyle = {
    // this is main dialog / modal / pop up

    "& .MuiPickersLayout-root": {
      direction: i18n.dir() === "rtl" ? "rtl !important" : "ltr !important",
      overflow: "hidden",
    },
    // this is header of the dialog
    "& .MuiPickersToolbar-root": {
      paddingBottom: 0,
    },
    "& .MuiPickersToolbarText-root": {
      fontSize: "23px",
    },
    "& .MuiDateTimePickerToolbar-ampmLabel": {
      fontSize: "14px",
    },
    "& .MuiDateTimePickerToolbar-timeDigitsContainer": {
      alignItems: "center",
      "& .MuiTypography-root": {
        fontSize: "32px !important",
      },
    },
    "& .MuiPickersLayout-contentWrapper": {
      maxWidth: "100%",
    },
    //  this is 30 days calendar
    "& .MuiPickersSlideTransition-root": {
      minHeight: "192px !important",
    },
    // this is the footer of the dialog
    "& .MuiDialogActions-root": {
      paddingTop: "0 !important",
    },
  };

  const localeText = {
    dateTimePickerToolbarTitle: t("SELECT DATE & TIME"),
    cancelButtonLabel: t("Cancel"),
    okButtonLabel: t("OK"),
  };

  if (loading)
    return (
      <Skeleton
        containerClassName="skeleton-container"
        style={{ height: 56 }}
      />
    );
  else if (props.readOnly)
    return (
      <TextField
        {...props}
        value={props.value ? moment(props.value).format(dateFormat) : ""}
        variant="outlined"
        fullWidth
        disabled
        placeholder={helperText}
        helperText=""
        InputLabelProps={{ shrink: true }}
      />
    );
  // calendarId == 2 for hijri calendar
  else if (calendarId == 2)
    return (
      <LocalizationProvider
        dateAdapter={AdapterMomentHijri}
        localeText={localeText}
        adapterLocale={i18n.language}
      >
        <MobileDateTimePicker
          {...props}
          // moment-hijri support dates bewteen 1356-01-01 and 1499-12-29 H (1937-03-14 and 2076-11-26)
          maxDate={momentHijri(new Date(2075, 11, 31))}
          variant="outlined"
          inputVariant="outlined"
          slotProps={{
            textField: {
              error: isError,
              helperText: errorText ?? helperText,
            },
            popper: {
              popperOptions: {
                placement: "top",
              },
              sx: {
                height: "200px !important"
              },
            },
            dialog: {
              sx: DialogBoxStyle,
            },
          }}
          value={props.value && momentHijri(props.value)}
          format={
            hijriDateFormatId == 1
              ? HIJRI_DATETIME_FORMAT
              : HIJRI_DATETIME_FORMAT_LONG
          }
          sx={CalendarStyle}
        />
      </LocalizationProvider>
    );
  // for Gregorian calendar
  else {
    return (
      <LocalizationProvider dateAdapter={AdapterMoment} localeText={localeText} adapterLocale={i18n.language}>
        <MobileDateTimePicker
          {...props}
          variant="outlined"
          inputVariant="outlined"
          slotProps={{
            textField: {
              error: isError,
              helperText: errorText ?? helperText,
            },
            popper: {
              popperOptions: {
                placement: "top",
              },
              sx: {
                height: "200px !important",
              },
            },
            dialog: {
              sx: DialogBoxStyle,
            },
          }}
          value={props.value && moment(props.value)}
          format={
            gregorianDateFormatId == 1 ? DATETIME_FORMAT : DATETIME_FORMAT_LONG
          }
          sx={CalendarStyle}
        />
      </LocalizationProvider>
    );
  }
};

export default DateTimePickerDX;
