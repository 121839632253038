import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import BoxDX from "../../layout/boxdx";

const EmptyView = (props: any) => {
  const { t } = useTranslation();

  return(
    <BoxDX
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        }}
      >
      <Typography fontSize={20} sx={{color: "grey"}}>
        {t("No Contents!")}
      </Typography>
      {props.showInstruction && (
        <Typography fontSize={16} sx={{color: "grey"}}>
          {t("Use the new button")}
        </Typography>
      )}
    </BoxDX>
  );
};

export default EmptyView;