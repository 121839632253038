import useSecureAPI from "../hooks/usesecureapi";

const useCorrespondenceRoutesService = () => {
  const secureAPI = useSecureAPI();



  const getRoutesByCorrespondenceID = async (id: number) => {
    const response = await secureAPI.get(`CorrespondenceRoute/bycorrespondence/${id}`);
    return response.data;
  };
  const getRouteByCorrespondenceRouteID = async (id: number) => {
    const response = await secureAPI.get(`CorrespondenceRoute/${id}`);
    return response.data;
  };


  const addCorrespondenceRoute = async (data: any) => {
    const response = await secureAPI.post("CorrespondenceRoute", data);
    return response.data;
  };

  const updateCorrespondenceRoute = async (id: number, data: any) => {
    const response = await secureAPI.put(`CorrespondenceRoute`, data);
    return response.data;
  };

  const deleteCorrespondenceRoute = async (id: number) => {
    const response = await secureAPI.delete(`CorrespondenceRoute/${id}`);
    return response.data;
  };

  return {
    getRouteByCorrespondenceRouteID,
    getRoutesByCorrespondenceID,
    addCorrespondenceRoute,
    deleteCorrespondenceRoute,
    updateCorrespondenceRoute
  };
};

export default useCorrespondenceRoutesService;
