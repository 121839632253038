import useSecureAPI from "../hooks/usesecureapi";

const useImportanceLevelsService = () => {
  const secureAPI = useSecureAPI();

  const getImportanceLevels = async () => {
    const response = await secureAPI.get("ImportanceLevel");
    return response.data;
  };

  const getImportanceLevelById = async (id: number) => {
    const response = await secureAPI.get(`ImportanceLevel/${id}`);
    return response.data;
  };
  

  const addImportanceLevel = async (data: any) => {
    const response = await secureAPI.post("ImportanceLevel", data);
    return response.data;
  };

  const updateImportanceLevel = async (id: number, data: any) => {
    const response = await secureAPI.put(`ImportanceLevel`, data);
    return response.data;
  };

  const deleteImportanceLevel = async (id: number) => {
    const response = await secureAPI.delete(`ImportanceLevel/${id}`);
    return response.data;
  };

  return {
    getImportanceLevels,
    getImportanceLevelById,
    addImportanceLevel,
    updateImportanceLevel,
    deleteImportanceLevel
  };
};

export default useImportanceLevelsService;
