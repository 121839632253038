import useSecureAPI from "../hooks/usesecureapi";

const useFollowerService = () => {
  const secureAPI = useSecureAPI();

  const getFollowers = async (id: number) => {
    const response = await secureAPI.get(`Follower/forcorrespondence/${id}`);
    return response.data;
  };

  const addFollower = async (id: number, data: any) => {
    for (var index in data) {
      const user = {
        userId: data[index].value,
        correspondenceId: id,
      };
      const response = await secureAPI.post("Follower", user);
      return response.data;
    }
  };

  const deleteFollower = async (correspondenceId: number, userId: number) => {
    const response = await secureAPI.delete(
      `Follower/${correspondenceId}/${userId}`
    );
    return response.data;
  };

  return {
    getFollowers,
    addFollower,
    deleteFollower,
  };
};

export default useFollowerService;
