import useSecureAPI from "../hooks/usesecureapi";

const useFolderSharingService = () => {
  const secureAPI = useSecureAPI();

  const getSharingsByFolderID = async (id: number) => {
    const response = await secureAPI.get(`dms/FolderSharing/forfolder/${id}`);
    return response.data;
  };
  
  const addFolderSharing = async (id: number, data: any) => {
    const response = await secureAPI.post(`dms/FolderSharing/${id}`, data);
    return response.data;
  };

  const deleteFolderSharing = async (id: number) => {
    const response = await secureAPI.delete(`dms/FolderSharing/${id}`);
    return response.data;
  };

  return {
   getSharingsByFolderID,
   addFolderSharing,
   deleteFolderSharing,
  };
};

export default useFolderSharingService;
