import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import SettingsApplicationsOutlinedIcon from "@mui/icons-material/SettingsApplicationsOutlined";
import CorrespondenceIcon from "@mui/icons-material/ArticleOutlined";
import ReportsIcon from "@mui/icons-material/PrintOutlined";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ViewListIcon from "@mui/icons-material/ViewList";
import SourceOutlinedIcon from "@mui/icons-material/SourceOutlined";

import {
  CategoryOutlined,
  ContactPageOutlined,
  DomainOutlined,
  GroupOutlined,
  LabelImportantOutlined,
  PrivacyTipOutlined,
  Person,
  StorageOutlined,
  FolderSharedOutlined,
  MediationOutlined,
  TypeSpecimenOutlined,
  TuneOutlined,
} from "@mui/icons-material";
import i18n from "../../multilingual/i18n";
import { useConfigContext } from "../../../context/configcontext";
import { useAuthContext } from "../../../context/authcontext";

const MenuItems = (props: any) => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const { MODULES } = useConfigContext();
  const { isUserAdmin } = useAuthContext();

  const [open, setOpen] = useState(props ? props.open : false);
  const [activeIndex, setActiveIndex] = useState(0);

  interface IMenuItem {
    id: number;
    text: string;
    icon: JSX.Element;
    link?: string | undefined;
    links?: IMenuItem[] | undefined;
  }

  const defaultMenuItems = [
    {
      id: 1,
      text: t("Dashboard"),
      icon: <InsertChartOutlinedIcon />,
      link: "/dashboard",
    },
    {
      id: 2,
      text: t("Correspondence"),
      icon: <CorrespondenceIcon />,
      link: "/correspondence",
    },
    {
      id: 3,
      text: t("Reports"),
      icon: <ReportsIcon />,
      link: "/reports",
    },
  ];

  const settingsMenuItems = {
    id: 6,
    text: t("Settings"),
    icon: <SettingsApplicationsOutlinedIcon />,
    links: [
      {
        id: 602,
        text: t("Category"),
        icon: <CategoryOutlined />,
        link: "/category",
      },
      {
        id: 603,
        text: t("Contact Types"),
        icon: <ContactPageOutlined />,
        link: "/contacttypes",
      },
      {
        id: 604,
        text: t("Correspondence Types"),
        icon: <ListAltOutlinedIcon />,
        link: "/correspondencetypes",
      },
      {
        id: 605,
        text: t("Domain Types"),
        icon: <DomainOutlined />,
        link: "/domaintypes",
      },
      {
        id: 606,
        text: t("Document Templates"),
        icon: <StorageOutlined />,
        link: "/templates",
      },
      {
        id: 607,
        text: t("Entities"),
        icon: <ViewListIcon />,
        link: "/entities",
      },
      {
        id: 608,
        text: t("Entity Types"),
        icon: <ViewListIcon />,
        link: "/entitytypes",
      },
      {
        id: 609,
        text: t("Importance Levels"),
        icon: <LabelImportantOutlined />,
        link: "/importancelevels",
      },
      {
        id: 610,
        text: t("Privacy Levels"),
        icon: <PrivacyTipOutlined />,
        link: "/privacylevels",
      },
      {
        id: 611,
        text: t("Reference Types"),
        icon: <FormatQuoteIcon />,
        link: "/referencetypes",
      },
      {
        id: 612,
        text: t("Routing Groups"),
        icon: <AccountTreeIcon />,
        link: "/routinggroup",
      },
      {
        id: 613,
        text: t("Users"),
        icon: <Person />,
        link: "/users",
      },
      {
        id: 614,
        text: t("User Groups"),
        icon: <GroupOutlined />,
        link: "/usergroup",
      },
    ],
  };

  const [menuItems, setMenuItems] = useState<IMenuItem[]>([]);

  useEffect(() => {
    const pathName = location.pathname.substring(1);
    let currentItem: any = null;

    setMenuItems(defaultMenuItems);

    if (isUserAdmin())
      setMenuItems((oldMenuItems) => {
        const newItems = oldMenuItems.slice();
        newItems.push(settingsMenuItems);
        return newItems;
      });

    checkAndAddDMSMenuItems();

    menuItems.forEach((i) => {
      if (i.link && i.link === pathName) {
        currentItem = i;
      } else if (i.links && currentItem == null) {
        currentItem = i.links.find((si) => si.link === pathName);
      }
    });

    if (currentItem) setActiveIndex(currentItem.id);
  }, [location.pathname, MODULES, i18n.language]);

  useEffect(() => {
    if (props) setOpen(props?.open);
  }, [props?.open]);

  const checkAndAddDMSMenuItems = () => {
    if (MODULES.includes("DMS")) {
      setMenuItems((oldMenuItems) => {
        const newMenuItems = [...oldMenuItems];

        if (newMenuItems.findIndex((m) => m.id === 4) === -1)
          newMenuItems.push({
            id: 4,
            text: t("Documents"),
            icon: <SourceOutlinedIcon />,
            link: "/documents",
          });

        const settingsIndex = newMenuItems.findIndex((m) => m.id === 6);

        if (settingsIndex > -1) {
          if (
            newMenuItems[settingsIndex].links?.findIndex(
              (m) => m.id === 601
            ) === -1
          )
            newMenuItems[settingsIndex].links?.push({
              id: 601,
              text: t("Documents"),
              icon: <SourceOutlinedIcon />,
              links: [
                {
                  id: 6011,
                  text: t("Cabinets"),
                  icon: <StorageOutlined />,
                  link: "/cabinets",
                },
                {
                  id: 6012,
                  text: t("Structure Templates"),
                  icon: <MediationOutlined />,
                  link: "/structuretemplates",
                },
                {
                  id: 6013,
                  text: t("Profiles"),
                  icon: <FolderSharedOutlined />,
                  link: "/documentprofiles",
                },
                {
                  id: 6014,
                  text: t("Document Types"),
                  icon: <TypeSpecimenOutlined />,
                  link: "/documenttypes",
                },
                {
                  id: 6014,
                  text: t("Categories"),
                  icon: <CategoryOutlined />,
                  link: "/documentcategories",
                },
              ],
            });

          newMenuItems[settingsIndex].links?.sort((m1, m2) => m1.id - m2.id);
        }

        if (newMenuItems.findIndex((m) => m.id === 7) === -1)
          newMenuItems.push({
            id: 7,
            text: t("DMS Reports"),
            icon: <ReportsIcon />,
            link: "/dmsreports",
          });

        newMenuItems.sort((m1, m2) => m1.id - m2.id);

        return newMenuItems;
      });
    }
  };

  return (
    <List
      sx={{
        px: 1,
        flex: "auto",
        overflowY: "auto",
        overflowX: open ? "auto" : "hidden",
      }}
      dir={i18n.dir()}
    >
      {menuItems.map((item: any, index) => {
        if (item.link)
          return (
            <MenuItem
              key={`menuItem_${index}`}
              item={item}
              open={open}
              activeIndex={activeIndex}
              onClick={props.onClick}
            />
          );
        else
          return (
            <SubMenus
              key={`subMenu_${index}`}
              item={item}
              menuIsOpen={open}
              activeIndex={activeIndex}
              onClick={props.onClick}
            />
          );
      })}
    </List>
  );
};

const MenuItem = ({ item, open, activeIndex, onClick }: any) => {
  const activeBgColor = "#395d41";
  const activeTextColor = "#bba20b";
  const defaultBgColor = "transparent";
  const defaultTextColor = "white";

  const { i18n } = useTranslation();
  const navigate = useNavigate();

  return (
    <ListItem
      key={item.text}
      disablePadding
      sx={{
        display: "block",
        borderRadius: 1,
        my: 2,
        background: item.id === activeIndex ? activeBgColor : defaultBgColor,
        color: item.id === activeIndex ? activeTextColor : defaultTextColor,
      }}
    >
      <ListItemButton
        sx={{
          height: 46,
          justifyContent: open ? "initial" : "center",
          px: 2.5,
          color: "inherit",
        }}
        onClick={() => {
          navigate(item.link);
          if (onClick) {
            onClick();
          }
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: i18n.dir() === "ltr" ? (open ? 3 : "auto") : "auto",
            ml: i18n.dir() === "rtl" ? (open ? 3 : "auto") : "auto",
            justifyContent: "center",
            color: "inherit",
          }}
        >
          {item.icon}
        </ListItemIcon>
        <ListItemText
          primary={item.text}
          sx={{
            opacity: open ? 1 : 0,
            textAlign: "initial",
          }}
        />
      </ListItemButton>
    </ListItem>
  );
};

const SubMenus = ({ item, menuIsOpen, activeIndex }: any) => {
  const [openSubMenu, setOpenSubMenu] = useState(false);

  useEffect(() => {
    if (!menuIsOpen) setOpenSubMenu(false);
  }, [menuIsOpen]);

  const handleClick = () => {
    setOpenSubMenu(!openSubMenu);
  };

  return (
    <>
      <ListItemButton
        onClick={handleClick}
        sx={{
          color: "white",
          padding: "8px 20px",
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: i18n.dir() === "ltr" ? (menuIsOpen ? 3 : "auto") : "auto",
            ml: i18n.dir() === "rtl" ? (menuIsOpen ? 3 : "auto") : "auto",
            justifyContent: "center",
            color: "inherit",
          }}
        >
          {item.icon}
        </ListItemIcon>
        <ListItemText
          primary={item.text}
          sx={{
            opacity: menuIsOpen ? 1 : 0,
            textAlign: i18n.dir() === "rtl" ? "right" : "left",
          }}
        />
        {menuIsOpen && (openSubMenu ? <ExpandLess /> : <ExpandMore />)}
      </ListItemButton>
      <Collapse in={openSubMenu} timeout="auto" unmountOnExit sx={{ pl: 2 }}>
        <List component="div" disablePadding>
          {item.links.map((item: any, index: number) =>
            item.link ? (
              <MenuItem
                key={`subMenu_menuItem_${index}`}
                item={item}
                index={index}
                open={openSubMenu}
                activeIndex={activeIndex}
              />
            ) : (
              <SubMenus
                key={`subMenu_${index}`}
                item={item}
                menuIsOpen={openSubMenu}
                activeIndex={activeIndex}
              />
            )
          )}
        </List>
      </Collapse>
    </>
  );
};

export default MenuItems;
