import useSecureAPI from "../hooks/usesecureapi";

const useDocumentActionTrailService = () => {
  const secureAPI = useSecureAPI();

  const getActionTrailByDocumentID = async (id: number) => {
    const response = await secureAPI.get(`/dms/DocumentActionTrail/forDocument/${id}`);
    return response.data;
  }; 

  return {
    getActionTrailByDocumentID,
  };
};

export default useDocumentActionTrailService;
