import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import ListPageDX from "../../../components/business/listpagedx";
import { useNotificationContext } from "../../../context/notificationcontext";
import useDomainTypeService from "../../../shared/services/domaintypeservice";

const DomainTypes = () => {
  const { t, i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";
  const { setError } = useNotificationContext();
  const { getDomainTypes } = useDomainTypeService();
  const [rows, setRows] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  const columns: GridColDef[] = [
    {
      field: languageIsEn ? "enName" : "arName",
      headerName: `${t("Type")}`,
      flex: 1,
    },
  ];

  const getData = () => {
    setIsLoading(true); // commented out due to error from server side
    getDomainTypes() // api to fetch data types from the server
      .then((response) => setRows(response))
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <ListPageDX
      listTitle={t("Domain Types")}
      name={t("Domain Type")}
      rows={rows}
      columns={columns}
      getRowId={(row: any) => row.domainTypeId}
      isLoading={isLoading}
    />
  );
};

export default DomainTypes;
