import { useEffect, useRef, useState } from "react";
import AddEditModalDX from "../../business/addeditmodaldx";
import { useTranslation } from "react-i18next";
import TextFieldDX from "../../controls/textfielddx";
import GridDX from "../../layout/griddx";
import { useNotificationContext } from "../../../context/notificationcontext";
import SelectListDX from "../../controls/selectlistdx";
import Loading from "../../loading";
import TextEditorDX from "../../business/texteditordx";
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertFromRaw,
  convertToRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";

const DocumentEditorModal = (props: any) => {
  const { open, handleClose, documentEditorId, rows, setRows } = props;
  const { t, i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";
  //  initaially set to documentEditorData.editorContent (should not be empty)
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const { setError, setInfo } = useNotificationContext();

  const defaultValues = {
    id: 0,
    enName: "",
    arName: "",
    state: t("Open"),
    editableDocumentType: "",
    documentTemplate: "",
    editorContent: "",
    dueDate: new Date().toUTCString(),
    createdDate: "",
    modifiedDate: "",
  };

  const [documentEditorData, setDocumentEditorData] =
    useState<any>(defaultValues);
  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const [errors, setErrors] = useState<any>({});

  const validateForm = () => {
    const newErrors: any = {};

    if (documentEditorData.enName.length === 0)
      newErrors["enName"] = t("English name is required");
    if (documentEditorData.arName.length === 0)
      newErrors["arName"] = t("Arabic name is required");
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = () => {
    // all the content of the editor is in the editorRef.current.getContent()

    if (validateForm()) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
        if (documentEditorId) {
          setRows([
            ...rows.filter((row: any) => row.id !== documentEditorId),
            {
              ...documentEditorData,
              id: documentEditorId,
              modifiedDate: new Date().toUTCString(),
              editorContent: draftToHtml(
                convertToRaw(editorState.getCurrentContent())
              ),
            },
          ]);
          setInfo(t("Editable document updated successfully"));
        } else {
          setRows([
            ...rows,
            {
              ...documentEditorData,
              id: rows.length + 1,
              createdDate: new Date().toUTCString(),
              modifiedDate: new Date().toUTCString(),
              editorContent: draftToHtml(
                convertToRaw(editorState.getCurrentContent())
              ),
            },
          ]);
          setInfo(t("Editable document created successfully"));
        }
        handleClose(true);
      }, 2000);
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsChanged(true);

    if (name === "documentTemplate" && value) {
      // Load the content of the selected document into the editor
      const selectedDocument = documentList.find((doc) => doc.value === value);
      if (selectedDocument) {
        const contentState = ContentState.createFromBlockArray(
          convertFromHTML(selectedDocument.content).contentBlocks // Assuming you have a property named 'content' for each document
        );
        const editorStateFromHtml = EditorState.createWithContent(contentState);
        setEditorState(editorStateFromHtml);
      }
    }
    setDocumentEditorData({
      ...documentEditorData,
      [name]: type == "checkbox" ? checked : value,
    });
  };

  const documentList = [
    {
      value: "document1",
      text: t("Template 1"),
      content: "<p>Content of Document 1</p>",
    },
    {
      value: "document2",
      text: t("Template 2"),
      content: "<p>Content of Document 2</p>",
    },
    {
      value: "document3",
      text: t("Template 3"),
      content: "<p>Content of Document 3</p>",
    },
  ];

  const doctypeList = [
    { value: "doctype1", text: t("Document Type 1") },
    { value: "doctype2", text: t("Document Type 2") },
    { value: "doctype3", text: t("Document Type 3") },
  ];

  useEffect(() => {
    if (documentEditorId) {
      const contentEditRequest = rows.find(
        (row: any) => row.id === documentEditorId
      );
      const htmlContent = contentEditRequest.editorContent;
      if (htmlContent) {
        const contentState = ContentState.createFromBlockArray(
          convertFromHTML(htmlContent).contentBlocks
        );
        const editorStateFromHtml = EditorState.createWithContent(contentState);
        setEditorState(editorStateFromHtml);
      }

      setDocumentEditorData({ ...defaultValues, ...contentEditRequest });
    }
  }, [documentEditorId]);

  return (
    <AddEditModalDX
      open={open}
      handleClose={handleClose}
      isSaving={isLoading}
      isChanged={isChanged}
      title={
        documentEditorId
          ? t("Update Editable Document")
          : t("Create Editable Document")
      }
      maxWidth="sm"
      onSaveClick={onSave}
      onYesClick={handleClose}
    >
      {isLoading && (
        <Loading styles={{ height: "100%", width: "100%", left: 0 }} />
      )}
      <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
        <GridDX item xs={6}>
          <TextFieldDX
            label={t("English Name")}
            name="enName"
            value={documentEditorData.enName}
            onChange={handleInputChange}
            error={errors["enName"]}
          />
        </GridDX>
        <GridDX item xs={6}>
          <TextFieldDX
            label={t("Arabic Name")}
            name="arName"
            value={documentEditorData.arName}
            onChange={handleInputChange}
            error={errors["arName"]}
          />
        </GridDX>
        <GridDX item xs={12}>
          <SelectListDX
            label={`${t("Editable Document Type")}`}
            items={doctypeList}
            name="editableDocumentType"
            value={documentEditorData.editableDocumentType}
            onChange={handleInputChange}
            error={errors["editableDocumentType"]}
          />
        </GridDX>

        <GridDX item xs={12}>
          <SelectListDX
            label={`${t("Document Template")}`}
            items={documentList}
            name="documentTemplate"
            onChange={handleInputChange}
            value={documentEditorData.documentTemplate}
            error={errors["documentTemplate"]}
          />
        </GridDX>

        <GridDX item xs={12}>
          <TextEditorDX
            editorState={editorState}
            setEditorState={setEditorState}
          />
        </GridDX>
      </GridDX>
    </AddEditModalDX>
  );
};

export default DocumentEditorModal;
