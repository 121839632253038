import useSecureAPI from "../hooks/usesecureapi";

const useDeliveryService = () => {
  const secureAPI = useSecureAPI();

  const getDeliveryTypes = async () => {
    const response = await secureAPI.get(`DeliveryMethod`);
    return response.data;
  };

  const addDeliveryType = async (data: any) => {
    const response = await secureAPI.post(`DeliveryMethod`, data);  
    return response.data;
  };

  const deleteDeliveryType = async (id: number) => {
    const response = await secureAPI.delete(`DeliveryMethod/${id}`);
    return response.data;
  };

  return {
    getDeliveryTypes,
    addDeliveryType,
    deleteDeliveryType
  };
};

export default useDeliveryService;
