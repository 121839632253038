import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Typography, InputAdornment } from "@mui/material";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Tooltip from "@mui/material/Tooltip/Tooltip";

import GridDX from "../../../components/layout/griddx";
import TextFieldDX from "../../../components/controls/textfielddx";
import ButtonDX from "../../../components/controls/buttondx";
import LoginBox from "../../../components/layout/loginbox";
import LoadingOverlay from "../../../components/loadingoverlay";
import { useNotificationContext } from "../../../context/notificationcontext";
import { resetPassword } from "../../../shared/services/authservice";
import { validatePassword } from "../../../shared/globals";

const ResetPassword = () => {
  const defaultValues = {
    userName: "",
    password: "",
  };

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { t, i18n } = useTranslation();
  const { setError, setInfo } = useNotificationContext();

  const [userID, setUserID] = useState<any>(null);
  const [code, setCode] = useState<any>(null);
  const [errors, setErrors] = useState<any>();
  const [open, setOpen] = useState<boolean>(true);
  const [formValues, setFormValues] = useState(defaultValues);
  const [rePassword, setRePassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [rePasswordVisible, setRePasswordVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (searchParams && searchParams.size === 2)
      setUserID(searchParams.get("userId"));
    setCode(searchParams.get("code"));
  }, [searchParams]);

  useEffect(() => {
    validateForm();
  }, [formValues.password]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const validateForm = () => {
    const error = validatePassword(formValues.password);
    if (error !== "") {
      setErrors(error);
      setOpen(true);
    } else setOpen(false);
  };

  const handleReset = (event: any) => {
    event.preventDefault();

    if (formValues.password === rePassword) {
      setOpen(false);
      let request = {
        userId: userID,
        code: code,
        newPassword: formValues.password,
        confirmPassword: rePassword,
      };

      setIsLoading(true);
      resetPassword(request)
        .then((response) => {
          setInfo(t("Password Changed Successfully"));
          navigate("/");
        })
        .catch((error) => setError(error))
        .finally(() => setIsLoading(false));
    } else {
      setErrors(t("Password not matched"));
      setOpen(true);
    }
  };

  return (
    <>
      {isLoading && <LoadingOverlay />}
      <LoginBox title={t("Reset Password")}>
        {searchParams && searchParams.size === 2 ? (
          <GridDX container rowSpacing={4}>
            <GridDX item xs={12} justifyContent="center" textAlign="center">
              <Typography color="#625B71" fontSize={14}>
                {t("Password must be")}
              </Typography>
            </GridDX>
            <GridDX item xs={12} justifyContent="center">
              <Tooltip title={errors} arrow open={open}>
                <TextFieldDX
                  fullWidth
                  name="password"
                  type={passwordVisible ? "text" : "password"}
                  label={t("Password")}
                  value={formValues.password}
                  onChange={handleInputChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {passwordVisible ? (
                          <VisibilityOffIcon
                            sx={{ cursor: "pointer" }}
                            onClick={() => setPasswordVisible(!passwordVisible)}
                          />
                        ) : (
                          <VisibilityIcon
                            sx={{ cursor: "pointer" }}
                            onClick={() => setPasswordVisible(!passwordVisible)}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Tooltip>
            </GridDX>
            <GridDX item xs={12} justifyContent="center">
              <TextFieldDX
                fullWidth
                name="rePassword"
                type={rePasswordVisible ? "text" : "password"}
                label={t("Repeat Password")}
                value={rePassword}
                onChange={(e: any) => setRePassword(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {rePasswordVisible ? (
                        <VisibilityOffIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => setRePasswordVisible(!rePasswordVisible)}
                        />
                      ) : (
                        <VisibilityIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => setRePasswordVisible(!rePasswordVisible)}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </GridDX>
            <GridDX item xs={12} justifyContent="center">
              <ButtonDX
                fullWidth
                startIcon={
                  <ArrowRightAltIcon
                    sx={{
                      transform:
                        i18n.dir() === "rtl" ? "rotate(180deg)" : "inherit",
                    }}
                  />
                }
                disabled={open ? true : false}
                onClick={handleReset}
              >
                {t("Continue")}
              </ButtonDX>
            </GridDX>
          </GridDX>
        ) : (
          <GridDX container sx={{ width: "100%", height: "100%" }}>
            <GridDX
              item
              xs={12}
              sx={{ flexDirection: "column" }}
              justifyContent="center"
              alignItems="center"
            >
              <Typography sx={{ mb: 1 }} variant="h4">
                {t("Invalid Link")}
              </Typography>
            </GridDX>
          </GridDX>
        )}
      </LoginBox>
    </>
  );
};
export default ResetPassword;
