import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { TextField, MenuItem, Button, Menu } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import MenuItemDX from "./menuitemdx";

const ActionDropDownMenuDx = (props: any) => {

  const { label, actions, rowSelectionModel } = props;
  const { i18n, t } = useTranslation();
  const loading = props.loading ?? false;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (loading)
    return (
      <Skeleton
        containerClassName="skeleton-container"
        style={{ height: 56 }}
      />
    );

  return (<>
    <Button onClick={handleClick} endIcon={<KeyboardArrowDown style={{ fontSize: '24px' }} />} > {t(label)} </Button>
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {actions.map((action: any, index: number) => {
        return (
          <MenuItemDX key={index}
            onClick={() => {
              rowSelectionModel ? action.onClick(rowSelectionModel) : action.onClick()

              handleClose()
            }}
            sx={{ minWidth: 110 }}
          >
            {action.label}</MenuItemDX>
        )
      })}
    </Menu >
  </>
  );








};

export default ActionDropDownMenuDx;
