import useSecureAPI from "../hooks/usesecureapi";

const useUserGroupService = () => {
  const secureAPI = useSecureAPI();

  const getUserGroups = async () => {
    const response = await secureAPI.get("UserGroup");
    return response.data;
  };
  
  const getUserGroupById = async (id: number) => {
    const response = await secureAPI.get(`UserGroup/${id}`);
    return response.data;
  };
  const addUserGroup = async (data: any) => {
    const response = await secureAPI.post("UserGroup", data);
    return response.data;
  };

  const updateUserGroup = async (id: number, data: any) => {
    const response = await secureAPI.put(`UserGroup`, data);
    return response.data;
  };

  const deleteUserGroup = async (id: number) => {
    const response = await secureAPI.delete(`UserGroup/${id}`);
    return response.data;
  };

  return {
    getUserGroups,
    getUserGroupById,
    addUserGroup,
    updateUserGroup,
    deleteUserGroup
  };
};

export default useUserGroupService;
