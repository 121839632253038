import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AddEditModalDX from "../../business/addeditmodaldx";
import GridDX from "../../layout/griddx";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Collapse, Divider, IconButton, LinearProgress } from "@mui/material";
import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined';
import { useNotificationContext } from "../../../context/notificationcontext";
import useCabinetService from "../../../shared/services/cabinetservice";
import useFolderService from "../../../shared/services/folderservice";

const MoveModal = (props: any) => {
  const { t, i18n } = useTranslation();
  const { setError } = useNotificationContext();
  const { getCabinets} = useCabinetService();
  const { getFolderTreeByCabinet } = useFolderService();

  const [selectedIndex, setSelectedIndex] = useState<any>(null);
  const [cabinets, setCabinets] = useState<any>([]);
  const [tree, setTree] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(()=>{
    getData();
  }, []);

  const getData = async () => {
    // setIsLoading(true);
    getCabinets()
      .then( async (res: any) => {
        setCabinets(res);
        setSelectedIndex(res[0].cabinetId);
        getTree(res[0]);
      })
      .catch((err: any) => setError(err));
      // .finally(() => setIsLoading(false));
  };

  const getTree = async (cabinet: any) => {
    setIsLoading(true);
    getFolderTreeByCabinet(cabinet.cabinetId)
      .then((res: any) => {
        setTree(res);
        props.onFolderSelect({
          folderId: null,
          cabinetId: cabinet.cabinetId
        });
      })
      .catch((err: any) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const toSave = () => {
    if (props.folder?.folderId === null) setError(t("No folder selected"))
    else props.toMove();
  };

  return(
    <AddEditModalDX
      open={props.open}
      handleClose={props.handleClose}
      isSaving={isLoading}
      title={t("Move")}
      maxWidth="md"
      onSaveClick={toSave}
      onYesClick={toSave}
    >
    <GridDX
      container
      sx={{ width: "100%", height: "100%" }}
    >
      <GridDX
        item
        xs={12}
        sx={{
          overflowX: "hidden",
          flexDirection: "column",
          height: "90%",
          borderRight: (i18n.language === "en" ? "1px solid #dcdcdc" : "0"),
          borderLeft: (i18n.language === "en" ? 0 : "1px solid #dcdcdc")
        }}
      >
        <List
          sx={{ width: "100%", direction: i18n.dir(), maxHeight: 800 }}
          disablePadding
        >
          {(
            cabinets.map((item: any) => (
              <>
                <ListItem disablePadding>
                  <ListItemButton
                    selected={item.cabinetId === selectedIndex}
                    onClick={() => {
                      setSelectedIndex(item.cabinetId);
                      getTree(item);
                    }}
                  >
                    <ListItemIcon>
                      <DnsOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        i18n.language === "en" ? item.enName : item.arName
                      }
                      primaryTypographyProps={
                        (item.cabinetId === selectedIndex)
                          ? { textAlign: "start", fontWeight: "bold" }
                          : { textAlign: "start" }
                      }
                    />
                  </ListItemButton>
                </ListItem>     
                <Collapse in={item.cabinetId === selectedIndex} timeout="auto" unmountOnExit sx={i18n.language === "en" ? {pl: 3} : {pr: 3}}>
                  <List component="div" disablePadding sx={i18n.language === "en" ? {borderLeft: 1} : {borderRight: 1}}>
                    {isLoading ? (
                      <LinearProgress />
                    ) : (
                      tree?.map((folder: any) => (
                        <SubDirectories directory={folder} folder={props.folder} onFolderChange={props.onFolderSelect}/>
                    )))}   
                  </List>
                </Collapse>
                <Divider />
              </>
            ))
          )}
        </List>
      </GridDX>
      </GridDX>
    </AddEditModalDX>
  )
};

export default MoveModal;

const SubDirectories = (props: any) => {
  const { i18n } = useTranslation();
  const [open, setOpen] = useState(false);

  if (props.directory.length === 0) return (<></>);
  else return (
    <>
    <ListItem disablePadding>
      <ListItemButton
        selected={props.folder?.folderId === props.directory.folderId}
        onClick={() => {
          props.onFolderChange(props.directory);
        }}
      >
        <ListItemText
          primary={
            i18n.language === "en" ? props.directory.enName : props.directory.arName
          }
          primaryTypographyProps={
            (props.folder?.folderId === props.directory.folderId)
              ? { textAlign: "start", fontWeight: "bold" }
              : { textAlign: "start" }
          }
        />
      </ListItemButton>
      <IconButton
        edge="end"
        sx={{mx: 1}}
        onClick={() => {
          setOpen(!open);
        }}
      >
        {props.directory.folders.length > 0 && (open ? <ExpandLess /> : <ExpandMore />)}
      </IconButton>
    </ListItem>
    <Collapse in={open} timeout="auto" unmountOnExit sx={i18n.language === "en" ? {pl: 3} : {pr: 3}}>
      <List component="div" disablePadding sx={i18n.language === "en" ? {borderLeft: 1} : {borderRight: 1}}>
          {props.directory.folders.map((subDirectory: any) => (
            <SubDirectories directory={subDirectory} folder={props.folder} onFolderChange={props.onFolderChange}/>
          ))}
      </List>
    </Collapse>
    </>
  );
};
