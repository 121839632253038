import GridDX from "../../components/layout/griddx";
import PageTitle from "../../components/pagetitle";
import MailOutlineOutlined from "@mui/icons-material/MailOutlineOutlined";
import {
  HourglassBottomOutlined,
  WarningAmberOutlined,
} from "@mui/icons-material";
import DashbordTabs from "../../components/pages/dashboard/dashboardtabs";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";

import useDashboardService from "../../shared/services/dashboardservice";
import { useNotificationContext } from "../../context/notificationcontext";
import WhiteBox from "../../components/layout/whitebox";
import ChartBox from "../../components/layout/chartbox";
import TypeTranslator from "../../shared/typetranslator";
import FilterRange from "../../components/controls/filterrange";
import { useAuthContext } from "../../context/authcontext";
import momentHijri from "moment-hijri";
import { APIDateFormatter } from "../../shared/globals";

const Dashboard = () => {
  const {
    getCorrespondenceTotalCount,
    getClosedCorrespondenceTotalCount,
    getAvgClosureDaysCount,
    getOverDueCorrespondenceCount,
    getCorrespondenceCountByType,
    getCorrespondenceCountByStatus,
  } = useDashboardService();
  const { t, i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";
  const { setError } = useNotificationContext();
  const { userData } = useAuthContext();
  const { calendarId } = userData;

  const date = new Date();
  const [filterFromDate, setFilterFromDate] = useState<any>(
    calendarId == 1 ? date.setDate(date.getDate() - 30) : momentHijri(date)
  );
  const [filterToDate, setFilterToDate] = useState<any>(
    calendarId == 1 ? new Date() : momentHijri(new Date())
  );

  const { getCorrespondenceStatusValue } = TypeTranslator();

  const [correspondenceCount, setCorrespondenceCount] = useState(0);
  const [closedCount, setClosedCount] = useState(0);
  const [avgClosureDaysCount, setAvgClosureDaysCount] = useState(0);
  const [overDueCorresnpondenceCount, setOverDueCorrespondenceCount] =
    useState(0);
  const [correspondenceCountByType, setCorrespondenceCountByType] = useState<
    any[]
  >([]);
  const [correspondenceCountByStatus, setCorrespondenceCountByStatus] =
    useState<any[]>([]);

  const [isLoadingCorrespondenceCount, setIsLoadingCorrespondenceCount] =
    useState(false);
  const [isLoadingClosedCount, setIsLoadingClosedCount] = useState(false);
  const [isLoadingAvgClosureDays, setIsLoadingAvgClosureDays] = useState(false);
  const [isLoadingOverDueCorrespondence, setIsLoadingOverDueCorrespondence] =
    useState(false);
  const [isLoadingCorrespondenceByType, setIsLoadingCorrespondenceByType] =
    useState(false);
  const [isLoadingCorrespondenceByStatus, setIsLoadingCorrespondenceByStatus] =
    useState(false);

  const handleCorrespondenceTotalCount = () => {
    setIsLoadingCorrespondenceCount(true);
    getCorrespondenceTotalCount(
      APIDateFormatter(filterFromDate),
      APIDateFormatter(filterToDate)
    )
      .then((correspondenceCount) => {
        setCorrespondenceCount(correspondenceCount);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setIsLoadingCorrespondenceCount(false);
      });
  };

  const handleClosedTotalCount = () => {
    setIsLoadingClosedCount(true);
    getClosedCorrespondenceTotalCount(
      APIDateFormatter(filterFromDate),
      APIDateFormatter(filterToDate)
    )
      .then((correspondenceCount) => {
        setClosedCount(correspondenceCount);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setIsLoadingClosedCount(false);
      });
  };

  const handleAvgClosureDaysCount = () => {
    setIsLoadingAvgClosureDays(true);
    getAvgClosureDaysCount(
      APIDateFormatter(filterFromDate),
      APIDateFormatter(filterToDate)
    )
      .then((avgClosureDaysCount) => {
        setAvgClosureDaysCount(avgClosureDaysCount);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setIsLoadingAvgClosureDays(false);
      });
  };

  const handleOverDueCorrespondenceCount = () => {
    setIsLoadingOverDueCorrespondence(true);
    getOverDueCorrespondenceCount(
      APIDateFormatter(filterFromDate),
      APIDateFormatter(filterToDate)
    )
      .then((overDueCorrespondenceCount) => {
        setOverDueCorrespondenceCount(overDueCorrespondenceCount);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setIsLoadingOverDueCorrespondence(false);
      });
  };

  const handleCorrespondenceCountByType = () => {
    setIsLoadingCorrespondenceByType(true);
    getCorrespondenceCountByType(
      APIDateFormatter(filterFromDate),
      APIDateFormatter(filterToDate)
    )
      .then((correspondenceCountByType) => {
        setCorrespondenceCountByType(
          correspondenceCountByType.map((item: any) => ({
            id: item.typeId,
            label: languageIsEn ? item.enName : item.arName,
            value: item.count,
          }))
        );
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setIsLoadingCorrespondenceByType(false);
      });
  };

  const handleCorrespondenceCountByStatus = () => {
    setIsLoadingCorrespondenceByStatus(true);
    getCorrespondenceCountByStatus(
      APIDateFormatter(filterFromDate),
      APIDateFormatter(filterToDate)
    )
      .then((correspondenceCountByStatus) => {
        setCorrespondenceCountByStatus(
          correspondenceCountByStatus.map((item: any) => ({
            id: item.status,
            label: getCorrespondenceStatusValue(item.status),
            value: item.count,
          }))
        );
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setIsLoadingCorrespondenceByStatus(false);
      });
  };

  const handleChangeFromDate = (date: any) => {
    setFilterFromDate(
      calendarId == 1 ? new Date(date) : momentHijri(new Date(date))
    );
    const filterDate =
      calendarId == 1 ? new Date(date) : momentHijri(new Date(date));
    if (filterToDate != null && filterDate > filterToDate) {
      setError(t("From date must be less than to date"));
      setFilterFromDate(null);
    }
  };
  const handleChangeToDate = (date: any) => {
    setFilterToDate(
      calendarId == 1 ? new Date(date) : momentHijri(new Date(date))
    );
    const filterDate =
      calendarId == 1 ? new Date(date) : momentHijri(new Date(date));

    if (filterFromDate != null && filterDate < filterFromDate) {
      setError(t("To date must be greater than from date"));
      setFilterToDate(null);
    }
  };

  useEffect(() => {
    handleCorrespondenceTotalCount();
    handleAvgClosureDaysCount();
    handleOverDueCorrespondenceCount();
    handleCorrespondenceCountByType();
    handleCorrespondenceCountByStatus();
  }, [i18n.language, filterFromDate, filterToDate]);

  return (
    <GridDX container sx={{ width: "100%" }} rowSpacing={2} columnSpacing={1}>
      <GridDX item xs={12}>
        <PageTitle />
      </GridDX>
      <GridDX item xs={12} justifyContent="end">
        <FilterRange
          filterFromDate={filterFromDate}
          filterToDate={filterToDate}
          handleChangeFromDate={handleChangeFromDate}
          handleChangeToDate={handleChangeToDate}
        />
      </GridDX>
      <GridDX item lg={1.4} sm={3} xs={12}>
        <WhiteBox
          title={t("Correspondence")}
          loading={isLoadingCorrespondenceCount}
          count={correspondenceCount}
          // percentage={"12%"}
          icon={<MailOutlineOutlined sx={{ fontSize: 80 }} />}
        />
      </GridDX>
      <GridDX item lg={1.4} sm={3} xs={12}>
        <WhiteBox
          title={t("Closed")}
          loading={isLoadingClosedCount}
          count={closedCount}
          // percentage={"0%"}
          icon={<MarkEmailReadOutlinedIcon sx={{ fontSize: 80 }} />}
        />
      </GridDX>
      <GridDX item lg={1.4} sm={3} xs={12}>
        <WhiteBox
          title={t("Over Due")}
          loading={isLoadingOverDueCorrespondence}
          count={overDueCorresnpondenceCount}
          // percentage={"0%"}
          icon={<WarningAmberOutlined sx={{ fontSize: 80 }} />}
        />
      </GridDX>
      <GridDX item lg={1.8} sm={3} xs={12}>
        <WhiteBox
          title={t("Avg. Closure Days")}
          loading={isLoadingAvgClosureDays}
          count={avgClosureDaysCount}
          // percentage={"-5%"}
          icon={<HourglassBottomOutlined sx={{ fontSize: 80 }} />}
        />
      </GridDX>
      <GridDX item lg={3} xs={12}>
        <ChartBox
          title={t("Type")}
          data={correspondenceCountByType}
          loading={isLoadingCorrespondenceByType}
        />
      </GridDX>
      <GridDX item lg={3} xs={12}>
        <ChartBox
          title={t("Status")}
          data={correspondenceCountByStatus}
          loading={isLoadingCorrespondenceByStatus}
        />
      </GridDX>
      <GridDX item xs={12} sm={12}>
        <DashbordTabs />
      </GridDX>
    </GridDX>
  );
};

export default Dashboard;
