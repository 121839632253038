import { useEffect, useState } from "react";
import AddEditModalDX from "../business/addeditmodaldx";
import { useTranslation } from "react-i18next";
import TextFieldDX from "../controls/textfielddx";
import GridDX from "../layout/griddx";
import { useNotificationContext } from "../../context/notificationcontext";
import useUserGroupService from "../../shared/services/usergroupservice";
import AutoCompleteMultiple from "../controls/autocompletemultiple";
import useUserService from "../../shared/services/userservices";
import Loading from "../loading";

const UserGroupModal = (props: any) => {
  const { groupId, open, handleClose } = props;
  const { t, i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";
  const { setError, setInfo } = useNotificationContext();
  const { updateUserGroup, addUserGroup, getUserGroupById } =
    useUserGroupService();
  const { getActiveUsers } = useUserService();
  const [usersDropDownData, setUsersDropDownData] = useState<any>([]);
  interface GroupData {
    enName: string;
    arName: string;
    description: string;
    userIds: Array<any>;
  }

  const defaultValues = {
    enName: "",
    arName: "",
    description: "",
    userIds: [],
  };

  const [groupData, setGroupData] = useState<GroupData>(defaultValues);
  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    setIsLoading(true);
    const p1 = getActiveUsers();
    const p2 = groupId ? getUserGroupById(groupId) : null;
    Promise.all([p1, p2])
      .then(([users, group]) => {
        // all users dropdown options
        const dataForUsersDropDown = users.map((res: any) => ({
          text: languageIsEn ? res.enFullName : res.arFullName,
          value: res.id,
        }));
        setUsersDropDownData(dataForUsersDropDown);
        console.log("dataForUsersDropDown", dataForUsersDropDown);

        if (groupId) {
          const { users, ...rest } = group;
          // to populate already selected users in the group
          const updatedUsersDataForDropDown = users.map((res: any) => ({
            text: languageIsEn ? res.enFullName : res.arFullName,
            value: res.id,
          }));
          setGroupData({ ...rest, userIds: updatedUsersDataForDropDown });
        }
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  }, []);

  const validateForm = () => {
    const newErrors: any = {};

    if (groupData.enName.length === 0)
      newErrors["enName"] = t("English name is required");
    if (groupData.arName.length === 0)
      newErrors["arName"] = t("Arabic name is required");
    if (groupData.description.length === 0)
      newErrors["description"] = t("Group Description is required");
    // if (groupData.users.length === 0)
    //   newErrors["users"] = "Users are required";

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = async () => {
    if (!validateForm()) {
      return;
    }
    setIsLoading(true);
    const { userIds } = groupData;
    const users = userIds.map((e: any) => e.value);
    try {
      const operation = groupId
        ? updateUserGroup(groupId, { ...groupData, userIds: users })
        : addUserGroup({ ...groupData, userIds: users });

      await operation;
      setInfo(
        t(groupId ? "Group updated successfully" : "Group created successfully")
      );
      handleClose(true);
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setIsChanged(true);
    setGroupData({
      ...groupData,
      [name]: value,
    });
  };

  return (
    <AddEditModalDX
      open={open}
      handleClose={handleClose}
      isSaving={isLoading}
      isChanged={isChanged}
      title={groupId ? t("Edit Group") : t("Create a Group")}
      maxWidth="sm"
      onSaveClick={onSave}
      onYesClick={handleClose}
    >
      {isLoading && (
        <Loading styles={{ height: "100%", width: "100%", left: 0 }} />
      )}

      <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
        <GridDX item xs={12}>
          <TextFieldDX
            label={t("English Name")}
            name="enName"
            value={groupData.enName}
            onChange={handleInputChange}
            error={errors["enName"]}
          />
        </GridDX>
        <GridDX item xs={12}>
          <TextFieldDX
            label={t("Arabic Name")}
            name="arName"
            value={groupData.arName}
            onChange={handleInputChange}
            error={errors["arName"]}
          />
        </GridDX>
        <GridDX item xs={12}>
          <TextFieldDX
            label={t("Description")}
            name="description"
            value={groupData.description}
            onChange={handleInputChange}
            error={errors["description"]}
          />
        </GridDX>
        <GridDX item xs={12}>
          <AutoCompleteMultiple
            sx={{ width: "100%" }}
            label={t("Group Members")}
            name="userIds"
            placeholder={t("Member")}
            value={groupData.userIds}
            onChange={(newValue: any) => {
              setIsChanged(true);
              setGroupData({
                ...groupData,
                userIds: newValue,
              });
            }}
            error={errors["userIds"]}
            list={usersDropDownData}
          />
        </GridDX>
      </GridDX>
    </AddEditModalDX>
  );
};

export default UserGroupModal;
