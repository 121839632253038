import useSecureAPI from "../hooks/usesecureapi";

const useCabinetService = () => {
  const secureAPI = useSecureAPI();

  const getCabinets = async () => {
    const response = await secureAPI.get(`dms/Cabinet/`);
    return response.data;
  };
  
  const getCabinetByID = async (id: number) => {
    const response = await secureAPI.get(`dms/Cabinet/${id}`);
    return response.data;
  };

  const getArchivedContentByCabinetID = async (id: number) => {
    const response = await secureAPI.get(`dms/Cabinet/archivedcontents/${id}`);
    return response.data;
  };  

  const addCabinet = async (data: any) => {
    const response = await secureAPI.post(`dms/Cabinet/`, data);
    return response.data; 
  };

  const updateCabinet = async (data: any) => {
    const response = await secureAPI.put(`dms/Cabinet/`, data);
    return response.data;
  };

  const deleteCabinet = async (id: number) => {
    const response = await secureAPI.delete(`dms/Cabinet/${id}`);
    return response.data;
  };

  return {
    getCabinets,
    getCabinetByID,
    getArchivedContentByCabinetID,
    addCabinet,
    updateCabinet,
    deleteCabinet,
  };
};

export default useCabinetService;