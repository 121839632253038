import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import SelectListDX from "./selectlistdx";

const LanguageSwitcher = (props: any) => {
  const { color } = props;
  const { i18n } = useTranslation();

  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  useEffect(() => {
    // i18n.language;
  }, []);

  const changeLanguageHandler = (e: any) => {
    const languageValue = e.target.value;
    setCurrentLanguage(languageValue);
    i18n.changeLanguage(languageValue);

    localStorage.setItem("lastLanguage", languageValue);
  };

  return (
    <SelectListDX
      fullWidth={false}
      variant="standard"
      inputProps={{
        sx: {
          color: color || "white",

        },

      }}
      sx={{
        width: '100% !important',
        "& .MuiInput-root:before": { borderBottomColor: color || "white !important" },
        "& .MuiSvgIcon-root": { color: color || "white !important" },
        textAlign: currentLanguage == 'en' ? "left" : 'right',
      }}
      value={currentLanguage}
      onChange={(e: any) => changeLanguageHandler(e)}
      items={[
        { value: "en", text: "English" },
        { value: "ar", text: "العربية" },
      ]}
    />
  );
};

export default LanguageSwitcher;
