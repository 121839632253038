import { apiCall } from "../api/api";

export const login = async (credentials: any): Promise<any> => {
  const response = await apiCall.post("Account/Login", credentials);
  return response.data;
};

export const forgetPassword = async (email: any): Promise<any> => {
  const response = await apiCall.post("Account/ForgetPassword", email);
  return response.data;
};

export const resetPassword = async (request: any): Promise<any> => {
  const response = await apiCall.post("Account/ResetPassword", request);
  return response.data;
};