import { useState } from "react";
import { useTranslation } from "react-i18next";
import ButtonDX from "./controls/buttondx";
import GridDX from "./layout/griddx";
import DeleteModal from "./controls/deleteModal";
import {
  AddOutlined,
  ChevronRightOutlined,
  DeleteOutline,
  EditOutlined,
  ExpandMoreOutlined,
} from "@mui/icons-material";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { TreeView } from "@mui/x-tree-view/TreeView";

const TreeViewDX = (props: any) => {
  const { t, i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";
  const {
    toCreate,
    onEdit,
    onDelete,
    createLabel,
    deleteLabel,
    name,
    data,
    loading,
  } = props;
  const [deleteModal, setDeleteModal] = useState({ open: false, id: null });

  const createBtn = toCreate || false;

  const handleDeleteModalOpen = (node: any) => {
    setDeleteModal({ open: true, id: node?.id });
  };

  const confirmDelete = () => {
    onDelete(deleteModal.id);
    setDeleteModal({ open: false, id: null });
  };
  return (
    <GridDX
      container
      sx={{ my: 1, height: "100%" }}
      alignItems="center"
      columnSpacing={1}
      rowSpacing={1}
    >
      {deleteModal.open && (
        <DeleteModal
          open={deleteModal.open}
          deleteLabel={deleteLabel}
          name={name}
          onDeleteClick={confirmDelete}
          onCancelClick={() => setDeleteModal({ open: false, id: null })}
        />
      )}
      {createBtn && data.length == 0 && (
        <GridDX
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <ButtonDX onClick={toCreate}> {createLabel || t("Create")}</ButtonDX>
        </GridDX>
      )}

      <GridDX item xs={12} sx={{ height: "100%" }}>
        <Tree
          data={data}
          toCreate={toCreate}
          onEdit={onEdit}
          handleDeleteModalOpen={handleDeleteModalOpen}
        />
      </GridDX>
    </GridDX>
  );
};

const Tree = (props: any) => {
  const { data, handleDeleteModalOpen, toCreate, onEdit } = props;
  const { i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  const renderTree = (nodes: any) => (
    <TreeItem
      key={nodes?.id?.toString()}
      nodeId={nodes?.id?.toString()}
      label={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span>
            {isArabic ? nodes.arName || nodes.name : nodes.enName || nodes.name}{" "}
          </span>
          {/* <span>{nodes.name}</span> */}
          <div>
            <AddOutlined onClick={() => toCreate(nodes)} />
            <EditOutlined onClick={() => onEdit(nodes)} />
            <DeleteOutline onClick={() => handleDeleteModalOpen(nodes)} />
          </div>
        </div>
      }
      sx={{
        borderBottom: "1px solid #e0e0e0",
        "& .MuiTreeItem-label": {
          fontWeight: "bold",
          color: "#333",
          fontSize: "14px",
        },
        "& .MuiTreeItem-content": {
          paddingLeft: `${20 + (nodes.level - 1) * 16}px`,
          display: "flex",
          alignItems: "center",
          transition: "background-color 0.3s",
          "&:hover": {
            backgroundColor: "#f5f5f5",
          },
        },
        "& .MuiTreeItem-iconContainer": {
          marginRight: "8px",
        },
      }}
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map((node: any) => renderTree({ ...node }))
        : null}
    </TreeItem>
  );

  return (
    <>
      {data?.length !== 0 && (
        <GridDX
          container
          rowSpacing={1}
          sx={{
            flexGrow: 1,
            padding: "8px",
            border: "1px solid #ddd",
            borderRadius: "8px",
            overflow: "auto",
          }}
        >
          {data.map((item: any) => {
            return (
              <GridDX item xs={12} key={item?.id}>
                <TreeView
                  aria-label="custom tree view"
                  defaultCollapseIcon={<ExpandMoreOutlined />}
                  defaultExpandIcon={<ChevronRightOutlined />}
                  sx={{
                    width: "100%",
                  }}
                >
                  {renderTree({ ...item })}
                </TreeView>
              </GridDX>
            );
          })}
        </GridDX>
      )}
    </>
  );
};

export default TreeViewDX;
