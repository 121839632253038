import { useState } from "react";
import { useTranslation } from "react-i18next";
import AutoCompleteMultiple from "../../controls/autocompletemultiple";
import GridDX from "../../layout/griddx";
import AddEditModalDX from "../../business/addeditmodaldx";
import SelectListDX from "../../controls/selectlistdx";

const LinkedItemModal = (props: any) => {
  const { t, i18n } = useTranslation();

  const [isChanged, setIsChanged] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [items, setItems] = useState<any>([]);
  const [errors, setErrors] = useState<any>({});

  const validateForm = () => {
    const newErrors: any = {};

    if (items.length === 0)
      newErrors["users"] = t("This field cannot be empty");

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = async () => {
    if (validateForm()) {}
  };

  const handleInputChange = (newValue: any) => {
    setIsChanged(true);
    setItems(newValue);
  };

  return (
    <AddEditModalDX
      open={props.open}
      handleClose={props.handleClose}
      isSaving={isSaving}
      isChanged={isChanged}
      title={t("Link Item")}
      maxWidth="sm"
      onSaveClick={onSave}
    >
      <GridDX
        container
        sx={{ width: "100%", height: "100%", my: 1 }}
        alignItems="center"
        columnSpacing={1}
        rowSpacing={2}
      >
        <GridDX item xs={6}>
          <SelectListDX
            label={t("Type")}
            items={[
              {text: "Correspondence", value: 1},
              {text: "Document", value: 2},
            ]}
            name="type"
          />
        </GridDX>
        <GridDX item xs={6}>
          <AutoCompleteMultiple
            sx={{ width: "100%" }}
            label={t("Item")}
            name="item"
            list={[
              {text: "test 1", value: 1},
              {text: "test 2", value: 2},
              {text: "test 3", value: 3},
            ]}
            value={items}
            onChange={handleInputChange}
          />
        </GridDX>
      </GridDX>
    </AddEditModalDX>
  );
};

export default LinkedItemModal;
