import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import ListPageDX from "../../../components/business/listpagedx";
import { useNotificationContext } from "../../../context/notificationcontext";
import { DateSorter, DateTimeFormatter } from "../../../shared/globals";
import StructureTemplateModal from "../../../components/editmodals/DMS/structureTemplateModal";
import useFolderTemplateService from "../../../shared/services/foldertemplateservice";
import { useAuthContext } from "../../../context/authcontext";

const StructureTemplates = () => {
  const { t, i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";
  const { setInfo, setError } = useNotificationContext();
  const [folderTemplateId, setFolderTemplateId] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { userData } = useAuthContext();
  const { calendarId, gregorianDateFormatId, hijriDateFormatId } = userData;
  const [open, setOpen] = useState(false);
  const { getFolderTemplates, deleteFolderTemplate } =
    useFolderTemplateService();

  const [rows, setRows] = useState<any>([]);
  const [folderTemplates, setFolderTemplates] = useState<any>([]);

  const columns: GridColDef[] = [
    {
      field: languageIsEn ? "enName" : "arName",
      headerName: `${t("Name")}`,
      flex: 1,
    },
    {
      field: "createdDate",
      headerName: `${t("Created at")}`,
      flex: 1,
      sortComparator: DateSorter,
    },
    {
      field: "modifiedDate",
      headerName: `${t("Last modified at")}`,
      flex: 1,
      sortComparator: DateSorter,
    },
  ];

  const getData = () => {
    setIsLoading(true);
    getFolderTemplates() //fetch user data from APi
      .then((res) => {
        const preprocessedData = res.map((template: any) => {
          return {
            ...template,
            createdDate: DateTimeFormatter(
              template.createdDate,
              calendarId,
              gregorianDateFormatId,
              hijriDateFormatId
            ),
            modifiedDate: DateTimeFormatter(
              template.modifiedDate,
              calendarId,
              gregorianDateFormatId,
              hijriDateFormatId
            ),
          };
        });

        setRows(preprocessedData);
        setFolderTemplates(preprocessedData);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onDelete = (id: number) => {
    setIsLoading(true);
    deleteFolderTemplate(id)
      .then(() => {
        setInfo(t("Structure Template deleted successfully"));
        getData();
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onEdit = (template: any) => {
    setOpen(!open);
    setFolderTemplateId(template.folderTemplateId);
  };

  const toCreate = () => {
    setOpen(!open);
  };

  const onClose = (refreshPage: boolean = false) => {
    setOpen(!open);
    setFolderTemplateId(null);

    if (refreshPage) getData();
  };

  useEffect(() => {
    getData();
  }, [i18n.language]);

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(folderTemplates);
      return;
    }

    const newRows = folderTemplates.filter(
      ({ enName, arName, createdDate, modifiedDate }: any) =>
        languageIsEn
          ? enName.toLowerCase().includes(value.toLowerCase()) ||
            createdDate.includes(value) ||
            modifiedDate.includes(value)
          : arName.includes(value) ||
            createdDate.includes(value) ||
            modifiedDate.includes(value)
    );
    setRows(newRows);
  };

  return (
    <>
      {open && (
        <StructureTemplateModal
          open={open}
          handleClose={onClose}
          folderTemplateId={folderTemplateId}
        />
      )}
      <ListPageDX
        listTitle={t("Structure Templates")}
        name={t("Structure Template")}
        rows={rows}
        columns={columns}
        getRowId={(row: any) => row.folderTemplateId}
        isLoading={isLoading}
        toCreate={toCreate}
        onEdit={onEdit}
        onDelete={onDelete}
        setGridFilterCriteria={setGridFilterCriteria}
      />
    </>
  );
};

export default StructureTemplates;
