import { useEffect, useState } from "react";
import AddEditModalDX from "../../business/addeditmodaldx";
import { useTranslation } from "react-i18next";
import TextFieldDX from "../../controls/textfielddx";
import GridDX from "../../layout/griddx";
import { useNotificationContext } from "../../../context/notificationcontext";
import SelectListDX from "../../controls/selectlistdx";
import Loading from "../../loading";
import AutoCompleteMultiple from "../../controls/autocompletemultiple";
import CheckBoxDX from "../../controls/checkboxdx";
import useCabinetService from "../../../shared/services/cabinetservice";
import useUserService from "../../../shared/services/userservices";

const CabinetModal = (props: any) => {
  const { open, handleClose, cabinetId, rows, setRows } = props;
  const { t, i18n } = useTranslation();
  const { getActiveUsers } = useUserService();
  const { getCabinetByID, addCabinet, updateCabinet } = useCabinetService();
  const languageIsEn = i18n.language === "en";

  const { setError, setInfo } = useNotificationContext();

  const defaultValues = {
    enName: "",
    arName: "",
    cabinetMembers: [],
    cabinetManagers: [],
    cabinetDataEntryUsers: [],
    cabinetFollowers: []
  };

  const [cabinetData, setCabinetData] = useState<any>(defaultValues);
  const [userList, setUserList] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [dataFromApi, setDataFromApi] = useState<any>([]);
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    if (cabinetId) getCabinetData();
    else getUsers();
  }, []);

  const getUsers = async () => {
    setIsLoading(true);
    getActiveUsers()
      .then((res: any) => {
        const list = res.map((user: any) => ({
          text: languageIsEn ? user.enFullName : user.arFullName,
          value: user.id,
        }));
        setUserList(list);
      })
      .catch((err: any) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const getCabinetData = async () => {
    const p1 = getCabinetByID(cabinetId);
    const p2 = getActiveUsers();

    setIsLoading(true);
    Promise.all([p1, p2])
      .then(([cabinet, users]) => {

        const cabinetData = {
          ...cabinet,
          cabinetDataEntryUsers: cabinet.cabinetDataEntryUsers.map((user: any) => {
            return {
              value: user.cabinetDataEntryUserId,
              text: languageIsEn ? user.user.enFullName : user.user.arFullName,
            };
          }),
          cabinetFollowers: cabinet.cabinetFollowers.map((user: any) => {
            return {
              value: user.cabinetFollowerId,
              text: languageIsEn ? user.user.enFullName : user.user.arFullName,
            };
          }),
          cabinetManagers: cabinet.cabinetManagers.map((user: any) => {
            return {
              value: user.cabinetManagerId,
              text: languageIsEn ? user.user.enFullName : user.user.arFullName,
            };
          }),
          cabinetMembers: cabinet.cabinetMembers.map((user: any) => {
            return {
              value: user.cabinetMemberId,
              text: languageIsEn ? user.user.enFullName : user.user.arFullName,
            };
          }),
        };

        setDataFromApi(cabinet);
        setCabinetData(cabinetData);

        const list = users.map((user: any) => ({
          text: languageIsEn ? user.enFullName : user.arFullName,
          value: user.id,
        }));
        setUserList(list);

      })
      .catch((err: any) => setError(err))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (dataFromApi.cabinetMembers) {
      let deletedMembers = dataFromApi.cabinetMembers.filter(
        (user: any) =>
          !cabinetData.cabinetMembers.some(
            (item: any) => item.value === user.cabinetMemberId
          )
      );
      deletedMembers = deletedMembers.map((user: any) => {
        return { ...user, isDeleted: true };
      });
      let mergedMembers = dataFromApi.cabinetMembers.map(
        (user: any) => {
          let deleteduser = deletedMembers.find(
            (deleted: any) =>
              deleted.cabinetMemberId === user.cabinetMemberId
          );
          if (deleteduser) {
            // Merge the user with the deleteduser and return
            return { ...user, isDeleted: true };
          } else {
            return user;
          }
        }
      );
      const updatedDataFromApi = {
        ...dataFromApi,
        cabinetMembers: mergedMembers,
      };
      setDataFromApi(updatedDataFromApi);
    }
  }, [cabinetData.cabinetMembers.length]);

  useEffect(() => {
    if (dataFromApi.cabinetFollowers) {
      let deletedFollowers = dataFromApi.cabinetFollowers.filter(
        (user: any) =>
          !cabinetData.cabinetFollowers.some(
            (item: any) => item.value === user.cabinetFollowerId
          )
      );
      deletedFollowers = deletedFollowers.map((user: any) => {
        return { ...user, isDeleted: true };
      });
      let mergedFollowers = dataFromApi.cabinetFollowers.map(
        (user: any) => {
          let deleteduser = deletedFollowers.find(
            (deleted: any) =>
              deleted.cabinetFollowerId === user.cabinetFollowerId
          );
          if (deleteduser) {
            // Merge the user with the deleteduser and return
            return { ...user, isDeleted: true };
          } else {
            return user;
          }
        }
      );
      const updatedDataFromApi = {
        ...dataFromApi,
        cabinetFollowers: mergedFollowers,
      };
      setDataFromApi(updatedDataFromApi);
    }
  }, [cabinetData.cabinetFollowers.length]);

  useEffect(() => {
    if (dataFromApi.cabinetManagers) {
      let deletedManagers = dataFromApi.cabinetManagers.filter(
        (user: any) =>
          !cabinetData.cabinetManagers.some(
            (item: any) => item.value === user.cabinetManagerId
          )
      );
      deletedManagers = deletedManagers.map((user: any) => {
        return { ...user, isDeleted: true };
      });
      let mergedManagers = dataFromApi.cabinetManagers.map(
        (user: any) => {
          let deleteduser = deletedManagers.find(
            (deleted: any) =>
              deleted.cabinetManagerId === user.cabinetManagerId
          );
          if (deleteduser) {
            // Merge the user with the deleteduser and return
            return { ...user, isDeleted: true };
          } else {
            return user;
          }
        }
      );
      const updatedDataFromApi = {
        ...dataFromApi,
        cabinetManagers: mergedManagers,
      };
      setDataFromApi(updatedDataFromApi);
    }
  }, [cabinetData.cabinetManagers.length]);

  
  useEffect(() => {
    if (dataFromApi.cabinetDataEntryUsers) {
      let deletedDataEntryUsers = dataFromApi.cabinetDataEntryUsers.filter(
        (user: any) =>
          !cabinetData.cabinetDataEntryUsers.some(
            (item: any) => item.value === user.cabinetDataEntryUserId
          )
      );
      deletedDataEntryUsers = deletedDataEntryUsers.map((user: any) => {
        return { ...user, isDeleted: true };
      });
      let mergedDataEntryUsers = dataFromApi.cabinetDataEntryUsers.map(
        (user: any) => {
          let deleteduser = deletedDataEntryUsers.find(
            (deleted: any) =>
              deleted.cabinetDataEntryUserId === user.cabinetDataEntryUserId
          );
          if (deleteduser) {
            // Merge the user with the deleteduser and return
            return { ...user, isDeleted: true };
          } else {
            return user;
          }
        }
      );
      const updatedDataFromApi = {
        ...dataFromApi,
        cabinetDataEntryUsers: mergedDataEntryUsers,
      };
      setDataFromApi(updatedDataFromApi);
    }
  }, [cabinetData.cabinetDataEntryUsers.length]);


  const validateForm = () => {
    const newErrors: any = {};

    if (cabinetData.enName.length === 0)
      newErrors["enName"] = t("English name is required");
    if (cabinetData.arName.length === 0)
      newErrors["arName"] = t("Arabic name is required");
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = () => {
    if (validateForm()) {
      if (cabinetId) toUpdate();
      else {
        setIsSaving(true);
        const data = {
          ...cabinetData,
          members: cabinetData.cabinetMembers.map((member: any) => member.value ),
          managers: cabinetData.cabinetManagers.map((manager: any) => manager.value),
          dataEntryUsers: cabinetData.cabinetDataEntryUsers.map((dataEntryUser: any) => dataEntryUser.value),
          followers: cabinetData.cabinetFollowers.map((follower: any) => follower.value),
        };

        addCabinet(data)
          .then((res: any) => {
            setInfo(t("Cabinet added successfully"));
            handleClose(true);
          })
          .catch((err: any) => setError(err))
          .finally(() => setIsSaving(false));
      }
    }
  };

  const toUpdate = async () => {
    setIsSaving(true);
    let members = cabinetData.cabinetMembers.map((member: any) => {
      let memberData = {
        memberUserId: member.value,
        cabinetMemberId: 0,
      };
      let memberDataFromApi = dataFromApi.cabinetMembers.find(
        (memberFromApi: any) =>
          memberFromApi.cabinetMemberId === member.value &&
          memberFromApi.isDeleted !== true
      );
      if (memberDataFromApi) {
        memberData.cabinetMemberId =
          memberDataFromApi.cabinetMemberId;
      }
      return memberData;
    });
    let membersFromApi = dataFromApi.cabinetMembers.map(
      (member: any) => {
        const { user, ...rest } =
          member;
        return {
          ...rest,
        };
      }
    );
    members = [...members, ...membersFromApi];
    members = members.filter(
      (member: any, index: number, self: any) =>
        index ===
        self.findIndex(
          (t: any) =>
            t.cabinetMemberId === member.cabinetMemberId &&
            t.isDeleted === member.isDeleted
        )
    );

    let followers = cabinetData.cabinetFollowers.map((follower: any) => {
      let followerData = {
        followerUserId: follower.value,
        cabinetFollowerId: 0,
      };
      let followerDataFromApi = dataFromApi.cabinetFollowers.find(
        (followerFromApi: any) =>
          followerFromApi.cabinetFollowerId === follower.value &&
          followerFromApi.isDeleted !== true
      );
      if (follower) {
        followerData.cabinetFollowerId =
          followerDataFromApi.cabinetFollowerId;
      }
      return followerData;
    });
    let followersFromApi = dataFromApi.cabinetFollowers.map(
      (follower: any) => {
        const { user, ...rest } =
          follower;
        return {
          ...rest,
        };
      }
    );
    followers = [...followers, ...followersFromApi];
    followers = followers.filter(
      (follower: any, index: number, self: any) =>
        index ===
        self.findIndex(
          (t: any) =>
            t.cabinetFollowerId === follower.cabinetFollowerId &&
            t.isDeleted === follower.isDeleted
        )
    );
    
    let managers = cabinetData.cabinetManagers.map((manager: any) => {
      let managerData = {
        managerUserId: manager.value,
        cabinetManagerId: 0,
      };
      let managersDataFromApi = dataFromApi.cabinetManagers.find(
        (managerFromApi: any) =>
          managerFromApi.cabinetManagerId === manager.value &&
          managerFromApi.isDeleted !== true
      );
      if (managersDataFromApi) {
        managerData.cabinetManagerId =
          managersDataFromApi.cabinetManagerId;
      }
      return managerData;
    });
    let managersFromApi = dataFromApi.cabinetManagers.map(
      (manager: any) => {
        const { user, ...rest } =
          manager;
        return {
          ...rest,
        };
      }
    );
    managers = [...managers, ...managersFromApi];
    managers = managers.filter(
      (manager: any, index: number, self: any) =>
        index ===
        self.findIndex(
          (t: any) =>
            t.cabinetManagerId === manager.cabinetManagerId &&
            t.isDeleted === manager.isDeleted
        )
    );

    let users = cabinetData.cabinetDataEntryUsers.map((user: any) => {
      let userData = {
        dataEntryUserId: user.value,
        cabinetDataEntryUserId: 0,
      };
      let userDataFromApi = dataFromApi.cabinetDataEntryUsers.find(
        (userFromApi: any) =>
          userFromApi.cabinetDataEntryUserId === user.value &&
          userFromApi.isDeleted !== true
      );
      if (userDataFromApi) {
        userData.cabinetDataEntryUserId =
          userDataFromApi.cabinetDataEntryUserId;
      }
      return userData;
    });
    let usersFromApi = dataFromApi.cabinetDataEntryUsers.map(
      (member: any) => {
        const { user, ...rest } =
          member;
        return {
          ...rest,
        };
      }
    );
    users = [...users, ...usersFromApi];
    users = users.filter(
      (user: any, index: number, self: any) =>
        index ===
        self.findIndex(
          (t: any) =>
            t.cabinetDataEntryUserId === user.cabinetDataEntryUserId &&
            t.isDeleted === user.isDeleted
        )
    );

    const data = {
      ...cabinetData,
      cabinetMembers: members,
      cabinetFollowers: followers,
      cabinetManagers: managers,
      cabinetDataEntryUsers: users
    }; 

    updateCabinet(data)
        .then((res: any) => {
          setInfo(t("Cabinet updated successfully"));
          handleClose(true);
        })
        .catch((err: any) => setError(err))
        .finally(() => setIsSaving(false));
  };

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsChanged(true);
    setCabinetData({
      ...cabinetData,
      [name]: type == "checkbox" ? checked : value,
    });
  };

  return (
    <AddEditModalDX
      open={open}
      handleClose={handleClose}
      isSaving={isSaving}
      isChanged={isChanged}
      title={cabinetId ? t("Edit Cabinet") : t("Create a new Cabinet")}
      maxWidth="sm"
      onSaveClick={onSave}
      onYesClick={handleClose}
    >
      {isLoading && (
        <Loading styles={{ height: "100%", width: "100%", left: 0 }} />
      )}
      <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
        <GridDX item xs={12}>
          <TextFieldDX
            label={t("English Name")}
            name="enName"
            value={cabinetData.enName}
            onChange={handleInputChange}
            error={errors["enName"]}
          />
        </GridDX>
        <GridDX item xs={12}>
          <TextFieldDX
            label={t("Arabic Name")}
            name="arName"
            value={cabinetData.arName}
            onChange={handleInputChange}
            error={errors["arName"]}
          />
        </GridDX>

        {/* <GridDX item xs={12}>
          <SelectListDX
            label={`${t("Default Code")}`}
            items={codeList}
            name="defaultCode"
            value={cabinetData.defaultCode}
            onChange={handleInputChange}
            error={errors["defaultCode"]}
          />
        </GridDX>

        <GridDX item xs={12}>
          <CheckBoxDX
            name="activateStructureBasedCode"
            label={t("Activate Structure-Based Codes for Documents")}
            checked={cabinetData.activateStructureBasedCode}
            onChange={handleInputChange}
          />
        </GridDX>

        {cabinetData.activateStructureBasedCode && (
          <GridDX item xs={12}>
            <SelectListDX
              label={`${t("Structure-Based Code")}`}
              items={codeList}
              name="structureBasedCode"
              value={cabinetData.structureBasedCode}
              onChange={handleInputChange}
              error={errors["structureBasedCode"]}
            />
          </GridDX>
        )} */}

        <GridDX item xs={12}>
          <AutoCompleteMultiple
            sx={{ width: "100%" }}
            label={t("Members")}
            name="members"
            placeholder={t("Member")}
            value={cabinetData.cabinetMembers}
            onChange={(newValue: any) => {
              setIsChanged(true);
              setCabinetData({
                ...cabinetData,
                cabinetMembers: newValue,
              });
            }}
            error={errors["members"]}
            list={userList}
          />
        </GridDX>
        <GridDX item xs={12}>
          <AutoCompleteMultiple
            sx={{ width: "100%" }}
            label={t("Managers")}
            name="managers"
            placeholder={t("Manager")}
            value={cabinetData.cabinetManagers}
            onChange={(newValue: any) => {
              setIsChanged(true);
              setCabinetData({
                ...cabinetData,
                cabinetManagers: newValue,
              });
            }}
            error={errors["managers"]}
            list={userList}
          />
        </GridDX>
        <GridDX item xs={12}>
          <AutoCompleteMultiple
            sx={{ width: "100%" }}
            label={t("Data Entry Users")}
            name="dataEntryUsers  "
            placeholder={t("Data Entry User")}
            value={cabinetData.cabinetDataEntryUsers}
            onChange={(newValue: any) => {
              setIsChanged(true);
              setCabinetData({
                ...cabinetData,
                cabinetDataEntryUsers: newValue,
              });
            }}
            error={errors["dataEntryUsers "]}
            list={userList}
          />
        </GridDX>
        <GridDX item xs={12}>
          <AutoCompleteMultiple
            sx={{ width: "100%" }}
            label={t("Followers")}
            name="followers"
            placeholder={t("Follower")}
            value={cabinetData.cabinetFollowers}
            onChange={(newValue: any) => {
              setIsChanged(true);
              setCabinetData({
                ...cabinetData,
                cabinetFollowers: newValue,
              });
            }}
            error={errors["followers"]}
            list={userList}
          />
        </GridDX>
        {/* <GridDX item xs={12}>
          <CheckBoxDX
            name="lockFolderStructure"
            label={t("Lock Folder Structure.")}
            checked={cabinetData.lockFolderStructure}
            onChange={handleInputChange}
          />
        </GridDX> */}
      </GridDX>
    </AddEditModalDX>
  );
};

export default CabinetModal;
