import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GridColDef } from "@mui/x-data-grid";

import ListPageDX from "../components/business/listpagedx";
import GroupsModal from "../components/editmodals/usergroupmodal";
import useUserGroupService from "../shared/services/usergroupservice";
import { DateSorter, DateTimeFormatter } from "../shared/globals";
import { useNotificationContext } from "../context/notificationcontext";
import { useAuthContext } from "../context/authcontext";

const UserGroups = () => {
  const { t, i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";
  const { setInfo, setError } = useNotificationContext();
  const { userData } = useAuthContext();
  const { getUserGroups, deleteUserGroup } = useUserGroupService();

  const [groupId, setGroupId] = useState<any>(null);
  const [rows, setRows] = useState<any>([]);
  const [userGroups, setUserGroups] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const { calendarId, gregorianDateFormatId, hijriDateFormatId } = userData;

  const columns: GridColDef[] = [
    {
      field: languageIsEn ? "enName" : "arName",
      headerName: `${t("Name")}`,
      flex: 1,
    },
    {
      field: "description",
      headerName: `${t("Description")}`,
      flex: 1,
    },
    {
      field: "createdByUser",
      headerName: `${t("Created By")}`,
      flex: 1,
      valueGetter: (params: any) => {
        return (
          params.row.createdByUser &&
          (languageIsEn
            ? params.row.createdByUser.enFullName
            : params.row.createdByUser.arFullName)
        );
      },
    },
    {
      field: "createdDate",
      headerName: `${t("Created at")}`,
      flex: 1,
      sortComparator: DateSorter,
    },
    {
      field: "modifiedDate",
      headerName: `${t("Last modified at")}`,
      flex: 1,
      sortComparator: DateSorter,
    },
  ];

  const getData = () => {
    setIsLoading(true);
    getUserGroups() // api to fetch data types from the server
      .then((response) => {
        const preprocessedData = response.map((userGroup: any) => {
          return {
            ...userGroup,
            createdDate: DateTimeFormatter(
              userGroup.createdDate,
              calendarId,
              gregorianDateFormatId,
              hijriDateFormatId
            ),
            modifiedDate: DateTimeFormatter(
              userGroup.modifiedDate,
              calendarId,
              gregorianDateFormatId,
              hijriDateFormatId
            ),
          };
        });
        setUserGroups(preprocessedData);
        setRows(preprocessedData);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onDelete = (id: number) => {
    setIsLoading(true);
    deleteUserGroup(id)
      .then(() => {
        setInfo(t("Group deleted successfully"));
        getData();
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onEdit = (group: any) => {
    setGroupId(group.id);
    setOpen(!open);
  };

  const toCreate = () => {
    setOpen(!open);
  };

  const onClose = (refreshPage: boolean = false) => {
    setGroupId(null);
    setOpen(!open);

    if (refreshPage) getData();
  };

  useEffect(() => {
    getData();
  }, [i18n.language]);
  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(userGroups);
      return;
    }
    const lowercasedValue = value.toLowerCase();
    const newRows = userGroups.filter(
      ({ enName, arName, description, createdByUser }: any) =>
        languageIsEn
          ? enName?.toLowerCase().includes(lowercasedValue) ||
            description?.toLowerCase().includes(lowercasedValue) ||
            createdByUser?.enFullName?.toLowerCase().includes(lowercasedValue)
          : arName?.includes(value) ||
            description?.includes(value) ||
            createdByUser?.arFullName?.includes(value)
    );
    setRows(newRows);
  };
  return (
    <>
      {open && (
        <GroupsModal open={open} handleClose={onClose} groupId={groupId} />
      )}
      <ListPageDX
        listTitle={t("User Groups")}
        name={t("User Groups")}
        rows={rows}
        columns={columns}
        getRowId={(row: any) => row.id}
        isLoading={isLoading}
        toCreate={toCreate}
        onEdit={onEdit}
        onDelete={onDelete}
        setGridFilterCriteria={setGridFilterCriteria}
      />
    </>
  );
};

export default UserGroups;
