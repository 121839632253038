import { Avatar, Typography, Button, IconButton } from "@mui/material";
import { t } from "i18next";
import BoxDX from "../layout/boxdx";
import GridDX from "../layout/griddx";
import { useRef } from "react";
import { toBase64 } from "../../shared/globals";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import Resizer from "react-image-file-resizer";
import CloseIcon from '@mui/icons-material/Close';

const UploadBoxDX = (props: any) => {
  const inputRef = useRef<HTMLInputElement>(null);
  
  const resizeFile = (file: Blob) => new Promise(resolve => {
    Resizer.imageFileResizer(file, 100, 100, 'PNG', 100, 0,
    uri => {
      resolve(uri);
    }, 'base64', 100, 100);
  });

  const handleClick = (event: any) => {
    if (inputRef.current != null) {
      inputRef.current.click();
    }
  };

  const handleDelete = async () => {
    props.setImage("");
  };

  const handleDragOver = (event: any) => {
    event.preventDefault();
  };

  const handleDrop = async (event: any) => {
    event.preventDefault();
    const image = await toBase64(event.dataTransfer.files[0]);
    props.setImage(image);
  };

  const onChangeHandler = async (event: any) => {
    // const image = await toBase64(event.target.files[0]);
    const image = await resizeFile(event.target.files[0]);
    props.setImage(image);
  };

  return (
    <>
    <BoxDX
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      component="fieldset"
      sx={{ ml: 1, mr:0, border: "1px solid #c6c6c6", borderRadius: 1}}
    >
      <legend style={{color: "#808080", fontSize: 14 }}>{props.label}</legend>
      <GridDX item xs={12} justifyContent="center" direction="column">
        {props.image ? (
          <GridDX item xs={12} justifyContent="center">
            <Avatar
              variant="square"
              style={{
                height: "80px",
                width: "100%",
                display: "flex",
                fontSize: "25px",
              }}
              src={props.image}
            />
          </GridDX>
        ) : (
          <GridDX item xs={12} justifyContent="center">
            <Avatar
              variant="square"
              style={{
                display: "flex",
                fontSize: "25px",
              }}
            >
              <AddPhotoAlternateIcon />
            </Avatar>
          </GridDX>
        )}
        <GridDX item xs={12} justifyContent="center" direction="column">
          <Typography
            sx={{
              fontSize: 16,
              textAlign: "center",
            }}
          >
            {t("Drag and Drop Image here or")}
          </Typography>
          <Button variant="text" onClick={handleClick}>
            {t("Browse")}
          </Button>
        </GridDX>
      </GridDX>
      <GridDX item xs={12} justifyContent="center">
        <input
          ref={inputRef}
          accept="image/*"
          type="file"
          name="file"
          style={{
            display: "none",
          }}
          onChange={onChangeHandler}
        />
      </GridDX>
    </BoxDX>
    <BoxDX>
      <IconButton onClick={handleDelete}>
        <CloseIcon/>
      </IconButton>
    </BoxDX>
    </>
  );
};

export default UploadBoxDX;