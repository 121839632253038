import { useEffect, useState } from "react";
import AddEditModalDX from "../../business/addeditmodaldx";
import { useTranslation } from "react-i18next";
import TextFieldDX from "../../controls/textfielddx";
import GridDX from "../../layout/griddx";
import { useNotificationContext } from "../../../context/notificationcontext";
import Loading from "../../loading";
import useCorrespondenceReminderService from "../../../shared/services/correspondenceremidersservice";
import DateTimePickerDX from "../../controls/datetimepickerdx";

import moment, { Moment } from "moment";
import momentHijri from "moment-hijri";
import { API_DATETIME_FORMAT } from "../../../shared/globals";
import { useAuthContext } from "../../../context/authcontext";
const CorrespondenceRemindersModal = (props: any) => {
  const { open, handleClose, correspondenceId, correspondenceReminderId } =
    props;

  const { userData } = useAuthContext();
  const { calendarId } = userData;

  const { t } = useTranslation();
  const { setError, setInfo } = useNotificationContext();
  const {
    addCorrespondenceReminder,
    updateCorrespondenceReminder,
    getReminderByReminderID,
  } = useCorrespondenceReminderService();

  interface ReminderData {
    reminderId: number;
    correspondenceId: number;
    description: string;
    reminderDate: string | Moment | null;
  }

  const defaultValues: ReminderData = {
    reminderId: 0,
    correspondenceId: correspondenceId,
    description: "",
    reminderDate: null,
  };

  const [correspondenceReminderData, setCorrespondenceReminderData] =
    useState<ReminderData>(defaultValues);
  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    if (correspondenceReminderId) {
      setIsLoading(true);
      getReminderByReminderID(correspondenceReminderId)
        .then((res: any) => {
          setCorrespondenceReminderData({
            ...res,
            reminderDate: moment(res.reminderDate),
          });
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
  }, []);

  const validateForm = () => {
    const newErrors: any = {};

    if (correspondenceReminderData.description.length === 0)
      newErrors["description"] = t("Reminder description is required");
    if (!correspondenceReminderData.reminderDate)
      newErrors["reminderDate"] = t("Reminder date and time is required");

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = () => {
    if (validateForm()) {
      setIsLoading(true);
      const reminderDate = moment(
        correspondenceReminderData.reminderDate
      ).format(API_DATETIME_FORMAT);
      const operation = correspondenceReminderId
        ? updateCorrespondenceReminder(correspondenceReminderId, {
            ...correspondenceReminderData,
            reminderDate: reminderDate,
          })
        : addCorrespondenceReminder({
            ...correspondenceReminderData,
            reminderDate: reminderDate,
          });
      operation
        .then(() => {
          setInfo(
            t(
              correspondenceReminderId
                ? "Reminder updated successfully"
                : "Reminder created successfully"
            )
          );
          handleClose(true);
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsChanged(true);
    setCorrespondenceReminderData({
      ...correspondenceReminderData,
      [name]: type == "checkbox" ? checked : value,
    });
  };

  const handleDateTimeChange = (e: any) => {
    setIsChanged(true);
    setCorrespondenceReminderData({
      ...correspondenceReminderData,
      reminderDate: moment(e),
    });
  };

  return (
    <AddEditModalDX
      open={open}
      handleClose={handleClose}
      isSaving={isLoading}
      isChanged={isChanged}
      title={
        correspondenceReminderId
          ? t("Edit Correspondence Reminder")
          : t("Add Correspondence Reminder")
      }
      maxWidth="sm"
      onSaveClick={onSave}
      onYesClick={handleClose}
    >
      {isLoading && (
        <Loading styles={{ height: "100%", width: "100%", left: 0 }} />
      )}
      <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
        <GridDX
          item
          xs={12}
          sx={{
            "& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root": {
              width: "100% !important",
            },
            "& .css-1rutzcu-MuiFormControl-root-MuiTextField-root": {
              width: "100% !important",
            },
          }}
        >
          <DateTimePickerDX
            label={t("Reminder Date and Time")}
            name="reminderDate"
            value={correspondenceReminderData.reminderDate}
            onChange={handleDateTimeChange}
            error={errors["reminderDate"]}
            minDate={calendarId == 1 ? moment() : momentHijri()}
          />
        </GridDX>

        <GridDX item xs={12}>
          <TextFieldDX
            label={t("Reminder Description")}
            name="description"
            value={correspondenceReminderData.description}
            onChange={handleInputChange}
            error={errors["description"]}
          />
        </GridDX>
      </GridDX>
    </AddEditModalDX>
  );
};

export default CorrespondenceRemindersModal;
