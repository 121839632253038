import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const CheckBoxDX = (props: any) => {
  if (props.label)
    return (
      <FormControlLabel
        control={<Checkbox  {...props} />}
        sx={{ ...props.sx, marginRight: 0 }}
        label={props.label}
      />
    );
  else return <Checkbox {...props} />;
};

export default CheckBoxDX;
