import { Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { usePDF } from "react-to-pdf";
import { useNotificationContext } from "../../../../context/notificationcontext";
import { downloadPDF } from "../../../../shared/globals";
import ButtonDX from "../../../controls/buttondx";
import FilterRange from "../../../controls/filterrange";
import GridDX from "../../../layout/griddx";
import Loading from "../../../loading";
import useDMSReportService from "../../../../shared/services/dmsreportservice";
import ListPageDX from "../../../business/listpagedx";


const ItemsCreatedByCabinets = (props: any) => {
  const { filterFromDate, filterToDate, isPdfGenerating, setIsPdfGenerating } =
    props;
  const { t, i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";

  const { setError } = useNotificationContext();
  const { getItemsCreatedByCabinets } = useDMSReportService();
  const [rows, setRows] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const columns: GridColDef[] = [
    {
      field: languageIsEn ? "cabinetEnName" : "cabinetArName",
      headerName: `${t("Cabinet Name")}`,
      flex: 1,
      renderCell(params) {
        if (params.row.cabinetEnName === t("Total")) return <strong>{t("Total")}</strong>
      },
    },

    {
      field: "numberOfDocuments",
      headerName: `${t("Documents Created")}`,
      flex: 1,
      renderCell(params) {
        if (params.row.cabinetEnName === t("Total")) return <strong>{params.row.numberOfDocuments}</strong>
      },
    },
    {
      field: "numberOfFolders",
      headerName: `${t("Folders Created")}`,
      flex: 1,
      renderCell(params) {
        if (params.row.cabinetEnName === t("Total")) return <strong>{params.row.numberOfFolders}</strong>
      },
    },

  ];

  const getData = (formattedFromDate: any, formattedToDate: any) => {
    setIsLoading(true);
    getItemsCreatedByCabinets(formattedFromDate, formattedToDate) //fetch user data from APi
      .then((res) => {
        let totalFolders = 0;
        let totalDocuments = 0;
        for (let item of res) {
          totalFolders += item.numberOfFolders;
          totalDocuments += item.numberOfDocuments;  
        }
        
        setRows([
          ...res,
          {
            cabinetId: res[res.length - 1].cabinetId + 1,
            cabinetEnName: t("Total"),
            cabinetArName: t("Total"),
            numberOfFolders: totalFolders,
            numberOfDocuments: totalDocuments
          }
        ]);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  // useEffect(() => getData(), [i18n.language, filterFromDate, filterToDate])

  const APIdateFormater = (date: any) => {
    const thisDate = new Date(date);
    const year = date != null && thisDate?.getFullYear();
    const month =
      date != null && String(thisDate.getMonth() + 1).padStart(2, "0");
    const day = date != null && String(thisDate.getDate()).padStart(2, "0");
    return `${year}${month}${day}`;
  };
  useEffect(() => {
    let formattedFromDate =
      filterFromDate && filterFromDate != null
        ? APIdateFormater(filterFromDate)
        : null;
    let formattedToDate =
      filterToDate && filterToDate != null
        ? APIdateFormater(filterToDate)
        : null;
    if (formattedFromDate && formattedToDate) {
      getData(formattedFromDate, formattedToDate);
    }
  }, [i18n.language, filterFromDate, filterToDate]);

  const { toPDF, targetRef } = usePDF({
    filename: t("Items Created By Cabinets"),
    page: {
      margin: 10, // Set the margin in millimeters
      format: "a4", // You can choose 'a4' or other formats
      orientation: "portrait", // 'portrait' or 'landscape'
    },
  });
  const handleDownloadReport = () => {
    setRowsPerPage(() => rows.length);
    setTimeout(() => {
      downloadPDF({ toPDF, setIsPdfGenerating, setRowsPerPage })
    }, 1000);
  };
  return (
    <div>
      {isPdfGenerating && (
        <Loading styles={{ height: "100%", width: "100%", left: 0 }} />
      )}
      <div ref={targetRef}>
        <GridDX
          container
          xs={12}
          sx={{ marginBottom: 20, marginTop: 5, py: 2 }}
        >
          <GridDX item xs={12} sx={{ my: 1 }}>
            <Typography
              variant="h5"
              component="div"
              sx={{ flexGrow: 1, fontWeight: "bold" }}
            >
              {t("Items Created By Cabinets")}
            </Typography>
            {!isPdfGenerating && (
              <ButtonDX
                onClick={handleDownloadReport}
              >
                {t("Download Report")}
              </ButtonDX>
            )}
            {isPdfGenerating && (
              <FilterRange
                filterFromDate={filterFromDate}
                filterToDate={filterToDate}
              />
            )}
          </GridDX>
          {/* <GridDX
            item
            md={6}
            xs={12}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <PieChartDx data={dataForPieChart} />
          </GridDX> */}
          <GridDX item xs={12}>
            <ListPageDX
              listTitle={t("Items Created By Cabinets")}
              rows={rows}
              columns={columns}
              getRowId={(row: any) => row.cabinetId}
              isLoading={isLoading}
              exportPDFLabel={t("Download Report")}
              exportToPDF={false}
              exportToCSV={false}
              searchAndFilter={false}
              rowsPerPage={rowsPerPage}
            />
          </GridDX>
        </GridDX>
      </div>
    </div>
  );
};

export default ItemsCreatedByCabinets;
