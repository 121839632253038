import useSecureAPI from "../hooks/usesecureapi";

const useReferenceTypeService = () => {
  const secureAPI = useSecureAPI();

  const getReferenceTypes = async () => {
    const response = await secureAPI.get("ReferenceType");
    return response.data;
  };

  const addReferenceType = async (data: any) => {
    const response = await secureAPI.post("ReferenceType", data);
    return response.data;
  };

  const updateReferenceType = async (id: number, data: any) => {
    const response = await secureAPI.put(`ReferenceType`, data);
    return response.data;
  };

  const deleteReferenceType = async (id: number) => {
    const response = await secureAPI.delete(`ReferenceType/${id}`);
    return response.data;
  };

  return {
    getReferenceTypes,
    addReferenceType,
    updateReferenceType,
    deleteReferenceType,
  };
};

export default useReferenceTypeService;
