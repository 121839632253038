import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import ListPageDX from "../../components/business/listpagedx";
import { useNotificationContext } from "../../context/notificationcontext";
import useRoutingGroupService from "../../shared/services/routinggroupservice";
import RoutingGroupModal from "../../components/editmodals/routinggroupmodal";
import { DateSorter, DateTimeFormatter } from "../../shared/globals";
import { useAuthContext } from "../../context/authcontext";

const RoutingGroup = () => {
  const { t, i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";
  const { setInfo, setError } = useNotificationContext();
  const { getRoutingGroups, deleteRoutingGroup } = useRoutingGroupService();
  const [routingGroupId, setRoutingGroupId] = useState<any>(null);
  const [rows, setRows] = useState<any>([]);
  const [routingGroups, setRoutingGroups] = useState<any>([]);

  const { userData } = useAuthContext();
  const { calendarId, gregorianDateFormatId, hijriDateFormatId } = userData;
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const columns: GridColDef[] = [
    {
      field: languageIsEn ? "enName" : "arName",
      headerName: `${t("Name")}`,
      flex: 1,
    },
    {
      field: "createdByUser",
      headerName: `${t("Created By")}`,
      flex: 1,
      valueGetter: (params: any) => {
        return (
          params.row.createdByUser &&
          (languageIsEn
            ? params.row.createdByUser.enFullName
            : params.row.createdByUser.arFullName)
        );
      },
    },
    {
      field: "createdDate",
      headerName: `${t("Created at")}`,
      flex: 1,
      sortComparator: DateSorter,
    },
  ];

  const getData = () => {
    setIsLoading(true);
    getRoutingGroups()
      .then((routingGroups) => {
        const preprocessedData = routingGroups.map((routingGroup: any) => {
          return {
            ...routingGroup,
            createdDate: DateTimeFormatter(
              routingGroup.createdDate,
              calendarId,
              gregorianDateFormatId,
              hijriDateFormatId
            ),
          };
        });

        setRoutingGroups(preprocessedData);
        setRows(preprocessedData);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onDelete = (id: number) => {
    setIsLoading(true);
    deleteRoutingGroup(id)
      .then(() => {
        setInfo(t("Routing Group deleted successfully"));
        getData();
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onEdit = (routingGroup: any) => {
    setRoutingGroupId(routingGroup.routingGroupId);
    setOpen(!open);
  };

  const toCreate = () => {
    setOpen(!open);
  };

  const onClose = (refreshPage: boolean = false) => {
    setRoutingGroupId(null);
    setOpen(!open);

    if (refreshPage) getData();
  };

  useEffect(() => {
    getData();
  }, [i18n.language]);

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(routingGroups);
      return;
    }
    const lowercasedValue = value.toLowerCase();
    const newRows = routingGroups.filter(
      ({ enName, arName, createdByUser }: any) =>
        languageIsEn
          ? enName?.toLowerCase().includes(lowercasedValue) ||
            createdByUser?.enFullName?.toLowerCase().includes(lowercasedValue)
          : arName?.includes(value) ||
            createdByUser?.arFullName?.includes(value)
    );
    setRows(newRows);
  };

  return (
    <>
      {open && (
        <RoutingGroupModal
          open={open}
          handleClose={onClose}
          routingGroupId={routingGroupId}
        />
      )}
      <ListPageDX
        listTitle={t("Routing Group")}
        name={t("Routing Group")}
        rows={rows}
        columns={columns}
        getRowId={(row: any) => row.routingGroupId}
        isLoading={isLoading}
        toCreate={toCreate}
        onEdit={onEdit}
        onDelete={onDelete}
        setGridFilterCriteria={setGridFilterCriteria}
      />
    </>
  );
};

export default RoutingGroup;
