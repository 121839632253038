import useSecureAPI from "../hooks/usesecureapi";

const useDocumentReminderService = () => {
  const secureAPI = useSecureAPI();

  const getRemindersByDocumentID = async (id: number) => {
    const response = await secureAPI.get(`dms/DocumentReminder/fordocument/${id}`);
    return response.data;
  };
  
  const addDocumentReminder = async (data: any) => {
    const response = await secureAPI.post("dms/DocumentReminder", data);
    return response.data;
  };

  const updateDocumentReminder = async (data: any) => {
    const response = await secureAPI.put(`dms/DocumentReminder`, data);
    return response.data;
  };

  const getReminderByReminderID = async (id: number) => {
    const response = await secureAPI.get(`dms/DocumentReminder/${id}`);
    return response.data;
  };

  const deleteDocumentReminder = async (id: number) => {
    const response = await secureAPI.delete(`dms/DocumentReminder/${id}`);
    return response.data;
  };

  return {
   getRemindersByDocumentID,
   addDocumentReminder,
   deleteDocumentReminder,
   updateDocumentReminder,
   getReminderByReminderID
  };
};

export default useDocumentReminderService;
