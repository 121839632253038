import useSecureAPI from "../hooks/usesecureapi";

const useFolderCommentService = () => {
  const secureAPI = useSecureAPI();

  const getFolderComments = async (id: number) => {
    const response = await secureAPI.get(`dms/FolderComment/treeforfolder/${id}`);
    return response.data;
  };
  
  const getFolderCommentByID = async (id: number) => {
    const response = await secureAPI.get(`dms/FolderComment/${id}`);
    return response.data;
  };

  const addFolderComment = async (data: any) => {
    const response = await secureAPI.post(`dms/FolderComment`, data);
    return response.data; 
  };

  const updateFolderComment = async (data: any) => {
    const response = await secureAPI.put(`dms/FolderComment`, data);
    return response.data;
  };

  const deleteFolderComment = async (id: number) => {
    const response = await secureAPI.delete(`dms/FolderComment/${id}`);
    return response.data;
  };

  return {
    getFolderComments,
    getFolderCommentByID,
    addFolderComment,
    updateFolderComment,
    deleteFolderComment,
  };
};

export default useFolderCommentService;