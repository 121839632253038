import useSecureAPI from "../hooks/usesecureapi";

const useDocumentProfileService = () => {
  const secureAPI = useSecureAPI();

  const getDocumentProfiles = async () => {
    const response = await secureAPI.get(`dms/DocumentProfile`);
    return response.data;
  };
  
  const getDocumentProfileByID = async (id: number) => {
    const response = await secureAPI.get(`dms/DocumentProfile/${id}`);
    return response.data;
  };

  const addDocumentProfile = async (data: any) => {
    const response = await secureAPI.post(`dms/DocumentProfile`, data);
    return response.data; 
  };

  const updateDocumentProfile = async (data: any) => {
    const response = await secureAPI.put(`dms/DocumentProfile`, data);
    return response.data;
  };

  const deleteDocumentProfile = async (id: number) => {
    const response = await secureAPI.delete(`dms/DocumentProfile/${id}`);
    return response.data;
  };

  return {
    getDocumentProfiles,
    getDocumentProfileByID,
    addDocumentProfile,
    updateDocumentProfile,
    deleteDocumentProfile,
  };
};

export default useDocumentProfileService;