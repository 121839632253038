import useSecureAPI from "../hooks/usesecureapi";

const useDMSReportService = () => {
  const secureAPI = useSecureAPI();

  const getDMSStatistics = async (startDate: string, endDate: string) => {
    const response = await secureAPI.get(
      `dms/report/statistics/${startDate}/${endDate}`
    );
    return response.data;
  };

  const getItemsCreatedByUser = async (startDate: string, endDate: string) => {
    const response = await secureAPI.get(
      `dms/report/itemscreatedcountbyuser/${startDate}/${endDate}`
    );
    return response.data;
  };

  const getItemsCreatedByCabinets = async (
    startDate: string,
    endDate: string
  ) => {
    const response = await secureAPI.get(
      `dms/report/itemscreatedcountbycabinet/${startDate}/${endDate}`
    );
    return response.data;
  };
  const getDocumentByFileExtension = async (
    startDate: string,
    endDate: string
  ) => {
    const response = await secureAPI.get(
      `dms/report/documentcountbyfileextension/${startDate}/${endDate}`
    );
    return response.data;
  };
  return {
    getDMSStatistics,
    getItemsCreatedByUser,
    getItemsCreatedByCabinets,
    getDocumentByFileExtension,
  };
};

export default useDMSReportService;
