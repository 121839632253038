import { useEffect, useState } from "react";
import AddEditModalDX from "../../business/addeditmodaldx";
import { useTranslation } from "react-i18next";
import TextFieldDX from "../../controls/textfielddx";
import GridDX from "../../layout/griddx";
import Loading from "../../loading";

const StructureModal = (props: any) => {
  const {
    open,
    handleClose,
    dataForTree,
    setDataForTree,
    currentNode,
    editNode,
  } = props;
  const { t } = useTranslation();
  const defaultValues = {
    enName: "",
    arName: "",
  };

  const [nodeData, setNodeData] = useState<any>(defaultValues);
  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const [errors, setErrors] = useState<any>({});

  const validateForm = () => {
    const newErrors: any = {};

    if (nodeData.enName.length === 0)
      newErrors["enName"] = t("English name is required");
    if (nodeData.arName.length === 0)
      newErrors["arName"] = t("Arabic name is required");

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  // const findNodeById = (node: any, id: any) => {
  //   if (node.id === id) {
  //     return node;
  //   }
  //   if (node.children && node.children.length > 0) {
  //     for (let child of node.children) {
  //       const found: any = findNodeById(child, id);
  //       if (found) {
  //         return found;
  //       }
  //     }
  //   }
  //   return null;
  // };
  const onSave = () => {
    if (validateForm()) {
      if (editNode) {
        setDataForTree((prev: any) => {
          const newTree = prev;
          const findAndEditNode = (nodes: any) => {
            for (let node of nodes) {
              if (node.id === editNode.id) {
                node.enName = nodeData.enName;
                node.arName = nodeData.arName;
                return true;
              } else if (node.children && node.children.length > 0) {
                if (findAndEditNode(node.children)) return true;
              }
            }
            return false;
          };

          findAndEditNode(newTree);

          return newTree;
        });
        handleClose();
      } else {
        if (dataForTree.length === 0) {
          setDataForTree([
            {
              id: Math.floor(Math.random() * Date.now()).toString(36),
              level: 1,
              parentId: null,
              enName: nodeData.enName,
              arName: nodeData.arName,
              children: [],
            },
          ]);
          handleClose();
        } else {
          setDataForTree((prev: any) => {
            // Deep clone the previous state tree
            const newTree = JSON.parse(JSON.stringify(prev));

            // Find the current node in the cloned tree
            const findAndAddNode = (nodes: any) => {
              for (let node of nodes) {
                if (node.id === currentNode.id) {
                  // Add the new node as a child of the current node
                  node.children.push({
                    id: Math.floor(Math.random() * Date.now()).toString(36),
                    level: currentNode.level + 1,
                    parentId: currentNode.id,
                    enName: nodeData.enName,
                    arName: nodeData.arName,
                    children: [],
                  });
                  return true;
                } else if (node.children && node.children.length > 0) {
                  if (findAndAddNode(node.children)) return true;
                }
              }
              return false;
            };

            findAndAddNode(newTree);

            return newTree;
          });
          handleClose();
        }
      }
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsChanged(true);
    setNodeData({
      ...nodeData,
      [name]: type == "checkbox" ? checked : value,
    });
  };

  useEffect(() => {
    if (editNode) {
      setNodeData(editNode);
    }
  }, []);

  return (
    <AddEditModalDX
      open={open}
      handleClose={handleClose}
      isSaving={isLoading}
      isChanged={isChanged}
      title={editNode ? t("Update") : t("Create")}
      maxWidth="sm"
      onSaveClick={onSave}
      onYesClick={handleClose}
    >
      {isLoading && (
        <Loading styles={{ height: "100%", width: "100%", left: 0 }} />
      )}
      <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
        <GridDX item xs={12}>
          <TextFieldDX
            label={t("English Name")}
            name="enName"
            value={nodeData.enName}
            onChange={handleInputChange}
            error={errors["enName"]}
          />
        </GridDX>
        <GridDX item xs={12}>
          <TextFieldDX
            label={t("Arabic Name")}
            name="arName"
            value={nodeData.arName}
            onChange={handleInputChange}
            error={errors["arName"]}
          />
        </GridDX>
      </GridDX>
    </AddEditModalDX>
  );
};

export default StructureModal;
