import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useTranslation } from "react-i18next";
import DWT from "../../DynamsoftSDK";
import Dynamsoft from "dwt";
import ButtonDX from "../controls/buttondx";

const ScanModal = (props: any) => {
  const { t, i18n } = useTranslation();
  
  const getFile = (file: any) => {
    props.setFile(file);
    toClose();
  };

  const toClose = () => {
    Dynamsoft.DWT.DeleteDWTObject("dwtcontrolContainer");
    props.onClose();
  };

  return (
  <Dialog
    open={props.open}
    onClose={toClose}
    fullWidth
    maxWidth={"lg"}
    disableEscapeKeyDown
    sx={{ direction: i18n.dir() }}
  >
    <DialogTitle sx={{ textAlign: "center" }}> {t("Scanner")} </DialogTitle>
    <DialogContent>
      <DWT
        features={[
          "scan"
        ]}
        onUpload={getFile}
      />
    </DialogContent>
    <DialogActions sx={{ flexWrap: "wrap", mx: 3 }}>
      <ButtonDX
        variant="outlined"
        onClick={toClose}
        sx={{ mb: { xs: 2, sm: "auto" } }}
      >
        {t("Discard")}
      </ButtonDX>
    </DialogActions>
  </Dialog>
  );
};

export default ScanModal;