import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import ListPageDX from "../../../components/business/listpagedx";
import { useNotificationContext } from "../../../context/notificationcontext";
import useImportanceLevelsService from "../../../shared/services/importancelevelsservice";
import ImportanceLevelsModal from "../../../components/editmodals/importancelevelsmodal";

const ImportanceLevels = () => {
  const { t, i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";
  const { setInfo, setError } = useNotificationContext();
  const { getImportanceLevels, deleteImportanceLevel } =
    useImportanceLevelsService();
  const [importanceLevelId, setImportanceLevelId] = useState<any>(null);
  const [rows, setRows] = useState<any>([]);
  const [importanceLevels, setImportanceLevels] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    getData();
  }, []);

  const columns: GridColDef[] = [
    {
      field: languageIsEn ? "enName" : "arName",
      headerName: `${t("Level Type")}`,
      flex: 1,
    },
    {
      field: "priority",
      headerName: `${t("Priority")}`,
      flex: 1,
    },
    {
      field: "createdByUser",
      headerName: `${t("Created By")}`,
      flex: 1,

      valueGetter: (params: any) => {
        return (
          params.row.createdByUser &&
          (languageIsEn
            ? params.row.createdByUser.enFullName
            : params.row.createdByUser.arFullName)
        );
      },
    },
  ];

  const getData = () => {
    setIsLoading(true);
    getImportanceLevels() // api to fetch data types from the server
      .then((response) => {
        setImportanceLevels(response);
        setRows(response);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onDelete = (id: number) => {
    setIsLoading(true);
    deleteImportanceLevel(id)
      .then(() => {
        setInfo(t("Importance level deleted successfully"));
        getData();
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onEdit = (importanceLevel: any) => {
    setImportanceLevelId(importanceLevel.importanceLevelId);
    setOpen(!open);
  };

  const toCreate = () => {
    setOpen(!open);
  };

  const onClose = (refreshPage: boolean = false) => {
    setImportanceLevelId(null);
    setOpen(!open);

    if (refreshPage) getData();
  };
  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(importanceLevels);
      return;
    }
    const lowercasedValue = value.toLowerCase();
    const newRows = importanceLevels.filter(
      ({ enName, arName, priority, createdByUser }: any) =>
        languageIsEn
          ? enName?.toLowerCase().includes(lowercasedValue) ||
            priority?.toString().includes(value) ||
            createdByUser?.enFullName?.toLowerCase().includes(lowercasedValue)
          : arName?.includes(value) ||
            priority?.toString().includes(value) ||
            createdByUser?.arFullName?.includes(value)
    );
    setRows(newRows);
  };
  return (
    <>
      {open && (
        <ImportanceLevelsModal
          open={open}
          handleClose={onClose}
          importanceLevelId={importanceLevelId}
        />
      )}
      <ListPageDX
        listTitle={t("Importance Levels")}
        name={t("Importance Level")}
        rows={rows}
        columns={columns}
        getRowId={(row: any) => row.importanceLevelId}
        isLoading={isLoading}
        toCreate={toCreate}
        onEdit={onEdit}
        onDelete={onDelete}
        setGridFilterCriteria={setGridFilterCriteria}
      />
    </>
  );
};

export default ImportanceLevels;
