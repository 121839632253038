import { TextField } from "@mui/material/";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

const TextFieldDX = (props: any) => {
  const { i18n } = useTranslation();

  const [direction, setDirection] = useState(i18n.dir());
  const helperText = props.helperText;
  const controlType = props.type ?? "text";
  const allowCopyPaste = props.allowCopyPaste ?? "true";
  const isError = props.error ? true : false;
  const loading = props.loading ?? false;

  useEffect(()=>{
    if (props.value?.length >= 1) setDirection(getDirection(props.value[0]));
    else setDirection(i18n.dir());
  }, [i18n.language, props.value])

  const getDirection = (text: any) => {
    if (text.length >= 1) {
      var x = new RegExp("[\x00-\x80]+"); // is ascii
      var isAscii = x.test(text);
   
      if (isAscii) {
        return("ltr")
      }
      return("rtl");
    }
    else return(i18n.dir());
  }

  const handleCopyPaste = (e: any) => {
    e.preventDefault();
  };

  if (loading)
    return (
      <Skeleton
        containerClassName="skeleton-container"
        style={{ height: 56 }}
      />
    );
  else if (props.readOnly)
    return (
      <TextField
        {...props}
        variant="standard"
        dir={direction}
        fullWidth
        helperText={helperText}
        InputProps={{
          readOnly: true
        }}
        InputLabelProps={{ shrink: true }}
        sx={
          i18n.dir() === "rtl"
            ? {
                ...props.sx,
                "& label": {
                  left: "unset",
                  right: "0rem",
                  transformOrigin: "right",
                },
                "& legend": {
                  textAlign: "right",
                },
                "& .MuiFormHelperText-root": {
                  textAlign: "right",   
                }
              }
            : { ...props.sx }
        }
      />
    );
  else
    return (
      <TextField
        fullWidth
        variant="outlined"
        error={isError}
        helperText={props.error ?? helperText}
        dir={direction}
        {...props}
        value={props.value ?? ""}
        onCut={allowCopyPaste ? null : handleCopyPaste}
        onCopy={allowCopyPaste ? null : handleCopyPaste}
        onPaste={allowCopyPaste ? null : handleCopyPaste}
        onWheel={(e: any) => e.target?.blur()}
        onKeyDown={(e:any) => {
          if (props?.type === "number" && (e.which === 38 || e.which === 40)) {
            e.preventDefault();
          }
        }}
        sx={
          i18n.dir() === "rtl"
            ? {
                ...props.sx,
                "& label": {
                  left: "unset",
                  right: "1.75rem",
                  transformOrigin: "right",
                },
                "& legend": {
                  textAlign: "right",
                },
                "& .MuiFormHelperText-root": {
                  textAlign: "right",   
                }
              }
            : { ...props.sx }
        }
      />
    );
};

export default TextFieldDX;
