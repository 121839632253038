import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GridColDef } from "@mui/x-data-grid";
import GridDX from "../../layout/griddx";
import { useNotificationContext } from "../../../context/notificationcontext";
import { useAuthContext } from "../../../context/authcontext";
import useInboxService from "../../../shared/services/inboxservice";
import ListPageDX from "../../business/listpagedx";

import { DateSorter, DateTimeFormatter } from "../../../shared/globals";

const Inbox = () => {
  const { t, i18n } = useTranslation();

  const { userData } = useAuthContext();
  const { userId, calendarId, gregorianDateFormatId, hijriDateFormatId } =
    userData;
  const [isLoading, setIsLoading] = useState(false);
  const [inboxData, setInboxData] = useState<any>([]);
  const [rows, setRows] = useState<any>([]);
  const { setError, setInfo } = useNotificationContext();
  const {
    getInboxByUser,
    markAsRead,
    markAsUnread,
    markAsArchived,
    deleteInbox,
  } = useInboxService();

  const navigate = useNavigate();

  const getInboxData = async () => {
    setIsLoading(true);

    getInboxByUser(userId)
      .then((res: any) => {
        const formattedRows = res.map((item: any) => {
          // Check if openedDatetime is null, and set message as "New" if it is.
          const isNew = item.openedDatetime === null;

          const actionTypeMessages: { [key: number]: string } = {
            1: isNew
              ? t("New Correspondence request For Information")
              : t("Correspondence request For Information"),
            2: isNew
              ? t("New Correspondence request For Approval")
              : t("Correspondence request For Approval"),
          };
          const message = actionTypeMessages[item.requiredActionTypeId] || "";
          return {
            ...item,
            message,
          };
        });

        setRows(
          formattedRows
            .filter((item: any) => item.archivedDate === null)
            .reverse()
        );
        setInboxData(formattedRows.reverse());
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getInboxData();
  }, [i18n.language]);

  const toPreview = (data: any) => {
    markAsRead([data.inboxId]);
    navigate(`/correspondencedetails/${data.correspondence.correspondenceId}`);
  };

  const handleMarkAsRead = (ids: any) => {
    // Implement marking selected messages as read
    markAsRead(ids)
      .then((res: any) => {
        setInfo(t("Marked as Read Successfully"));
        getInboxData();
      })
      .catch((err) => setError(err));
  };

  const handleMarkAsUnRead = (ids: any) => {
    // Implement marking selected messages as read

    markAsUnread(ids)
      .then((res: any) => {
        setInfo(t("Marked as Unread Successfully"));
        getInboxData();
      })
      .catch((err) => setError(err));
  };

  const handleDeleteSelected = (ids: any) => {
    // Implement deleting selected messages
    deleteInbox(ids)
      .then((res: any) => {
        setInfo(t("Deleted Successfully"));
        getInboxData();
      })
      .catch((err) => setError(err));
  };

  const handleArchive = (ids: any) => {
    // Implement archiving selected messages
    markAsArchived(ids)
      .then((res: any) => {
        setInfo(t("Archived Successfully"));
        getInboxData();
      })
      .catch((err) => setError(err));
  };

  const filterRead = (props: any) => {
    setRows(
      inboxData.filter(
        (item: any) => item.openedDatetime !== null && item.archivedDate == null
      )
    );
  };

  const filterUnRead = (props: any) => {
    setRows(
      inboxData.filter(
        (item: any) => item.openedDatetime == null && item.archivedDate == null
      )
    );
  };

  const filterArchived = (props: any) => {
    setRows(inboxData.filter((item: any) => item.archivedDate !== null));
  };

  const resetFilter = (props: any) => {
    setRows(inboxData.filter((item: any) => item.archivedDate == null));
  };

  const columns: GridColDef[] = [
    {
      field: "correspondenceId",
      headerName: t("ID"),
      maxWidth: 20,
    },
    {
      field: "message",
      headerName: t("Message"),
      flex: 1,
      renderCell: (params) => {
        const Unread = !params.row.openedDatetime;
        return (
          <span style={{ fontWeight: Unread ? "bold" : "normal" }}>
            {params.row.message}
          </span>
        );
      },
    },
    {
      field: "from",
      headerName: t("From"),
      headerAlign: "center",
      align: "center",
      width: 250,
      valueGetter(params) {
        return (i18n.language === "en" ? 
          params.row.correspondence.routedByUser?.enFullName
          :
          params.row.correspondence.routedByUser?.arFullName  
        )
      },
    },
    {
      field: "date",
      headerName: t("Date"),
      minWidth: 200,
      valueGetter(params) {
        return DateTimeFormatter(
          params.row.correspondence.correspondenceDate,
          calendarId,
          gregorianDateFormatId,
          hijriDateFormatId
        );
      },
      sortComparator: DateSorter,
    },
  ];

  return (
    <GridDX container sx={{ width: "100%" }}>
      <GridDX item xs={3} sm={12} justifyContent={"center"}>
        <ListPageDX
          rows={rows}
          columns={columns}
          getRowId={(row: any) => row.inboxId}
          isLoading={isLoading}
          onEdit={toPreview}
          editLabel={t("Preview")}
          exportToPDF={false}
          exportToCSV={false}
          searchAndFilter={false}
          checkboxSelection={true}
          customActions={[
            {
              label: t("Mark as Read"),
              onClick: handleMarkAsRead,
            },
            {
              label: t("Mark as Unread"),
              onClick: handleMarkAsUnRead,
            },
            {
              label: t("Archive Selected"),
              onClick: handleArchive,
            },
            {
              label: t("Delete Selected"),
              onClick: handleDeleteSelected,
            },
          ]}
          customFilters={[
            {
              label: t("Read"),
              onClick: filterRead,
            },
            {
              label: t("Unread"),
              onClick: filterUnRead,
            },
            {
              label: t("Archived"),
              onClick: filterArchived,
            },
            {
              label: t("Reset"),
              onClick: resetFilter,
            },
          ]}
        />
      </GridDX>
    </GridDX>
  );
};

export default Inbox;
