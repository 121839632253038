import useSecureAPI from "../hooks/usesecureapi";

const useRoutingGroupService = () => {
  const secureAPI = useSecureAPI();

  const getRoutingGroups = async () => {
    const response = await secureAPI.get("RoutingGroup");
    return response.data;
  };

  const addRoutingGroup = async (data: any) => {
    const response = await secureAPI.post(`RoutingGroup`, data);
    return response.data;
  };

  const getRoutingGroupById = async (id: number) => {
    const response = await secureAPI.get(`RoutingGroup/${id}`);
    return response.data;
  };

  const updateRoutingGroup = async (id: number, data: any) => {
    const response = await secureAPI.put(`RoutingGroup`, data);
    return response.data;
  };

  const deleteRoutingGroup = async (id: number) => {
    const response = await secureAPI.delete(`routinggroup/${id}`);
    return response.data;
  };

  return {
    getRoutingGroupById,
    getRoutingGroups,
    updateRoutingGroup,
    deleteRoutingGroup,
    addRoutingGroup,
  };
};

export default useRoutingGroupService;
