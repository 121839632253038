import { GridColDef } from "@mui/x-data-grid";
import ListPageDX from "../../../business/listpagedx";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import useDocumentReminderService from "../../../../shared/services/documentreminderservice";
import { useNotificationContext } from "../../../../context/notificationcontext";
import { useAuthContext } from "../../../../context/authcontext";
import { DateSorter, DateTimeFormatter } from "../../../../shared/globals";
import RemindersModal from "../../../editmodals/DMS/remindersmodal";

const FileReminders = (props: any) => {
  const { t, i18n } = useTranslation();
  const { userData } = useAuthContext();
  const { calendarId, gregorianDateFormatId, hijriDateFormatId } = userData;
  const { setError, setInfo } = useNotificationContext();
  const { addDocumentReminder, updateDocumentReminder, getRemindersByDocumentID, deleteDocumentReminder } = useDocumentReminderService();
  
  const columns: GridColDef[] = [
    {
      field: "description",
      headerName: `${t("Title")}`,
      flex: 1,
    },
    {
      field: "reminderDate",
      headerName: `${t("Reminder Date")}`,
      flex: 1,
      valueGetter(params) {
        return DateTimeFormatter(
          params.row.reminderDate,
          calendarId,
          gregorianDateFormatId,
          hijriDateFormatId
        );
      },
      sortComparator: DateSorter,
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [rows, setRows] = useState([]);
  const [reminder, setReminder] = useState<any>(null);
  const [reminders, setReminders] = useState<any>([]);
  const [openReminders, setOpenReminders] = useState(false);

  useEffect(()=>{
    getData();
  }, []);

  const getData = async () => {
    setIsLoading(true);
    getRemindersByDocumentID(props.documentId)
    .then((response) => {
      setRows(response);
      setReminders(response);
    })
    .catch((err) => setError(err))
    .finally(() => setIsLoading(false));
  };

  const onDelete = (id: number) => {
    setIsLoading(true);
    deleteDocumentReminder(id)
      .then(() => {
        setInfo(t("Document reminder deleted successfully"));
        getData();
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const toCreate = () => {
    setOpenReminders(!openReminders);
  };

  const onEdit = (data: any) => {
    setReminder(data);
    setOpenReminders(!openReminders);
  };

  const toSave = async (update: boolean, details: any) => {
    const data = {
      ...details,
      documentId: props.documentId
    }; 
    
    setIsSaving(true);
    if (!update) {
      updateDocumentReminder(data).then(() => {
        setInfo(t("Document reminder updated successfully"));
        setReminder(null);
        onClose(true);
      })
      .catch((err) => setError(err))
      .finally(() => setIsSaving(false));
    }
    else {
      addDocumentReminder(data).then(() => {
        setInfo(t("Document reminder added successfully"));
        onClose(true);
      })
      .catch((err) => setError(err))
      .finally(() => setIsSaving(false));
    }
  }; 

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(reminders);
      return;
    }

    const lowercasedValue = value.toLowerCase();
    const newRows = reminders.filter(({ description, createdByUser }: any) =>
      i18n.language === "en"
        ? description.toLowerCase().includes(lowercasedValue)
        : description.includes(value)
    );
    setRows(newRows);
  };

  const onClose = (refreshPage: boolean = false) => {
    setOpenReminders(false);

    if (refreshPage) getData();
  };

  return(
    <>
    {openReminders && (
      <RemindersModal
        open={openReminders}
        handleClose={()=>setOpenReminders(false)}
        reminder={reminder} 
        toSave={toSave}
        savingReminder={isSaving}
      />
    )}
    <ListPageDX
      listTitle={t("Document Reminders")}
      name={t("document reminder")}
      createLabel={t("Add Reminder")}
      rows={rows}
      columns={columns}
      getRowId={(row: any) => row.documentReminderId}
      isLoading={isLoading}
      exportToPDF={false}
      exportToCSV={false}
      toCreate={toCreate}
      onDelete={onDelete}
      onEdit={onEdit}
      setGridFilterCriteria={setGridFilterCriteria}
    />
    </>
  );
};

export default FileReminders;