import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Importing translation files
import translationEN from "../../locales/en/translation.json";
import translationAR from "../../locales/ar/translation.json";

//Creating object with the variables of imported translation files
const resources = {
  en: {
    translation: translationEN,
  },
  ar: {
    translation: translationAR,
  },
};

const getLastSelectedLanguage = () => {
  const language = localStorage.getItem("lastLanguage") ?? "en";
  return language;
};

//i18N Initialization
i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    lng: getLastSelectedLanguage(), //default language
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
