import useSecureAPI from "../hooks/usesecureapi";

const useDocumentSharingService = () => {
  const secureAPI = useSecureAPI();

  const getSharingsByDocumentID = async (id: number) => {
    const response = await secureAPI.get(`dms/DocumentSharing/fordocument/${id}`);
    return response.data;
  };
  
  const addDocumentSharing = async (id: number, data: any) => {
    const response = await secureAPI.post(`dms/DocumentSharing/${id}`, data);
    return response.data;
  };

  const deleteDocumentSharing = async (id: number) => {
    const response = await secureAPI.delete(`dms/DocumentSharing/${id}`);
    return response.data;
  };

  return {
   getSharingsByDocumentID,
   addDocumentSharing,
   deleteDocumentSharing,
  };
};

export default useDocumentSharingService;
