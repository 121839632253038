import { useTranslation } from "react-i18next";

const TypeTranslator = () => {
  const { i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";
  const correspondenceStatus: any = [];
  correspondenceStatus[1] = { id: 1, en_value: "Draft", ar_value: "مسودة" };
  correspondenceStatus[2] = { id: 2, en_value: "Pending", ar_value: "قيد الانتظار" };
  correspondenceStatus[3] = { id: 3, en_value: "Reviewed", ar_value: "تمت المراجعة" };
  correspondenceStatus[4] = { id: 4, en_value: "Approved", ar_value: "مكتمل" };
  correspondenceStatus[5] = { id: 5, en_value: "Rejected", ar_value: "مرفوض" };
  correspondenceStatus[6] = { id: 6, en_value: "Recall", ar_value: "استعادة" };

  const getCorrespondenceStatusValue = (value: number) => {
    const status = correspondenceStatus[value];

    if (status) {
      if (languageIsEn) return status.en_value;
      else return status.ar_value;
    } else return value;
  };

  return {
    getCorrespondenceStatusValue
  };
};

export default TypeTranslator;
