import CardDX from "./carddx";
import { CardContent, CircularProgress, Typography } from "@mui/material";
import BoxDX from "./boxdx";
import {
  ArrowDownwardOutlined,
  ArrowUpwardOutlined,
  RemoveOutlined,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const WhiteBox = (props: any) => {
  const { i18n } = useTranslation();

  function putArrow(percentage: string) {
    if (percentage[0] === "-") return <ArrowDownwardOutlined color="error" />;
    else if (percentage[0] === "0") return <RemoveOutlined />;
    else return <ArrowUpwardOutlined color="success" />;
  }

  return (
    <CardDX
      sx={{
        width: "100%",
        height: 160,
        my: 1,
        borderColor: "transparent",
        borderRadius: "8px",
        filter: "drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.25))",
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-evenly",
          height: "100%",
          p: 1,
          "&:last-child": {
            pb: 1,
          },
        }}
      >
        <BoxDX
          sx={{
            height: "100%",
            width: "100%",
            position: "absolute",
            display: "flex",
          }}
        >
          <BoxDX
            sx={{
              color: "#d3d3d3",
            }}
          >
            {props.icon}
          </BoxDX>
        </BoxDX>
        <BoxDX
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "end",
            alignItems: "end",
          }}
        >
          {props.percentage !== undefined ? (
            <BoxDX
              sx={{
                display: "flex",
              }}
            >
              {putArrow(props.percentage)}
              <Typography fontSize={16}>{props.percentage}</Typography>
            </BoxDX>
          ) : (
            ""
          )}
          <Typography
            sx={{
              fontSize: 47,
              fontWeight: 700,
              textAlign: "center",
            }}
            color="#333333"
          >
            {props.loading ? <CircularProgress /> : props.count}
          </Typography>
        </BoxDX>
        <Typography
          sx={{
            fontSize: 18,
            lineHeight: "20px",
            textAlign: "center",
          }}
          color="#625B71"
        >
          {props.title}
        </Typography>
      </CardContent>
    </CardDX>
  );
};

export default WhiteBox;
