import useSecureAPI from "../hooks/usesecureapi";

const useNotificationService = () => {
    const secureAPI = useSecureAPI();
    
    const getNotificationsByUser = async (id: any) => {
        const response = await secureAPI.get(`UserNotification/foruser/${id}`);
        return response.data;
    }

    const getUnreadNotificationsCount = async (id: any) => {
      const response = await secureAPI.get(`UserNotification/unreadcount/${id}`);
      return response.data;
    }

    const markAsRead = async (id: any) => {
        const response = await secureAPI.put(`UserNotification/MarkRead/${id}`);
        return response.data;
    }

    const markUnRead = async (id: any) => {
        const response = await secureAPI.put(`UserNotification/MarkUnRead/${id}`);
        return response.data;
    }

    const markAsArchived = async (id: any) => {
        const response = await secureAPI.delete(`UserNotification/${id}`);
        return response.data;
    }

    const markAllUnRead = async (userId: any) => {
        const response = await secureAPI.put(`UserNotification/MarkAllUnRead/foruser/${userId}`);
        return response.data;
    }

    return {
        getNotificationsByUser,
        getUnreadNotificationsCount,
        markAsRead,
        markUnRead,
        markAllUnRead,
        markAsArchived,
    };
};

export default useNotificationService;