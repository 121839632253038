import { CardContent, Divider, Typography } from "@mui/material";
import GridDX from "../../layout/griddx";
import Loading from "../../loading";
import ButtonDX from "../../controls/buttondx";
import { useEffect, useState } from "react";
import CardDX from "../../layout/carddx";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AutoAwesomeMosaicOutlinedIcon from "@mui/icons-material/AutoAwesomeMosaicOutlined";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import { useTranslation } from "react-i18next";
import TextFieldDX from "../../controls/textfielddx";
import BoxDX from "../../layout/boxdx";
import MenuButtonDX from "../../controls/menubutton";
import MenuItemDX from "../../controls/menuitemdx";
import { useNavigate, useParams } from "react-router-dom";
import DirectoryInformationTab from "./directoryinfotab";
import DeleteModal from "../../controls/deleteModal";
import CopyModal from "../../editmodals/DMS/copymodal";
import MoveModal from "../../editmodals/DMS/movemodal";
import DirectoryModal from "../../editmodals/DMS/directorymodal";
import useFolderService from "../../../shared/services/folderservice";
import { useNotificationContext } from "../../../context/notificationcontext";
import FolderDrawer from "./folderdrawer";
import { useAuthContext } from "../../../context/authcontext";
import { DateFormatter } from "../../../shared/globals";

const DirectoryInformationPage = (props: any) => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { userData } = useAuthContext();
  const { calendarId, gregorianDateFormatId, hijriDateFormatId } = userData;
  const { setError, setInfo } = useNotificationContext();
  const { getFolderByID, moveFolder, copyFolder, deleteFolder } =
    useFolderService();

  const [isLoading, setIsLoading] = useState(false);
  const [refetchActivity, setRefetchActivity] = useState(false);
  const [editDir, setEditDir] = useState<any>(null);
  const [directoryData, setDirectoryData] = useState<any>(null);
  const [openDirectoryModal, setOpenDirectoryModal] = useState(false);
  const [openMoveModal, setOpenMoveModal] = useState({
    open: false,
    id: null,
    document: false,
  });
  const [openCopyModal, setOpenCopyModal] = useState({
    open: false,
    id: null,
    document: false,
  });
  const [deleteModal, setDeleteModal] = useState<any>(false);
  const [selectedFolder, setSelectedFolder] = useState<any>(null);

  useEffect(() => {
    getDetails(parseInt(id as string));
  }, []);

  const getDetails = async (id: number) => {
    setIsLoading(true);
    getFolderByID(id)
      .then((res) => setDirectoryData(res))
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const toMove = () => {
    if (!openMoveModal.document) {
      const data = {
        folderId: openMoveModal.id,
        newParentFolderId: selectedFolder?.folderId,
        newCabinetId:
          directoryData.cabinetId === selectedFolder?.cabinetId
            ? -1
            : selectedFolder?.cabinetId,
      };
      moveFolder(data)
        .then((res: any) => {
          setInfo(t("Directory moved successfully"));
          onClose(true);
        })
        .catch((err: any) => setError(err));
    }
  };

  const toCopy = () => {
    if (!openCopyModal.document) {
      const data = {
        folderId: openMoveModal.id,
        newParentFolderId: selectedFolder?.folderId,
        newCabinetId:
          directoryData.cabinetId === selectedFolder?.cabinetId
            ? -1
            : selectedFolder?.cabinetId,
      };
      copyFolder(data)
        .then((res: any) => {
          setInfo(t("Directory copied successfully"));
          onClose(true);
        })
        .catch((err: any) => setError(err));
    }
  };

  const onDelete = async () => {
    setIsLoading(true);
    deleteFolder(directoryData.folderId)
      .then((res: any) => {
        setInfo(t("Folder deleted successfully"));
        setDeleteModal(false);
        navigate("/documents");
      })
      .catch((err: any) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onClose = (refreshPage: boolean = false) => {
    setOpenDirectoryModal(false);
    setOpenMoveModal({ open: false, id: null, document: false });
    setOpenCopyModal({ open: false, id: null, document: false });
    setDeleteModal(false);

    if (refreshPage) {
      getDetails(parseInt(id as string));
      setRefetchActivity(true);
    }
  };

  return (
    <GridDX container flexDirection="column" sx={{ pt: 1 }}>
      {isLoading && <Loading styles={{ height: "100%", width: "100%" }} />}
      {openDirectoryModal && (
        <DirectoryModal
          open={openDirectoryModal}
          handleClose={onClose}
          directory={editDir}
        />
      )}
      {openMoveModal.open && (
        <MoveModal
          open={openMoveModal.open}
          handleClose={onClose}
          folder={selectedFolder}
          onFolderSelect={setSelectedFolder}
          toMove={toMove}
        />
      )}
      {openCopyModal && (
        <CopyModal
          open={openCopyModal}
          handleClose={onClose}
          folder={selectedFolder}
          onFolderSelect={setSelectedFolder}
          toCopy={toCopy}
        />
      )}
      {deleteModal && (
        <DeleteModal
          open={deleteModal}
          onDeleteClick={onDelete}
          onCancelClick={onClose}
        />
      )}
      <GridDX item xs={12} justifyContent="space-between">
        <Typography fontSize={25} gutterBottom>
          {i18n.language === "en"
            ? directoryData?.enName
            : directoryData?.enName}
        </Typography>
        <BoxDX sx={{ display: "flex", flexDirection: "row" }}>
          <ButtonDX sx={{ width: 650 }} onClick={() => navigate(`/documents`)}>
            {t("Back to Directories")}
          </ButtonDX>
          <FolderDrawer folderId={parseInt(id as string)} />
          <MenuButtonDX sx={{ width: 100 }} label={t("Actions")}>
            <MenuItemDX
              onClick={() => {
                setEditDir(directoryData);
                setOpenDirectoryModal(true);
              }}
            >
              {t("Edit")}
            </MenuItemDX>
            <MenuItemDX
              onClick={() =>
                setOpenCopyModal({
                  open: true,
                  id: directoryData.folderId,
                  document: false,
                })
              }
            >
              {t("Copy")}
            </MenuItemDX>
            <MenuItemDX
              onClick={() =>
                setOpenMoveModal({
                  open: true,
                  id: directoryData.folderId,
                  document: false,
                })
              }
            >
              {t("Move")}
            </MenuItemDX>
            <MenuItemDX onClick={() => setDeleteModal(true)}>
              {t("Delete")}
            </MenuItemDX>
          </MenuButtonDX>
        </BoxDX>
      </GridDX>
      <CardDX>
        <CardContent>
          <Typography fontSize={22} gutterBottom>
            <InfoOutlinedIcon fontSize="medium" /> {t("Directory Information")}
          </Typography>
          <Divider sx={{ mb: 1 }} />
          <GridDX fullWidth>
            <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
              <GridDX item xs={6}>
                <TextFieldDX
                  readOnly
                  label={t("Creation Date")}
                  name="creationDate"
                  placeholder={t("No Information")}
                  value={DateFormatter(
                    directoryData?.createdDate,
                    calendarId,
                    gregorianDateFormatId,
                    hijriDateFormatId
                  )}
                />
              </GridDX>
              <GridDX item xs={6}>
                <TextFieldDX
                  readOnly
                  label={t("Owner")}
                  name="owner"
                  placeholder={t("No Information")}
                  value={
                    i18n.language === "en"
                      ? directoryData?.createdByUser.enFullName
                      : directoryData?.createdByUser.arFullName
                  }
                />
              </GridDX>
              {/* <GridDX item xs={6}>
                  <TextFieldDX
                    readOnly
                    label={t("Description")}
                    name="Test File"
                    placeholder={t("No Information")}
                  />
                </GridDX>
                <GridDX item xs={6}>
                  <TextFieldDX
                    readOnly
                    label={t("Categories")}
                    name="categories"
                    placeholder={t("No Information")}
                  />
                </GridDX> */}
            </GridDX>
          </GridDX>
        </CardContent>
      </CardDX>
      {/* <CardDX sx={{ mt: 2 }}>
        <CardContent>
          <Typography fontSize={22} gutterBottom>
            <AutoAwesomeMosaicOutlinedIcon fontSize="medium" /> {t("Profiles")}
          </Typography>
          <Divider sx={{ mb: 1 }} />
          <Typography textAlign={"center"} sx={{width: "100%", color: "grey"}}>
            {t("No Information")}
          </Typography>
        </CardContent>
      </CardDX> */}
      <CardDX sx={{ mt: 2, width: "100%" }}>
        <CardContent>
          <Typography fontSize={22} gutterBottom>
            <FormatListBulletedOutlinedIcon fontSize="medium" />{" "}
            {t("Other Information")}
          </Typography>
          <Divider sx={{ mb: 1 }} />
          <DirectoryInformationTab
            folderId={parseInt(id as string)}
            refetchActivity={refetchActivity}
          />
        </CardContent>
      </CardDX>
    </GridDX>
  );
};

export default DirectoryInformationPage;
