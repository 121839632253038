import useSecureAPI from "../hooks/usesecureapi";

const useContactTypeService = () => {
  const secureAPI = useSecureAPI();

  const getContactTypes = async () => {
    const response = await secureAPI.get("ContactType");
    return response.data;
  };

  const addContactType = async (data: any) => {
    const response = await secureAPI.post("ContactType", data);
    return response.data;
  };

  const updateContactType = async (id: number, data: any) => {
    const response = await secureAPI.put(`ContactType`, data);
    return response.data;
  };

  const deleteContactType = async (id: number) => {
    const response = await secureAPI.delete(`ContactType/${id}`);
    return response.data;
  };

  return {
    getContactTypes,
    addContactType,
    updateContactType,
    deleteContactType,
  };
};

export default useContactTypeService;
