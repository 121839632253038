import { Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { usePDF } from "react-to-pdf";
import { useNotificationContext } from "../../../../context/notificationcontext";
import { downloadPDF } from "../../../../shared/globals";
import ButtonDX from "../../../controls/buttondx";
import FilterRange from "../../../controls/filterrange";
import GridDX from "../../../layout/griddx";
import Loading from "../../../loading";
import useDMSReportService from "../../../../shared/services/dmsreportservice";
import ListPageDX from "../../../business/listpagedx";


const DocumentByFileExtension = (props: any) => {
  const { filterFromDate, filterToDate, isPdfGenerating, setIsPdfGenerating } =
    props;
  const { t, i18n } = useTranslation();

  const { setError } = useNotificationContext();
  const { getDocumentByFileExtension } = useDMSReportService();
  const [rows, setRows] = useState<any>([]);
  // const [dataForPieChart, setDataForPieChart] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const columns: GridColDef[] = [
    {
      field: "label",
      headerName: `${t("File Extension")}`,
      flex: 1,
      renderCell(params) {
        if (params.row.label === t("Total")) return <strong>{t("Total")}</strong>
      },
    },
    {
      field: "count",
      headerName: `${t("Files Created")}`,
      flex: 1,
      renderCell(params) {
        if (params.row.label === t("Total")) return <strong>{params.row.count}</strong>
      },
    },

  ];

  const getData = (formattedFromDate: any, formattedToDate: any) => {
    setIsLoading(true);
    getDocumentByFileExtension(formattedFromDate, formattedToDate) //fetch user data from APi
      .then((res) => {
        let total = 0;
        let data = [];
        for (let i in res) {
          const item = res[i];
          total += item.numberOfFiles;
          data.push({
            id: i,
            label: item.fileExtension,
            count: item.numberOfFiles
          })
        }
        
        setRows([
          ...data,
          {
            id: data.length + 2, 
            label: t("Total"),
            count: total
          }
        ]);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const APIdateFormater = (date: any) => {
    const thisDate = new Date(date);
    const year = date != null && thisDate?.getFullYear();
    const month =
      date != null && String(thisDate.getMonth() + 1).padStart(2, "0");
    const day = date != null && String(thisDate.getDate()).padStart(2, "0");
    return `${year}${month}${day}`;
  };
  useEffect(() => {
    let formattedFromDate =
      filterFromDate && filterFromDate != null
        ? APIdateFormater(filterFromDate)
        : null;
    let formattedToDate =
      filterToDate && filterToDate != null
        ? APIdateFormater(filterToDate)
        : null;
    if (formattedFromDate && formattedToDate) {
      getData(formattedFromDate, formattedToDate);
    }
  }, [i18n.language, filterFromDate, filterToDate]);

  const { toPDF, targetRef } = usePDF({
    filename: t("Documents By File Extension"),
    page: {
      margin: 10, // Set the margin in millimeters
      format: "a4", // You can choose 'a4' or other formats
      orientation: "portrait", // 'portrait' or 'landscape'
    },
  });
  const handleDownloadReport = () => {
    setRowsPerPage(() => rows.length);
    setTimeout(() => {
      downloadPDF({ toPDF, setIsPdfGenerating, setRowsPerPage })
    }, 1000);
  };

  return (
    <div>
      {isPdfGenerating && (
        <Loading styles={{ height: "100%", width: "100%", left: 0 }} />
      )}
      <div ref={targetRef}>
        <GridDX
          container
          xs={12}
          sx={{ marginBottom: 20, marginTop: 5, py: 2 }}
        >
          <GridDX item xs={12} sx={{ my: 1 }}>
            <Typography
              variant="h5"
              component="div"
              sx={{ flexGrow: 1, fontWeight: "bold" }}
            >
              {t("Documents By File Extension")}
            </Typography>
            {!isPdfGenerating && (
              <ButtonDX
                onClick={handleDownloadReport}
              >
                {t("Download Report")}
              </ButtonDX>
            )}
            {isPdfGenerating && (
              <FilterRange
                filterFromDate={filterFromDate}
                filterToDate={filterToDate}
              />
            )}
          </GridDX>
          {/* <GridDX
            item
            md={6}
            xs={12}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <PieChartDx data={dataForPieChart} />
          </GridDX> */}
          <GridDX item xs={12}>
            <ListPageDX
              listTitle={t("Documents By File Extension")}
              rows={rows}
              columns={columns}
              getRowId={(row: any) => row.id}
              isLoading={isLoading}
              exportPDFLabel={t("Download Report")}
              exportToPDF={false}
              exportToCSV={false}
              searchAndFilter={false}
              rowsPerPage={rowsPerPage}

            />
          </GridDX>
        </GridDX>
      </div>
    </div>
  );
};

export default DocumentByFileExtension;
