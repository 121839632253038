import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNotificationContext } from "../../../context/notificationcontext";
import useUserService from "../../../shared/services/userservices";
import AutoCompleteMultiple from "../../controls/autocompletemultiple";
import GridDX from "../../layout/griddx";
import AddEditModalDX from "../../business/addeditmodaldx";
import { useAuthContext } from "../../../context/authcontext";

const UsersModal = (props: any) => {
  const { t, i18n } = useTranslation();
  const { setError } = useNotificationContext();
  const { getActiveUsers } = useUserService();
  const { userData } = useAuthContext();
  const languageIsEn = i18n.language === "en";

  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [followers, setFollowers] = useState<any>([]);
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    getUsersList();
  }, []);

  const validateForm = () => {
    const newErrors: any = {};

    if (followers.length === 0)
      newErrors["users"] = t("This field cannot be empty");

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const getUsersList = async () => {
    setIsLoading(true);
    getActiveUsers()
      .then((data: any) => {
        const users = data.filter(
          (user: any) =>
            !props.followers.includes(user.id) && user.id !== userData.userId
        );

        const list = users.map((res: any) => ({
          text: languageIsEn ? res.enFullName : res.arFullName,
          value: res.id,
        }));
        setUsersList(list);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onSave = async () => {
    if (validateForm()) {
      props.toSave(followers);
    }
  };

  const handleInputChange = (newValue: any) => {
    setIsChanged(true);
    setFollowers(newValue);
  };

  return (
    <AddEditModalDX
      open={props.open}
      handleClose={props.handleClose}
      isSaving={props.isSaving}
      isChanged={isChanged}
      title={t("Add Users")}
      maxWidth="sm"
      onSaveClick={onSave}
    >
      <GridDX
        container
        sx={{ width: "100%", height: "100%", my: 1 }}
        alignItems="center"
        columnSpacing={1}
        rowSpacing={2}
      >
        <GridDX item xs={12}>
          <AutoCompleteMultiple
            sx={{ width: "100%" }}
            label={t("Users")}
            name="userIds"
            value={followers}
            onChange={handleInputChange}
            disabled={isLoading}
            error={errors["users"]}
            list={usersList}
          />
        </GridDX>
      </GridDX>
    </AddEditModalDX>
  );
};

export default UsersModal;
