import { useState, useEffect } from "react";
import GridDX from "../../components/layout/griddx";
import PageTitle from "../../components/pagetitle";
import { useTranslation } from "react-i18next";
import moment from "moment";
import momentHijri from "moment-hijri";
import { useAuthContext } from "../../context/authcontext";
import { useNotificationContext } from "../../context/notificationcontext";
import FilterRange from "../../components/controls/filterrange";
import DMSStatistics from "../../components/pages/DMS/dmsreports/dmsstatistics";
import ItemsCreatedByCabinets from "../../components/pages/DMS/dmsreports/itemscreatedbycabinets";
import ItemsCreatedByUser from "../../components/pages/DMS/dmsreports/itemscreatedbyuser";
import DocumentByFileExtension from "../../components/pages/DMS/dmsreports/documentbyfileextension";

const DMSReports = () => {
  const { t } = useTranslation();
  const { setError } = useNotificationContext();
  const { userData } = useAuthContext();
  const { calendarId } = userData;

  const [filterFromDate, setFilterFromDate] = useState<any>(null);
  const [filterToDate, setFilterToDate] = useState<any>(null);

  const [isPdfGenerating, setIsPdfGenerating] = useState(false);

  useEffect(() => {
    if (calendarId === 1) {
      setFilterFromDate(moment("20140101"));
      setFilterToDate(moment());
    } else {
      setFilterFromDate(momentHijri("20140101"));
      setFilterToDate(momentHijri());
    }
  }, []);

  const handleChangeFromDate = (date: any) => {
    const filterDate =
      calendarId === 1 ? new Date(date) : momentHijri(new Date(date));
    if (filterToDate != null && filterDate > filterToDate) {
      setError(t("From date must be less than to date"));
      setFilterFromDate(null);
    } else {
      setFilterFromDate(
        calendarId === 1 ? new Date(date) : momentHijri(new Date(date))
      );
    }
  };

  const handleChangeToDate = (date: any) => {
    const filterDate =
      calendarId == 1 ? new Date(date) : momentHijri(new Date(date));
    if (filterFromDate != null && filterDate < filterFromDate) {
      setError(t("To date must be greater than from date"));
      setFilterToDate(null);
    } else {
      setFilterToDate(
        calendarId == 1 ? new Date(date) : momentHijri(new Date(date))
      );
    }
  };

  return (
    <GridDX container sx={{ width: "100%" }}>
      <GridDX item xs={12} sx={{ marginBottom: 5 }}>
        <PageTitle />
      </GridDX>
      <GridDX
        item
        xs={12}
        sx={{ marginBottom: 5, display: "flex", justifyContent: "flex-end" }}
      >
        {!isPdfGenerating && (
          <FilterRange
            filterFromDate={filterFromDate}
            filterToDate={filterToDate}
            handleChangeFromDate={handleChangeFromDate}
            handleChangeToDate={handleChangeToDate}
          />
        )}
      </GridDX>

      <DMSStatistics
        filterFromDate={filterFromDate}
        filterToDate={filterToDate}
        isPdfGenerating={isPdfGenerating}
        setIsPdfGenerating={setIsPdfGenerating}
      />
      <ItemsCreatedByUser
        filterFromDate={filterFromDate}
        filterToDate={filterToDate}
        isPdfGenerating={isPdfGenerating}
        setIsPdfGenerating={setIsPdfGenerating}
      />
      <ItemsCreatedByCabinets
        filterFromDate={filterFromDate}
        filterToDate={filterToDate}
        isPdfGenerating={isPdfGenerating}
        setIsPdfGenerating={setIsPdfGenerating}
      />
      <DocumentByFileExtension
        filterFromDate={filterFromDate}
        filterToDate={filterToDate}
        isPdfGenerating={isPdfGenerating}
        setIsPdfGenerating={setIsPdfGenerating}
      />
    </GridDX>
  );
};

export default DMSReports;
