import useSecureAPI from "../hooks/usesecureapi";

const useCorrespondenceReminderService = () => {
  const secureAPI = useSecureAPI();



  const getRemindersByCorrespondenceID = async (id: number) => {
    const response = await secureAPI.get(`Reminder/forcorrespondence/${id}`);
    return response.data;
  };
  

  const addCorrespondenceReminder = async (data: any) => {
    const response = await secureAPI.post("Reminder", data);
    return response.data;
  };

  const updateCorrespondenceReminder = async (id: number, data: any) => {
    const response = await secureAPI.put(`Reminder`, data);
    return response.data;
  };

  const getReminderByReminderID = async (id: number) => {
    const response = await secureAPI.get(`Reminder/${id}`);
    return response.data;
  };

  const deleteCorrespondenceReminder = async (id: number) => {
    const response = await secureAPI.delete(`Reminder/${id}`);
    return response.data;
  };

  return {
   getRemindersByCorrespondenceID,
   addCorrespondenceReminder,
   deleteCorrespondenceReminder,
   updateCorrespondenceReminder,
   getReminderByReminderID
  };
};

export default useCorrespondenceReminderService;
