import { Dialog, DialogTitle, DialogContent, Typography, DialogActions } from "@mui/material";
import ButtonDX from "./buttondx";
import GridDX from "../layout/griddx";
import { useTranslation } from "react-i18next";

const ConfirmModal = (props: any) => {
  const { t, i18n } = useTranslation();
  
  return (
    <Dialog
      open={props.open}
      onClose={props.onNoClick}
      fullWidth
      maxWidth={"xs"}
      disableEscapeKeyDown
      sx={{ direction: i18n.dir() }}
    >
      <DialogTitle sx={{ textAlign: "center" }}>{t("Are You Sure?")}</DialogTitle>
      <DialogContent>
        <GridDX justifyContent="center" direction="column">
          <Typography sx={{ textAlign: "center" }}  >
            {t("unsaved data")}
          </Typography>
        </GridDX>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-around" }}>
        <ButtonDX
          variant="outlined"
          onClick={props.onNoClick}
        >
          {t("No")}
        </ButtonDX>
        <ButtonDX
          onClick={() => {
            props.onYesClick();
          }}
        >
          {t("Yes")}
        </ButtonDX>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmModal;