import { GridColDef } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import ForwardToInboxOutlinedIcon from "@mui/icons-material/ForwardToInboxOutlined";
import MarkEmailUnreadOutlinedIcon from "@mui/icons-material/MarkEmailUnreadOutlined";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
import moment from "moment";

import { useAuthContext } from "../../../context/authcontext";
import { useNotificationContext } from "../../../context/notificationcontext";

import ListPageDX from "../../../components/business/listpagedx";
import GridDX from "../../../components/layout/griddx";
import WhiteBox from "../../../components/layout/whitebox";
import ChartBox from "../../../components/layout/chartbox";
import CreateCorrespondenceModal from "../../../components/editmodals/correspondence/createcorrespondencemodal";

import TypeTranslator from "../../../shared/typetranslator";
import useCorrespondenceService from "../../../shared/services/correspondenceservice";
import { DateFormatter, DateSorter } from "../../../shared/globals";

const CorrespondenceList = () => {
  const { t, i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";
  const { getCorrespondenceStatusValue } = TypeTranslator();
  const { setInfo, setError } = useNotificationContext();
  const { getCorrespondenceList, deleteCorrespondence } =
    useCorrespondenceService();
  const navigate = useNavigate();
  const { userData } = useAuthContext();
  const { calendarId, gregorianDateFormatId, hijriDateFormatId } = userData;
  const [correspondenceData, setCorrespondenceData] = useState<any>([]);
  const [rows, setRows] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [filterByStatus, setFilterByStatus] = useState<any>(false);
  const [filterByType, setFilterByType] = useState<any>(false);

  useEffect(() => {
    getData();
  }, [i18n.language]);

  const filterRoute = () => {
    const newRows = correspondenceData.filter(
      (correspondence: any) =>
        correspondence.routedByUser?.id === userData.userId
    );
    setRows(newRows);
  };

  const filterImportant = () => {
    const newRows = correspondenceData.filter(
      (correspondence: any) => correspondence.importanceLevel?.priority === 1
    );
    setRows(newRows);
  };

  const filterOverDue = () => {
    const newRows = correspondenceData.filter((correspondence: any) =>
      moment(correspondence?.dueDate).isBefore(moment())
    );
    setRows(newRows);
  };

  const resetFilter = () => setRows(correspondenceData);

  const columns: GridColDef[] = [
    {
      field: "correspondenceId",
      headerName: `${t("ID")}`,
      align: "center",
      headerAlign: "center",
      minWidth: 100,
      hideable: true,
    },
    {
      field: "subject",
      headerName: `${t("Subject")}`,

      headerAlign: "center",
      minWidth: 110,
    },
    {
      field: "correspondenceDate",
      headerName: `${t("Date")}`,

      align: "center",
      headerAlign: "center",
      sortComparator: DateSorter,
      minWidth: 100,
    },
    {
      field: languageIsEn
        ? "correspondenceTypeEnName"
        : "correspondenceTypeArName",
      headerName: `${t("Type")}`,

      align: "center",
      headerAlign: "center",
      minWidth: 100,
    },
    {
      field: "status",
      headerName: `${t("Status")}`,
      flex: 1,
      align: "center",
      headerAlign: "center",
      minWidth: 100,
    },
    {
      field: "importanceLevel",
      headerName: `${t("Importance Level")}`,
      align: "center",
      headerAlign: "center",
      minWidth: 150,
      valueGetter(params) {
        return languageIsEn
          ? params.row.importanceLevel?.enName
          : params.row.importanceLevel?.arName;
      },
    },
    {
      field: "privacyLevel",
      headerName: `${t("Privacy Level")}`,
      align: "center",
      headerAlign: "center",
      minWidth: 150,
      valueGetter(params) {
        return languageIsEn
          ? params.row.privacyLevel?.enName
          : params.row.privacyLevel?.arName;
      },
    },
    {
      field: "domainType",
      headerName: `${t("Domain")}`,
      flex: 1,
      align: "center",
      headerAlign: "center",
      minWidth: 100,
      valueGetter(params) {
        return languageIsEn
          ? params.row.domainType?.enName
          : params.row.domainType?.arName;
      },
    },

    {
      field: languageIsEn ? "entityNameEnName" : "entityNameArName",
      headerName: `${t("From (Entity)")}`,
      flex: 1,
      align: "center",
      headerAlign: "center",
      minWidth: 100,
    },
    {
      field: "routedByUser",
      headerName: `${t("From (User)")}`,
      flex: 1,
      align: "center",
      headerAlign: "center",
      minWidth: 100,
      valueGetter(params) {
        return i18n.language === "en"
          ? params.row.routedByUser?.enFullName
          : params.row.routedByUser?.arFullName;
      },
    },
    {
      field: languageIsEn ? "enDestination" : "arDestination",
      headerName: `${t("Current Destination")}`,
      flex: 1,
      headerAlign: "center",
      minWidth: 100,
    },
    {
      field: "modifiedDate",
      headerName: `${t("Last modified at")}`,
      flex: 1,
      align: "center",
      headerAlign: "center",
      minWidth: 100,
      sortComparator: DateSorter,
    },
  ];

  const filters = [
    {
      label: t("Routed By Me"),
      onClick: filterRoute,
    },
    {
      label: t("Most Important"),
      onClick: filterImportant,
    },
    {
      label: t("Over Due"),
      onClick: filterOverDue,
    },
    {
      label: t("Filter By Status"),
      onClick: () => {
        filterByStatus && resetFilter();
        setFilterByType(false);
        setFilterByStatus(!filterByStatus);
      },
    },
    {
      label: t("Filter By Type"),
      onClick: () => {
        filterByType && resetFilter();
        setFilterByStatus(false);
        setFilterByType(!filterByType);
      },
    },
    {
      label: t("Reset"),
      onClick: resetFilter,
    },
  ];

  const statusFilters = [1, 2, 3, 4, 5, 6].map((status) => ({
    label: getCorrespondenceStatusValue(status),
    onClick: () => {
      const newRows = correspondenceData.filter(
        (correspondence: any) =>
          correspondence.status === getCorrespondenceStatusValue(status)
      );
      setRows(newRows);
    },
  }));

  const typeFilters = [t("Incoming"), t("Outgoing"), t("Internal")].map(
    (type) => ({
      label: type,
      onClick: () => {
        const newRows = correspondenceData.filter(
          (correspondence: any) =>
            correspondence.correspondenceTypeEnName === type ||
            correspondence.correspondenceTypeArName === type
        );
        setRows(newRows);
      },
    })
  );

  const getData = () => {
    setIsLoading(true);
    getCorrespondenceList()
      .then((res) => {
        const preprocessedData = res.map((data: any) => ({
          ...data,
          status: getCorrespondenceStatusValue(data.status), // Override the status field,
          correspondenceDate: DateFormatter(
            data.correspondenceDate,
            calendarId,
            gregorianDateFormatId,
            hijriDateFormatId
          ),
        }));
        setRows(preprocessedData);
        setCorrespondenceData(preprocessedData);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const toPreview = (data: any) => {
    navigate(`/correspondencedetails/${data.correspondenceId}`);
  };

  const toCreate = () => {
    setOpen(!open);
  };

  const onDelete = (id: number) => {
    setIsLoading(true);
    deleteCorrespondence(id)
      .then(() => {
        setInfo(t("Data archived successfully"));
        getData();
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onClose = (refreshPage: boolean = false) => {
    setOpen(!open);

    if (refreshPage) getData();
  };

  const setGridFilterCriteria = (value: string) => {
    // if (!value.trim()) {
    //   setRows(correspondenceData);
    //   return;
    // }
    const lowercasedValue = value.toLowerCase();
    const newRows = correspondenceData?.filter(
      ({
        correspondenceId,
        subject,
        correspondenceTypeEnName,
        correspondenceTypeArName,
        entityNameEnName,
        entityNameArName,
        enDestination,
        arDestination,
        routedByUser,
        correspondenceDate,
        status,
        contentAsText,
      }: any) =>
        languageIsEn
          ? correspondenceId?.toString().includes(value) ||
            subject?.toLowerCase().includes(lowercasedValue) ||
            routedByUser?.enFullName?.toLowerCase().includes(lowercasedValue) ||
            correspondenceTypeEnName?.toLowerCase().includes(lowercasedValue) ||
            entityNameEnName?.toLowerCase().includes(lowercasedValue) ||
            enDestination?.toLowerCase().includes(lowercasedValue) ||
            status?.toLowerCase().includes(lowercasedValue) ||
            correspondenceDate?.toLowerCase().includes(lowercasedValue) ||
            contentAsText?.toLowerCase().includes(lowercasedValue)
          : correspondenceId?.toString().includes(value) ||
            subject?.toLowerCase().includes(value) ||
            routedByUser?.arFullName?.includes(value) ||
            correspondenceTypeArName?.includes(value) ||
            entityNameArName?.includes(value) ||
            arDestination?.includes(value) ||
            status?.includes(value) ||
            correspondenceDate?.toLowerCase().includes(lowercasedValue) ||
            contentAsText?.toLowerCase().includes(value)
    );
    setRows(newRows);
  };
  return (
    <GridDX
      container
      sx={{ width: "100%", height: "100%", my: 1 }}
      alignItems="center"
      columnSpacing={1}
      rowSpacing={2}
    >
      {open && <CreateCorrespondenceModal open={open} handleClose={onClose} />}
      <GridDX item xs={12}>
        <ListPageDX
          listTitle={t("Correspondence List")}
          name="correspondence"
          rows={rows}
          columns={columns}
          editLabel={t("Preview")}
          deleteLabel={t("Archive")}
          getRowId={(row: any) => row.correspondenceId}
          isLoading={isLoading}
          toCreate={toCreate}
          onEdit={toPreview}
          onDelete={onDelete}
          setGridFilterCriteria={setGridFilterCriteria}
          customFilters={filters}
          filterByStatus={filterByStatus ? statusFilters : undefined}
          filterByType={filterByType ? typeFilters : undefined}
          hideColumnsOption={true}
        />
      </GridDX>
    </GridDX>
  );
};

export default CorrespondenceList;