import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
} from "@mui/material";
import ButtonDX from "./buttondx";
import GridDX from "../layout/griddx";
import { useTranslation } from "react-i18next";
import LoadingButtonDX from "./loadingbuttondx";

const RestoreModal = (props: any) => {
  const {
    open,
    onCancelClick,
    onRestoreClick,
    deleteLabel,
    name,
    deleteMessage,
    loading,
  } = props;
  const { t, i18n } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={!loading && onCancelClick}
      fullWidth
      maxWidth={"xs"}
      disableEscapeKeyDown
      sx={{ direction: i18n.dir() }}
    >
      <DialogTitle sx={{ textAlign: "center" }}>
        {t("Are You Sure?")}
      </DialogTitle>
      <DialogContent>
        <GridDX justifyContent="center" direction="column">
          <Typography sx={{ textAlign: "center" }}>
            {deleteMessage && deleteMessage.length > 0 ? (
              deleteMessage
            ) : deleteLabel == t("Unarchive") ? (
              <>
                {t("You want to unarchive this")}&nbsp;{t(name || "entry")}
              </>
            ) : (
              <>
                {t("You want to restore this")}&nbsp;{t(name || "entry")}
              </>
            )}
          </Typography>
        </GridDX>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-around" }}>
        <ButtonDX variant="outlined" onClick={onCancelClick} disabled={loading}>
          {t("Cancel")}
        </ButtonDX>
        <LoadingButtonDX
          loading={loading}
          onClick={onRestoreClick}
          loadingPosition="end"
        >
          {t(deleteLabel || "Restore")}
        </LoadingButtonDX>
      </DialogActions>
    </Dialog>
  );
};

export default RestoreModal;
