import { Dialog, DialogTitle, DialogContent, Typography, DialogActions } from "@mui/material";
import ButtonDX from "./buttondx";
import GridDX from "../layout/griddx";
import { useTranslation } from "react-i18next";

const CorrespondenceConfirmModal = (props: any) => {

  const { open, onCancelClick, onConfirmClick, label } = props
  const { t, i18n } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={onCancelClick}
      fullWidth
      maxWidth={"xs"}
      disableEscapeKeyDown
      sx={{ direction: i18n.dir() }}
    >
      <DialogTitle sx={{ textAlign: "center" }}>{t("Are You Sure?")}</DialogTitle>
      <DialogContent>
        <GridDX  container justifyContent="center" direction="column">
          <Typography sx={{ textAlign: "center" }}  >
            {t(`You want to ${label} this correspondence?`)}
          </Typography>
        </GridDX>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-around" }}>
        <ButtonDX
          variant="outlined"
          onClick={() => onCancelClick()}
        >
          {t("Cancel")}
        </ButtonDX>
        <ButtonDX
          onClick={onConfirmClick}
        >
          {t("Yes")}
        </ButtonDX>
      </DialogActions>
    </Dialog >
  );
}

export default CorrespondenceConfirmModal;