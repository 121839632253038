import { useEffect, useRef, useState } from "react";
import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  Document,
  Font,
  Image,
  Page,
  pdf,
  StyleSheet,
} from "@react-pdf/renderer";
import moment from "moment";
import momentHijri from "moment-hijri";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useTranslation } from "react-i18next";
import { Html } from "react-pdf-html";

import { useAuthContext } from "../../../context/authcontext";
import { useNotificationContext } from "../../../context/notificationcontext";

import ar from "../../../locales/arEditor";
import {
  CorrespondenceStatus,
  textToBase64Barcode,
} from "../../../shared/globals";
import useCorrespondenceService from "../../../shared/services/correspondenceservice";
import useCorrespondenceTypeService from "../../../shared/services/correspondencetypes";
import useDeliveryService from "../../../shared/services/deliverytypeservice";
import useDomainTypeService from "../../../shared/services/domaintypeservice";
import useImportanceLevelsService from "../../../shared/services/importancelevelsservice";
import usePrivacyLevelsService from "../../../shared/services/privacylevelsservice";
import useRoutingGroupService from "../../../shared/services/routinggroupservice";
import useUserService from "../../../shared/services/userservices";
import ButtonDX from "../../controls/buttondx";
import ConfirmModal from "../../controls/confirmmodal";
import DatePickerDX from "../../controls/datepickerdx";
import LoadingButtonDX from "../../controls/loadingbuttondx";
import SelectListDX from "../../controls/selectlistdx";
import TextFieldDX from "../../controls/textfielddx";
import GridDX from "../../layout/griddx";
import ScanModal from "../scanmodal";
import { getContentFromPdf } from "../../../shared/pdfoperations";
import useDocumentTemplateService from "../../../shared/services/documenttemplateservice";
import CheckBoxDX from "../../controls/checkboxdx";
import CKEditorDX from "../../controls/ckeditordx";

const CreateCorrespondenceModal = (props: any) => {
  const { t, i18n } = useTranslation();
  const { userData } = useAuthContext();
  const { calendarId } = userData;
  const languageIsEn = i18n.language === "en";
  const { getUserDetails } = useAuthContext();
  const { setError, setInfo } = useNotificationContext();
  const { addCorrespondence } = useCorrespondenceService();
  const { getCorrespondenceType } = useCorrespondenceTypeService();
  const { getImportanceLevels } = useImportanceLevelsService();
  const { getPrivacyLevels } = usePrivacyLevelsService();
  const { getDomainTypes } = useDomainTypeService();
  const { getActiveUsers } = useUserService();
  const { getDeliveryTypes } = useDeliveryService();
  const { getRoutingGroups } = useRoutingGroupService();
  const { getDocumentTemplates } = useDocumentTemplateService();

  const defaultValues = {
    Subject: "",
    CorrespondenceDate: calendarId === 1 ? moment() : momentHijri(),
    DueDate: null,
    Description: "",
    Notes: "",
    CorrespondenceTypeId: 1,
    ContentAsText: " ",
    MainAttachmentFile: "",
    ImportanceLevelId: null,
    PrivacyLevelId: null,
    DomainTypeId: null,
    RoutingGroupId: null,
    RoutedBy: "",
    applyWatermark: false,
    IsDocumentDelivered: false,
    DeliveryMethodId: null,
  };
  const [correspondenceData, setCorrespondenceData] =
    useState<any>(defaultValues);
  const [date, setDate] = useState(correspondenceData.CorrespondenceDate);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [extractingText, setExtractingText] = useState(false);
  const [open, setOpen] = useState(false);
  const [openScan, setOpenScan] = useState(false);
  const [errors, setErrors] = useState<any>({});
  // const [template, setTemplate] = useState<any>(null);
  const [attachmentType, setAttachmentType] = useState(1);
  const [correspondenceTypeList, setCorrespondenceTypeList] = useState([]);
  const [importanceLevelList, setImportanceLevelList] = useState([]);
  const [privacyLevelList, setPrivacyLevelList] = useState([]);
  const [domainTypeList, setDomainTypeList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [deliveryList, setDeliveryList] = useState([]);
  const [routingGroupList, setRoutingGroupList] = useState([]);
  const [documentTemplateList, setDocumentTemplateList] = useState([]);
  const [fileName, setFileName] = useState("");
  const [template, setTemplate] = useState<any>({
    id: 0,
    content: "",
  });
  const arEditorTranslation = {
    translations: ar,
  };

  useEffect(() => {
    populateLists();
  }, []);

  const populateLists = async () => {
    setIsLoading(true);
    const p1 = getCorrespondenceType();
    const p2 = getDomainTypes();
    const p3 = getImportanceLevels();
    const p4 = getPrivacyLevels();
    const p5 = getActiveUsers();
    const p6 = getDeliveryTypes();
    const p7 = getRoutingGroups();
    const p8 = getUserDetails();
    const p9 = getDocumentTemplates();

    Promise.all([p1, p2, p3, p4, p5, p6, p7, p8, p9])
      .then(
        ([
          correspondenceTypes,
          domainTypes,
          importanceLevels,
          privacyLevels,
          users,
          deliveryTypes,
          routingGroups,
          userDetails,
          documentTemplates,
        ]) => {
          setCorrespondenceTypeList(correspondenceTypes);
          setDomainTypeList(domainTypes);
          setImportanceLevelList(importanceLevels);
          setPrivacyLevelList(privacyLevels);
          setUsersList(users);
          setDeliveryList(deliveryTypes);
          setRoutingGroupList(routingGroups);
          setCorrespondenceData({
            ...correspondenceData,
            RoutedBy: userDetails.userId,
          });
          setDocumentTemplateList(documentTemplates);
        }
      )
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const validateForm = () => {
    const newErrors: any = {};

    if (correspondenceData.Subject.length === 0)
      newErrors["Subject"] = t("Subject is required");
    if (correspondenceData.RoutingGroupId === null)
      newErrors["route"] = t("Select a routing group");

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = async (status: CorrespondenceStatus) => {
    if (validateForm()) {
      setIsSaving(true);
      const docBlob = await pdf(
        <MyDocument text={correspondenceData.ContentAsText} />
      ).toBlob();

      const docFile = new File([docBlob], "createdDoc.pdf", {
        type: "application/pdf",
      });

      const data: any = {
        ...correspondenceData,
        Status: status,
        CorrespondenceDate: moment(correspondenceData.CorrespondenceDate)
          .locale("en")
          .format(),
        DueDate:
          correspondenceData.DueDate !== null
            ? moment(correspondenceData.DueDate).locale("en").format()
            : null,
        ContentAsText: correspondenceData.ContentAsText,
        MainAttachmentFile:
          attachmentType === 1
            ? correspondenceData.MainAttachmentFile
            : docFile,
      };

      var form_data = new FormData();

      for (var key in data) {
        if (data[key] !== null) form_data.append(key, data[key]);
      }

      addCorrespondence(form_data)
        .then((response) => {
          setInfo(t("Correspondence created successfully"));
          props.handleClose(true);
        })
        .catch((err) => setError(err))
        .finally(() => setIsSaving(false));
    }
  };

  // const handleTemplateChange = (e: any) => {
  //   const { name, value } = e.target;
  //   setIsChanged(true);
  //   setTemplate(value);
  // };

  const handleInputChange = (e: any) => {
    const { name, value, type, checked } = e.target;
    if (name === "attachmentType") setAttachmentType(value);
    else {
      setIsChanged(true);
      setCorrespondenceData({
        ...correspondenceData,
        [name]: type == "checkbox" ? checked : value,
      });
    }
  };

  const handleTemplateChange = (e: any) => {
    const { name, value } = e.target;
    setIsChanged(true);
    setTemplate(value);
    setCorrespondenceData({
      ...correspondenceData,
      ContentAsText: value.content,
    });
  };

  const handleDateChange = (value: any, name: any) => {
    if (name === "CorrespondenceDate") setDate(value);
    setIsChanged(true);
    setCorrespondenceData({
      ...correspondenceData,
      [name]: value,
    });
  };

  const onUpload = async (event: any) => {
    const file: File = event.target.files[0];
    if (fileTypeValidator(file)) {
      setExtractingText(true);
      getContentFromPdf(file)
        .then((content) => {
          setCorrespondenceData({
            ...correspondenceData,
            MainAttachmentFile: file,
            ContentAsText: content,
          });
          setFileName(file.name);
        })
        .catch((err) => setError(err))
        .finally(() => setExtractingText(false));
    }
  };

  const onScan = async (file: any) => {
    const fileData = new File([file], "scannedDoc.pdf", {
      type: "application/pdf",
    });

    setExtractingText(true);
    getContentFromPdf(fileData)
      .then((content) => {
        setCorrespondenceData({
          ...correspondenceData,
          MainAttachmentFile: fileData,
          ContentAsText: content,
        });
        setFileName("scannedDoc.pdf");
      })
      .catch((err) => setError(err))
      .finally(() => setExtractingText(false));
  };

  const onFileDelete = () => {
    setCorrespondenceData({
      ...correspondenceData,
      MainAttachmentFile: "",
    });
  };

  const contentChange = (newContent: string) => {
    setIsChanged(true);
    setCorrespondenceData({
      ...correspondenceData,
      ContentAsText: newContent,
    });
  };

  const fileTypeValidator = (file: File) => {
    //function to reject exe, dll, and system files
    if (
      file.type === "" ||
      file.type === "application/x-msdownload" ||
      file.type === "application/octet-stream"
    ) {
      setError(t("File type not accepted"));
      return false;
    } else return true;
  };

  const onClose = () => {
    if (isChanged) setOpen(true);
    else props.handleClose();
  };

  return (
    <Dialog
      open={props.open}
      onClose={onClose}
      fullWidth
      maxWidth={"xl"}
      disableEscapeKeyDown
      sx={{ direction: i18n.dir() }}
    >
      <DialogTitle sx={{ textAlign: "center" }}>
        {t("Create Correspondence")}
      </DialogTitle>
      <DialogContent>
        {open && (
          <ConfirmModal
            open={open}
            onYesClick={props.handleClose}
            onNoClick={() => setOpen(false)}
          />
        )}
        {openScan && (
          <ScanModal
            open={openScan}
            onClose={() => setOpenScan(false)}
            setFile={onScan}
          />
        )}
        <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
          <GridDX item xs={12}>
            <SelectListDX
              label={t("Correspondence Type")}
              items={correspondenceTypeList.map((type: any) => {
                return {
                  value: type.correspondenceTypeId,
                  text: languageIsEn ? type.enName : type.arName,
                };
              })}
              name="CorrespondenceTypeId"
              value={correspondenceData.CorrespondenceTypeId}
              onChange={handleInputChange}
              disabled={isLoading}
              // error={}
            />
          </GridDX>
          <GridDX item xs={6}>
            <TextFieldDX
              label={t("Subject")}
              name="Subject"
              value={correspondenceData.Subject}
              onChange={handleInputChange}
              error={errors["Subject"]}
            />
          </GridDX>
          <GridDX item xs={6}>
            <TextFieldDX
              label={t("Description")}
              name="Description"
              value={correspondenceData.Description}
              onChange={handleInputChange}
              // error={}
            />
          </GridDX>
          <GridDX item xs={6}>
            <DatePickerDX
              label={t("Correspondence Date")}
              sx={{ flex: 1 }}
              name="CorrespondenceDate"
              value={moment(correspondenceData.CorrespondenceDate)}
              calendarId={calendarId}
              onChange={(value: any) => {
                handleDateChange(value, "CorrespondenceDate");
              }}
            />
          </GridDX>
          <GridDX item xs={6}>
            <DatePickerDX
              label={t("Due Date")}
              sx={{ flex: 1 }}
              name="DueDate"
              minDate={date}
              value={moment(correspondenceData.DueDate)}
              calendarId={calendarId}
              onChange={(value: any) => {
                handleDateChange(value, "DueDate");
              }}
            />
          </GridDX>
          <GridDX item xs={6}>
            <SelectListDX
              label={t("Routing Group")}
              items={routingGroupList.map((group: any) => {
                return {
                  value: group.routingGroupId,
                  text: languageIsEn ? group.enName : group.arName,
                };
              })}
              name="RoutingGroupId"
              value={correspondenceData.RoutingGroupId}
              onChange={handleInputChange}
              disabled={isLoading}
              error={errors["route"]}
            />
          </GridDX>
          <GridDX item xs={6}>
            <SelectListDX
              label={t("Routed By")}
              items={usersList.map((user: any) => {
                return {
                  value: user.id,
                  text:
                    i18n.language === "en" ? user.enFullName : user.arFullName,
                };
              })}
              name="RoutedBy"
              value={correspondenceData.RoutedBy}
              onChange={handleInputChange}
              disabled={isLoading}
            />
          </GridDX>
          <GridDX item xs={6}>
            <SelectListDX
              label={t("Importance Level")}
              items={importanceLevelList.map((level: any) => {
                return {
                  value: level.importanceLevelId,
                  text: languageIsEn ? level.enName : level.arName,
                };
              })}
              name="ImportanceLevelId"
              value={correspondenceData.ImportanceLevelId}
              onChange={handleInputChange}
              disabled={isLoading}
              // error={}
            />
          </GridDX>
          <GridDX item xs={6}>
            <SelectListDX
              label={t("Privacy Level")}
              items={privacyLevelList.map((level: any) => {
                return {
                  value: level.privacyLevelId,
                  text: languageIsEn ? level.enName : level.arName,
                };
              })}
              name="PrivacyLevelId"
              value={correspondenceData.PrivacyLevelId}
              onChange={handleInputChange}
              disabled={isLoading}
              // error={}
            />
          </GridDX>
          <GridDX item xs={6}>
            <SelectListDX
              label={t("Domain Type")}
              items={domainTypeList.map((type: any) => {
                return {
                  value: type.domainTypeId,
                  text: languageIsEn ? type.enName : type.arName,
                };
              })}
              name="DomainTypeId"
              value={correspondenceData.DomainTypeId}
              onChange={handleInputChange}
              disabled={isLoading}
              // error={}
            />
          </GridDX>
          <GridDX item xs={6}>
            <SelectListDX
              label={t("Delivery Method")}
              items={deliveryList.map((method: any) => {
                return {
                  value: method.deliveryMethodId,
                  text: languageIsEn ? method.enName : method.arName,
                };
              })}
              name="DeliveryMethodId"
              value={correspondenceData.DeliveryMethodId}
              onChange={handleInputChange}
              disabled={isLoading}
              // error={}
            />
          </GridDX>
          <GridDX item xs={12} sm={6}>
            <SelectListDX
              label={t("Attachment Type")}
              items={[
                { value: 1, text: t("Upload") + "/" + t("Scan") },
                { value: 2, text: t("Create Document") },
              ]}
              name="attachmentType"
              value={attachmentType}
              onChange={handleInputChange}
              // error={}
            />
          </GridDX>
          {attachmentType === 1 ? (
            <>
              <GridDX xs={12} sm={6} pt={1}>
                <GridDX
                  component="fieldset"
                  sx={{
                    ml: 1,
                    mr: 0,
                    height: 65,
                    width: "100%",
                    border: "1px solid #c6c6c6",
                    borderRadius: 1,
                  }}
                >
                  <legend style={{ color: "#808080", fontSize: 12 }}>
                    {t("Main Attachment")}
                  </legend>
                  {correspondenceData.MainAttachmentFile !== "" ? (
                    <Chip
                      label={fileName}
                      color="primary"
                      onDelete={onFileDelete}
                    />
                  ) : (
                    <>
                      <LoadingButtonDX
                        variant="contained"
                        component="label"
                        sx={{ height: 32 }}
                        loading={extractingText}
                      >
                        {t("Upload")}
                        <input type="file" hidden onChange={onUpload} />
                      </LoadingButtonDX>
                      <LoadingButtonDX
                        sx={{ mx: 1, height: 32 }}
                        onClick={() => setOpenScan(true)}
                        loading={extractingText}
                      >
                        {t("Scan")}
                      </LoadingButtonDX>
                    </>
                  )}
                </GridDX>
              </GridDX>
              <GridDX item xs={12}>
                <CheckBoxDX
                  name="applyWatermark"
                  label={t("Apply Watermark")}
                  checked={correspondenceData.applyWatermark}
                  onChange={handleInputChange}
                />
              </GridDX>
            </>
          ) : (
            <>
              <GridDX item xs={6}>
                <SelectListDX
                  label={t("Document Template")}
                  items={documentTemplateList.map((template: any) => {
                    return {
                      value: template,
                      text: languageIsEn ? template.enName : template.arName,
                    };
                  })}
                  name="documentTemplateId"
                  value={template}
                  onChange={handleTemplateChange}
                  // error={}
                />
              </GridDX>
              <GridDX item xs={12}>
                <CheckBoxDX
                  name="applyWatermark"
                  label={t("Apply Watermark")}
                  checked={correspondenceData.applyWatermark}
                  onChange={handleInputChange}
                />
              </GridDX>
              <GridDX item xs={12} justifyContent="center">
                <CKEditorDX
                  label={t("Template")}
                  langSelectorLabe={"Language"}
                  data={correspondenceData?.ContentAsText}
                  onChange={contentChange}
                />
              </GridDX>
            </>
          )}
          <GridDX item xs={12}>
            <TextFieldDX
              label={t("Notes")}
              fullWidth
              multiline
              name="Notes"
              rows={3}
              value={correspondenceData.Notes}
              onChange={handleInputChange}
              // error={}
            />
          </GridDX>
        </GridDX>
      </DialogContent>
      <DialogActions>
        <GridDX
          container
          width={"100%"}
          sx={{ justifyContent: "space-around" }}
        >
          <GridDX xs={12} md={4} mt={1} justifyContent="center">
            <ButtonDX
              variant="outlined"
              onClick={onClose}
              sx={{ mb: { xs: 2, sm: "auto" } }}
            >
              {t("Cancel")}
            </ButtonDX>
          </GridDX>
          <GridDX xs={12} md={4} mt={1} justifyContent="center">
            <LoadingButtonDX
              onClick={() => onSave(CorrespondenceStatus.Draft)}
              loading={isSaving}
              // loadingPosition="end"
            >
              {t("Save as Draft")}
            </LoadingButtonDX>
          </GridDX>
          <GridDX xs={12} md={4} mt={1} justifyContent="center">
            <LoadingButtonDX
              onClick={() => onSave(CorrespondenceStatus.Pending)}
              loading={isSaving}
              // loadingPosition="end"
            >
              {t("Save and Send")}
            </LoadingButtonDX>
          </GridDX>
        </GridDX>
      </DialogActions>
    </Dialog>
  );
};

export default CreateCorrespondenceModal;

const MyDocument = (props: any) => {
  Font.register({
    family: "Rubik",
    fonts: [
      {
        src: "./fonts/Rubik-Regular.ttf",
      },
      {
        src: "./fonts/Rubik-Bold.ttf",
        fontWeight: "bold",
      },
      {
        src: "./fonts/Rubik-Italic.ttf",
        fontWeight: 400,
        fontStyle: "italic",
      },
      {
        src: "./fonts/Rubik-BoldItalic.ttf",
        fontWeight: 800,
        fontStyle: "italic",
      },
    ],
  });

  const styles = StyleSheet.create({
    p: {
      fontFamily: "Rubik",
    },
  });

  return (
    <Document>
      <Page>
        <Html stylesheet={styles}>{props.text}</Html>
        <Image
          style={{ width: 150, height: 50 }}
          src={() => textToBase64Barcode("test barcode")}
        />
      </Page>
    </Document>
  );
};
