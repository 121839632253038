import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import { useState } from "react";
import { useNotificationContext } from "../../context/notificationcontext";
import useMainAttachmentService from "../../shared/services/mainattachmentservice";
import FileViewer from "../../components/business/fileviewer";

const Attachment = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { setInfo, setError } = useNotificationContext();
  const { updateMainAttachment } = useMainAttachmentService();

  const fileName = `${location.state.data.correspondenceId}`;
  const [isLoading, setIsLoading] = useState(false);
  
  const update = async (fileBlob: Blob) => {
    const file = new File([fileBlob], "updatedDoc.pdf", {
      type: "application/pdf",
    });

    const formData = new FormData();
    formData.append(
      "ExistingMainAttachmentId",
      location.state.data.mainAttachmentId
    );
    formData.append("CorrespondenceId", location.state.data.correspondenceId);
    formData.append("MainAttachmentFile", file);

    setIsLoading(true);
    updateMainAttachment(formData)
      .then((result) => {
        setInfo(t("Attachment updated successfully"));
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  return (
    <FileViewer
      fileName={fileName}
      fileURL={location.state.data.mainAttachmentURL}
      applyWatermark={location.state.data.applyWatermark}
      toUpdate={update}
      onBackClick={() => {
        navigate(
          `/correspondencedetails/${location.state.data.correspondenceId}`
        );
      }}
    />
  );
};

export default Attachment;
