const ar = {
  "generic.add": "جمع",
  "generic.cancel": "الغي",

  // BlockType
  "components.controls.blocktype.h1": "العنوان 1",
  "components.controls.blocktype.h2": "العنوان 2",
  "components.controls.blocktype.h3": "العنوان 3",
  "components.controls.blocktype.h4": "العنوان 4",
  "components.controls.blocktype.h5": "العنوان 5",
  "components.controls.blocktype.h6": "العنوان 6",
  "components.controls.blocktype.blockquote": "حظر الاقتباس",
  "components.controls.blocktype.code": "كود",
  "components.controls.blocktype.blocktype": "نوع الكتلة",
  "components.controls.blocktype.normal": "عادي",

  // Color Picker
  "components.controls.colorpicker.colorpicker": "منتقي الألوان",
  "components.controls.colorpicker.text": "نص",
  "components.controls.colorpicker.background": "تمييز",

  // Embedded
  "components.controls.embedded.embedded": "مضمن",
  "components.controls.embedded.embeddedlink": "ارتباط مضمن",
  "components.controls.embedded.enterlink": "أدخل الرابط",

  // Emoji
  "components.controls.emoji.emoji": "الرموز التعبيرية",

  // FontFamily
  "components.controls.fontfamily.fontfamily": "الخط",

  // FontSize
  "components.controls.fontsize.fontsize": "حجم الخط",

  // History
  "components.controls.history.history": "تاريخ",
  "components.controls.history.undo": "تراجع",
  "components.controls.history.redo": "اعاده",

  // Image
  "components.controls.image.image": "صورة",
  "components.controls.image.fileUpload": "تحميل الملف",
  "components.controls.image.byURL": "الرابط",
  "components.controls.image.dropFileText": "قم بإسقاط الملف أو انقر للتحميل",

  // Inline
  "components.controls.inline.bold": "غامق",
  "components.controls.inline.italic": "مائل",
  "components.controls.inline.underline": "تسطير",
  "components.controls.inline.strikethrough": "يتوسطه خط",
  "components.controls.inline.monospace": "أحادي الفضاء",
  "components.controls.inline.superscript": "مرتفع",
  "components.controls.inline.subscript": "منخفض",

  // Link
  "components.controls.link.linkTitle": "عنوان الرابط",
  "components.controls.link.linkTarget": "هدف الارتباط",
  "components.controls.link.linkTargetOption": "فتح الرابط في نافذة جديدة",
  "components.controls.link.link": "رابط",
  "components.controls.link.unlink": "فك ارتباط",

  // List
  "components.controls.list.list": "قائمة",
  "components.controls.list.unordered": "مرتبه",
  "components.controls.list.ordered": "امر",
  "components.controls.list.indent": "المسافه البادئه",
  "components.controls.list.outdent": "المسافة البادئة",

  // Remove
  "components.controls.remove.remove": "أبعد",

  // TextAlign
  "components.controls.textalign.textalign": "محاذاة النص",
  "components.controls.textalign.left": "يسار",
  "components.controls.textalign.center": "مركز",
  "components.controls.textalign.right": "يمين",
  "components.controls.textalign.justify": "برر"
};

export default ar;