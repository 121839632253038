import useSecureAPI from "../hooks/usesecureapi";

const useMainAttachmentService = () => {
  const secureAPI = useSecureAPI();

  const getMainAttachment = async (id: number) => {
    const response = await secureAPI.get(`MainAttachment/${id}`);
    return response.data;
  };

  const updateMainAttachment = async (data: any) => {
    const response = await secureAPI.put(`MainAttachment`, data);
    return response.data; 
  };

  const deleteMainAttachment = async (id: number) => {
    const response = await secureAPI.delete(`MainAttachment/${id}`);
    return response.data;
  };

  return {
    getMainAttachment,
    updateMainAttachment, 
    deleteMainAttachment
  };
};

export default useMainAttachmentService;
