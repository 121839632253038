import useSecureAPI from "../hooks/usesecureapi";

const useCorrespondenceTypeService = () => {
  const secureAPI = useSecureAPI();

  const getCorrespondenceType = async () => {
    const response = await secureAPI.get("CorrespondenceType");
    return response.data;
  };

  const addCorrespondenceType = async (data: any) => {
    const response = await secureAPI.post("CorrespondenceType", data);
    return response.data;
  };

  const updateCorrespondenceType = async (id: number, data: any) => {
    const response = await secureAPI.put(`CorrespondenceType`, data);
    return response.data;
  };

  const deleteCorrespondenceType = async (id: number) => {
    const response = await secureAPI.delete(`CorrespondenceType/${id}`);
    return response.data;
  };

  return {
    getCorrespondenceType,
    addCorrespondenceType,
    updateCorrespondenceType,
    deleteCorrespondenceType,
  };
};

export default useCorrespondenceTypeService;
