import Button from "@mui/material/Button";

const ButtonDX = (props: any) => {
  return (
    <Button
      fullWidth
      variant="contained"
      {...props}
      sx={{
        maxWidth: props.fullWidth ? "100%" : 200,
        height: "max-content",
        ...props.sx,
      }}
    />
  );
};

export default ButtonDX;
