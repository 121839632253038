import { Collapse, Divider, Drawer, IconButton, LinearProgress, List, ListItem, ListItemText, Toolbar, Typography } from "@mui/material";
import TextFieldDX from "../controls/textfielddx";
import ButtonDX from "../controls/buttondx";
import BoxDX from "../layout/boxdx";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Close, Delete, Reply, North } from "@mui/icons-material";
import { useAuthContext } from "../../context/authcontext";
import { DateTimeFormatter } from "../../shared/globals";

const DiscussionDrawer = (props: any) => {
  const { t, i18n } = useTranslation();
  const { userData } = useAuthContext();
  const { userId, calendarId, gregorianDateFormatId, hijriDateFormatId } = userData;
  
  const [comment, setComment] = useState("");
  const [reply, setReply] = useState("");
  const [parentComment, setParentComment] = useState<any>(null);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "reply") setReply(value); 
    else setComment(value);
  };

  return(
    <Drawer
      anchor={ i18n.language === "en" ? "right" : "left" }
      open={props.open}
      sx={{
        height: "100%",
        direction: i18n.dir()
      }}
      SlideProps={{direction: i18n.language === "en" ? "left" : "right" }}
      onClose={props.toClose}
    >
      <Toolbar />
      <BoxDX
        sx={{ width: 350, m: 2 }}
      >
        <BoxDX 
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
        <Typography fontSize={20}>{t("Discussion")}</Typography>
        <IconButton onClick={props.toClose}><Close/></IconButton>
        </BoxDX>
        <Divider />
        {props.isLoading && <LinearProgress/>}
        <List sx={i18n.language === "en" ? {borderLeft: 1} : {borderRight: 1}}>
          {props.commentList?.length > 0 ? props.commentList.map((comment: any) =>
          <>
            <ListItem>
              <ListItemText
                primary={comment.comment}
                secondary={
                  (i18n.language === "en" ? comment.createdByUser?.enFullName ?? "X" : comment.createdByUser?.arFullName ?? "X") +
                  " @ " +
                  DateTimeFormatter(comment.createdDate, calendarId, gregorianDateFormatId, hijriDateFormatId)
                }
                primaryTypographyProps={{ textAlign: "start" }}
                secondaryTypographyProps={{ textAlign: "start" }}
              />
              <IconButton
                size="small"
                sx={{color: "#A0A0A0"}}
                onClick={() => setParentComment(comment)}
              >
                <Reply/>
              </IconButton>
              {(comment.createdByUser?.id === userId) && (comment.childComments.length === 0) && (
                <IconButton
                  size="small"
                  sx={{color: "#A0A0A0"}}
                  onClick={() => props.toDeleteComment(comment)}
                >
                  <Delete/>
                </IconButton>
              )}
            </ListItem>
            <Collapse in={parentComment === comment} timeout="auto" unmountOnExit sx={i18n.language === "en" ? {pl: 3} : {pr: 3}}>
              <BoxDX display="flex" flexDirection="row" sx={{alignItems: "center", justifyContent: "center"}}>
                <TextFieldDX
                  label={t("Reply")}
                  name={"reply"}
                  placeholder={t("Place your reply here")}
                  value={reply}
                  multiline
                  onChange={handleInputChange}
                  sx={{mt: 2}}
                />
                <IconButton
                  size="small"
                  sx={{color: "#A0A0A0"}}
                  disabled={reply.length === 0}
                  onClick={() => {
                    props.toPostReply(reply, parentComment).then(() => setReply(""));
                  }}
                >
                  <North/>
                </IconButton>
                <IconButton
                    size="small"
                    sx={{color: "#A0A0A0"}}
                    onClick={() => {
                      setReply("");
                      setParentComment(null);
                    }}
                  >
                    <Close/>
                </IconButton>
              </BoxDX>
            </Collapse>
            {comment.childComments.length > 0 && (
              <Replies
                commentList={comment.childComments}
                toDeleteComment={props.toDeleteComment}
                toPostReply={props.toPostReply}
              />
            )}
            </>
          ): <Typography width={"100%"} textAlign={"center"}>{t("No Comments")}</Typography>}
        </List>
        <Divider />
        <TextFieldDX
          label={t("Comment")}
          name={"comment"}
          placeholder={t("Place your comment here")}
          value={comment}
          multiline
          rows={2}
          onChange={handleInputChange}
          sx={{mt: 2}}
        />
        <ButtonDX
          sx={{mt: 2}}
          onClick={() => {
            props.toPublishComment(comment).then(()=>setComment(""));
          }}
          >
            {t("Publish")}
          </ButtonDX>
      </BoxDX>
    </Drawer>
  );
};

const Replies = (props: any) => {
  const { t, i18n } = useTranslation();
  const { userData } = useAuthContext();
  const { userId, calendarId, gregorianDateFormatId, hijriDateFormatId } = userData;

  const [reply, setReply] = useState("");
  const [parentComment, setParentComment] = useState<any>(null);

  const handleInputChange = (e: any) => {
    const { value } = e.target;
    setReply(value);
  };

  return(
    <List disablePadding sx={i18n.language === "en" ? {pl: 3} : {pr: 3}} >
      {props.commentList.length > 0 ? props.commentList.map((comment: any) =>
      <>
        <ListItem sx={i18n.language === "en" ? {borderLeft: 1} : {borderRight: 1}}>
          <ListItemText
            primary={comment.comment}
            secondary={ 
              (i18n.language === "en" ? comment.createdByUser?.enFullName ?? "X" : comment.createdByUser?.arFullName ?? "X") +
              " @ " +
              DateTimeFormatter(comment.createdDate, calendarId, gregorianDateFormatId, hijriDateFormatId)
            }
            primaryTypographyProps={{ textAlign: "start" }}
            secondaryTypographyProps={{ textAlign: "start" }}
          />
          <IconButton
            size="small"
            sx={{color: "#A0A0A0"}}
            onClick={() => setParentComment(comment)}
          >
            <Reply/>
          </IconButton>
          {(comment.createdByUser?.id === userId) && (comment.childComments.length === 0) && (
            <IconButton
              size="small"
              sx={{color: "#A0A0A0"}}
              onClick={() => props.toDeleteComment(comment)}
            >
              <Delete/>
            </IconButton>
          )}
        </ListItem>
        <Collapse in={parentComment === comment} timeout="auto" unmountOnExit sx={i18n.language === "en" ? {pl: 3} : {pr: 3}}>
          <BoxDX display="flex" flexDirection="row" sx={{alignItems: "center", justifyContent: "center"}}>
            <TextFieldDX
              label={t("Reply")}
              name={"reply"}
              placeholder={t("Place your reply here")}
              value={reply}
              multiline
              onChange={handleInputChange}
              sx={{mt: 2}}
            />
            <IconButton
              size="small"
              sx={{color: "#A0A0A0"}}
              disabled={reply.length === 0}
              onClick={() => {
                props.toPostReply(reply, parentComment).then(() => setReply(""));
              }}
            >
              <North/>
            </IconButton>
            <IconButton
                size="small"
                sx={{color: "#A0A0A0"}}
                onClick={() => {
                  setReply("");
                  setParentComment(null);
                }}
              >
                <Close/>
            </IconButton>
          </BoxDX>
        </Collapse>
        {comment.childComments.length > 0 && (
          <Replies
            commentList={comment.childComments}
            toDeleteComment={props.toDeleteComment}
            toPostReply={props.toPostReply}
          />
        )}
        </>
      ): <></>}
    </List>
  );
};

export default DiscussionDrawer;