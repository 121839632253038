import { useEffect, useState } from "react";
import AddEditModalDX from "../../business/addeditmodaldx";
import { useTranslation } from "react-i18next";
import TextFieldDX from "../../controls/textfielddx";
import GridDX from "../../layout/griddx";
import { useNotificationContext } from "../../../context/notificationcontext";
import Loading from "../../loading";
import useFolderTemplateService from "../../../shared/services/foldertemplateservice";
import TreeViewDX from "../../treeviewDX";
import { AddCircleOutline, AddOutlined } from "@mui/icons-material";
import StructureModal from "./structureModal";

const StructureTemplateModal = (props: any) => {
  const { open, handleClose, folderTemplateId } = props;
  const { t, i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";

  const { setError, setInfo } = useNotificationContext();

  const { addFolderTemplate, updateFolderTemplate, getFolderTemplateById } =
    useFolderTemplateService();

  const defaultValues = {
    folderTemplateId: 0,
    enName: "",
    arName: "",
    content: "",
  };

  const [dataForTree, setDataForTree] = useState<any>([]);
  const [openStructureModal, setOpenStructureModal] = useState(false);
  const [currentNode, setCurrentNode] = useState<any>(null);
  const [editNode, setEditNode] = useState<any>(null);

  const [structureTemplateData, setStructuretemplateData] =
    useState<any>(defaultValues);
  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const [errors, setErrors] = useState<any>({});

  const validateForm = () => {
    const newErrors: any = {};

    if (structureTemplateData.enName.length === 0)
      newErrors["enName"] = t("English name is required");
    if (structureTemplateData.arName.length === 0)
      newErrors["arName"] = t("Arabic name is required");
    if (dataForTree.length === 0)
      newErrors["content"] = t("Structure is required");
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = () => {
    if (validateForm()) {
      setIsLoading(true);
      if (folderTemplateId) {
        updateFolderTemplate(folderTemplateId, {
          ...structureTemplateData,
          content: JSON.stringify(dataForTree),
        })
          .then((res) => {
            setInfo(t("Structure Template updated successfully"));
            handleClose(true);
          })
          .catch((err) => setError(err))
          .finally(() => setIsLoading(false));
      } else {
        addFolderTemplate({
          ...structureTemplateData,
          content: JSON.stringify(dataForTree),
        })
          .then((res) => {
            setInfo(t("Structure Template created successfully"));
            handleClose(true);
          })
          .catch((err) => {
            setError(err);
          })
          .finally(() => setIsLoading(false));
      }
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsChanged(true);
    setStructuretemplateData({
      ...structureTemplateData,
      [name]: type == "checkbox" ? checked : value,
    });
  };

  useEffect(() => {
    if (folderTemplateId) {
      setIsLoading(true);
      getFolderTemplateById(folderTemplateId)
        .then((res) => {
          const { content, ...rest } = res;
          setStructuretemplateData(rest);
          setDataForTree(JSON.parse(content));
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
  }, [folderTemplateId]);

  const toCreate = (currentNode: any) => {
    setCurrentNode(currentNode);
    setOpenStructureModal(!openStructureModal);
  };

  const onDelete = (nodeId: any) => {
    const findAndDeleteNode = (nodes: any) => {
      for (let node of nodes) {
        if (node.id === nodeId) {
          nodes.splice(nodes.indexOf(node), 1);
          return true;
        } else if (node.children && node.children.length > 0) {
          if (findAndDeleteNode(node.children)) return true;
        }
      }
      return false;
    };

    setDataForTree((prev: any) => {
      const newTree = prev;
      findAndDeleteNode(newTree);
      return newTree;
    });
  };

  const onEdit = (node: any) => {
    setOpenStructureModal(!openStructureModal);
    setEditNode(node);
  };

  const onClose = () => {
    setOpenStructureModal(!openStructureModal);
    setCurrentNode(null);
    setEditNode(null);
  };

  return (
    <>
      {openStructureModal && (
        <StructureModal
          open={openStructureModal}
          handleClose={onClose}
          dataForTree={dataForTree}
          setDataForTree={setDataForTree}
          editNode={editNode}
          currentNode={currentNode}
        />
      )}

      <AddEditModalDX
        open={open}
        handleClose={handleClose}
        isSaving={isLoading}
        isChanged={isChanged}
        title={
          folderTemplateId
            ? t("Update Structure Template")
            : t("Create a new Structure Template")
        }
        maxWidth="xl"
        onSaveClick={onSave}
        onYesClick={handleClose}
      >
        {isLoading && (
          <Loading styles={{ height: "100%", width: "100%", left: 0 }} />
        )}
        <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
          <GridDX item xs={12}>
            <TextFieldDX
              label={t("English Name")}
              name="enName"
              value={structureTemplateData.enName}
              onChange={handleInputChange}
              error={errors["enName"]}
            />
          </GridDX>
          <GridDX item xs={12}>
            <TextFieldDX
              label={t("Arabic Name")}
              name="arName"
              value={structureTemplateData.arName}
              onChange={handleInputChange}
              error={errors["arName"]}
            />
          </GridDX>
          <GridDX item xs={12} sx={{ pb: 2 }}>
            {/* <Typography variant="h6">{t("Structure")}</Typography> */}
            <TextFieldDX
              name="content"
              value={t("Structure")}
              readOnly
              error={errors["content"]}
            />
          </GridDX>
          <GridDX item xs={12}>
            <TreeViewDX
              createLabel={
                <span style={{ display: "flex", alignItems: "center" }}>
                  <AddCircleOutline fontSize="small" /> {t("Structure")}
                </span>
              }
              sx={{ mt: 0 }}
              data={dataForTree}
              toCreate={toCreate}
              onEdit={onEdit}
              onDelete={onDelete}
              name={t("structure")}
            />
          </GridDX>
        </GridDX>
      </AddEditModalDX>
    </>
  );
};

export default StructureTemplateModal;
