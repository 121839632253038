import { Typography, Fab, Chip } from "@mui/material";
import { useState } from "react";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useNotificationContext } from "../../../context/notificationcontext";
import useAttachmentService from "../../../shared/services/attachmentservice";
import ButtonDX from "../../controls/buttondx";
import LoadingButtonDX from "../../controls/loadingbuttondx";
import TextFieldDX from "../../controls/textfielddx";
import BoxDX from "../../layout/boxdx";
import GridDX from "../../layout/griddx";
import AddEditModalDX from "../../business/addeditmodaldx";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';


const AttachmentModal = (props: any) => {
  const { t, i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";
  const { addAttachments } = useAttachmentService();
  const { setError, setInfo } = useNotificationContext();
  const defaultValues = {
    enName: "",
    arName: "",
    description: "",
    attachment: null as null | File
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [data, setData] = useState(defaultValues);
  const [errors, setErrors]= useState<any>({});
  
  // useEffect(()=>{
  //   if (data.isDeliveryNote) setData({
  //     ...data,
  //     enName: "Delivery Note",
  //     arName: "إيصال استلام"
  //   });
  // }, [data]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setIsChanged(true);
    setData({
      ...data,
      [name]: value,
    });
  };

  const validateForm = () => {
    const newErrors: any = {};

    if (data.enName.length === 0)
      newErrors["enName"] = t("This field cannot be empty");
    if (data.arName.length === 0)
      newErrors["arName"] = t("This field cannot be empty");
    if (data.attachment === null){
      newErrors["attachment"] = t("This field cannot be empty");
      setError(t("File is required"));
    }
    
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = () => {
    if(validateForm()) {
      const formData = new FormData();
      const file = data.attachment as File;
      formData.append('CorrespondenceId', props.correspondenceId);
      formData.append('EnName', data.enName);
      formData.append('ArName', data.arName);
      formData.append('Description', data.description);
      formData.append('Attachment', file);
      formData.append('DirLocation', file.name);
      
      setIsLoading(true);
      addAttachments(formData)
      .then(() => {
        setInfo(t("Attachment added successfully"));
        props.handleClose(true);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
    }
  };

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      setIsChanged(true);
      setData({
        ...data,
        attachment: acceptedFiles[0],
      });
    }
  };

  const fileTypeValidator = (file: File) => {
    if (file.type === "" || file.type === "application/octet-stream") {
      setError(t("File type not accepted"));
      return {
        code: "wrong file type",
        message: `File type not accepted`
      };
    }
    else return null;
  }

  return(
    <AddEditModalDX
      open={props.open}
      handleClose={props.handleClose}
      isSaving={isLoading}
      isChanged={isChanged}
      title={t("Add Attachments")}
      maxWidth="md"
      onSaveClick={onSave}
    >
      <GridDX
        container
        sx={{ width: "100%", height: "100%", my: 1 }}
        alignItems="center"
        columnSpacing={1}
        rowSpacing={2}
      >
        <GridDX item xs={6}>
          <TextFieldDX
            label={t("English Name")}
            name="enName"
            value={data.enName}
            onChange={handleInputChange}
            error={errors["enName"]}
          />
        </GridDX>
        <GridDX item xs={6}>
          <TextFieldDX
            label={t("Arabic Name")}
            name="arName"
            value={data.arName}
            onChange={handleInputChange}
            error={errors["arName"]}
          />
        </GridDX>
        <GridDX item xs={12}>
          <TextFieldDX
            label={t("Description")}
            name="description"
            value={data.description}
            onChange={handleInputChange}
          />
        </GridDX>
        {data.attachment === null ?
          <GridDX item xs={12} flexDirection="column">
            <Dropzone validator={fileTypeValidator} maxFiles={1} onDrop={onDrop}>
            {({getRootProps, getInputProps}) => (
              <BoxDX {...getRootProps({className: 'dropzone'})}
              sx={{
                width: "100%",
                height: 100,
                border: "1px dashed grey",
                justifyContent: "center",
                alignItems: "center"
              }}
              >
                <GridDX item xs={12} justifyContent="center">
                  <input {...getInputProps()} />
                </GridDX>
                <GridDX item xs={12} height={100} justifyContent="center" flexDirection="column">
                  <Typography
                    sx={{
                      fontSize: 18,
                      textAlign: "center",
                    }}
                  >
                    {t("Drag & Drop")}
                  </Typography>
                </GridDX>
              </BoxDX>
            )}
          </Dropzone>
          </GridDX>
          : <Chip
              color={"primary"}
              onDelete={() => setData({
                ...data,
                attachment: null,
              })}
              label={data.attachment.name}
              sx={{ mt: 2, mx: 1, minWidth: 150 }}
            />
        }
      </GridDX>
    </AddEditModalDX>
  );
};

export default AttachmentModal;