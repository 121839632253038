import "./App.css";
import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import { AuthProvider, useAuthContext } from "./context/authcontext";
import { NotificationContextProvider } from "./context/notificationcontext";

import LoadingOverlay from "./components/loadingoverlay";
import ProtectedRoute from "./components/route/protectedroute";

import AccountTemplate from "./templates/accounttemplate";
import MainTemplate from "./templates/maintemplate";

import LoginPage from "./pages/account/login";
import Dashboard from "./pages/cts/dashboard";
import CorrespondenceDetails from "./pages/cts/correspondence/correspondencedetails";
import ListPageTemplate from "./templates/listpagetemplate";
import Reports from "./pages/cts/reports";
import EntityTypes from "./pages/cts/settings/entitytypes";
import UserGroups from "./pages/usergroups";
import PrivacyLevels from "./pages/cts/settings/privacylevels";
import ImportanceLevels from "./pages/cts/settings/importancelevel";
import Entity from "./pages/cts/settings/entity";
import DomainTypes from "./pages/cts/settings/domaintypes";
import ContactTypes from "./pages/cts/settings/contacttypes";
import ReferenceTypes from "./pages/cts/settings/referencetypes";
import CorrespondenceTypes from "./pages/cts/settings/correspondencetypes";
import Category from "./pages/cts/settings/category";
import UserList from "./pages/users";
import Attachment from "./pages/cts/attachment";
import ProfileModal from "./components/editmodals/profilemodal";
import GetEmail from "./pages/account/forgetPassword/getEmail";
import ResetPassword from "./pages/account/forgetPassword/resetPassword";
import RoutingGroup from "./pages/cts/routinggroup";
import UserGroupDetails from "./pages/usergroupdetails";
import useRefreshToken from "./shared/hooks/userefreshtoken";
import DMSExplorer from "./pages/dms/dms";
import Cabinet from "./pages/dms/settings/cabinets";
import StructureTemplates from "./pages/dms/settings/structureTemplates";
import DocumentProfiles from "./pages/dms/settings/documentProfiles";
import DocumentTypes from "./pages/dms/settings/documentTypes";
import DocumentCategories from "./pages/dms/settings/documentCategories";
import ContentEditingRequests from "./pages/dms/tools/contenteditingrequests";
import DocumentEditorPage from "./pages/dms/tools/documentEditor";
import DocumentTemplates from "./pages/dms/tools/documenttemplate/documenttemplates";
import AttachmentSearch from "./pages/dms/tools/attachmentsearch";
import DirectoryInformationPage from "./components/pages/DMS/directoryinfopage";
import { ConfigProvider, useConfigContext } from "./context/configcontext";
import FileInformationPage from "./components/pages/DMS/fileinformationpage";
import DocumentTemplate from "./pages/dms/tools/documenttemplate/documenttemplate";
import DMSReports from "./pages/dms/reports";
import Correspondence from "./pages/cts/correspondence/correspondence";

function App() {
  return (
    <ConfigProvider>
      <NotificationContextProvider>
        <AuthProvider>
          <Router>
            <ApplicationRoutes />
          </Router>
        </AuthProvider>
      </NotificationContextProvider>
    </ConfigProvider>
  );
}

const ApplicationRoutes = () => {
  const { authInitialized } = useAuthContext();
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const { isLoggedIn, fullToken, userData, signOut } = useAuthContext();
  const refresh = useRefreshToken();
  const location = useLocation();

  const refreshToken = () => {
    if (userData.rememberMe) {
      refresh()
        .then(() => {
          console.log("refreshed");
        })
        .catch(() => {
          signOut();
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      signOut();
    }
  };

  useEffect(() => {
    if (authInitialized && !isLoggedIn) {
      setLoading(false);
    } else if (authInitialized && isLoggedIn && fullToken) {
      const currentDateTime = new Date().getTime();
      const expiryDateTime = new Date(fullToken.expiryDate).getTime();
      if (expiryDateTime < currentDateTime) {
        refreshToken();
      } else if (
        location.pathname === "/" ||
        location.pathname === "/forget-password"
      ) {
        setLoading(false);
        //to redirect to dashboard if user is logged in and tries to access login page
        navigate("/dashboard");
      } else {
        setLoading(false);
      }
    }
  }, [location.pathname, isLoggedIn, authInitialized]);

  if (loading) return <LoadingOverlay />;
  else
    return (
      <Routes>
        <Route element={<AccountTemplate />}>
          <Route path="/" element={<LoginPage />} />
          <Route path="/forget-password" element={<GetEmail />} />
          <Route path="/ResetPassword" element={<ResetPassword />} />
        </Route>
        <Route
          element={
            <ProtectedRoute>
              <MainTemplate />
            </ProtectedRoute>
          }
        >
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/attachment" element={<Attachment />} />
          <Route path="/documents" element={<DMSExplorer />} />
          <Route path="/documents/:id" element={<FileInformationPage />} />
          <Route path="/directory/:id" element={<DirectoryInformationPage />} />
          <Route path="/userprofile" element={<ProfileModal />} />
          <Route element={<ListPageTemplate />}>
            <Route path="/correspondence" element={<Correspondence />} />
            <Route path="/entitytypes" element={<EntityTypes />} />
            <Route path="/usergroup" element={<UserGroups />} />
            <Route path="/privacylevels" element={<PrivacyLevels />} />
            <Route path="/importancelevels" element={<ImportanceLevels />} />
            <Route path="/entities" element={<Entity />} />
            <Route path="/domaintypes" element={<DomainTypes />} />
            <Route path="/contacttypes" element={<ContactTypes />} />
            <Route path="/referencetypes" element={<ReferenceTypes />} />
            <Route
              path="/correspondencetypes"
              element={<CorrespondenceTypes />}
            />
            <Route path="/category" element={<Category />} />
            <Route path="/users" element={<UserList />} />
            <Route path="/routinggroup" element={<RoutingGroup />} />

            <Route path="/cabinets" element={<Cabinet />} />
            <Route
              path="/structuretemplates"
              element={<StructureTemplates />}
            />
            <Route path="/documentprofiles" element={<DocumentProfiles />} />

            <Route path="/documenttypes" element={<DocumentTypes />} />   

            <Route
              path="/documentcategories"
              element={<DocumentCategories />}
            />

            {/* tools */}
            <Route
              path="/contenteditingrequests"
              element={<ContentEditingRequests />}
            />
            <Route path="/documenteditor" element={<DocumentEditorPage />} />
            <Route path="/templates" element={<DocumentTemplates />} />
            <Route path="/attachmentsearch" element={<AttachmentSearch />} />
          </Route>
          <Route
            path="/correspondencedetails/:id"
            element={<CorrespondenceDetails />}
          />
          <Route path="/groupdetails/:id" element={<UserGroupDetails />} />

          <Route path="/documenttemplate" element={<DocumentTemplate />} />
          <Route path="/dmsreports" element={<DMSReports />} />
          {/* to redirect user to dashboard if he tries to access invalid url */}
          <Route path="*" element={<Navigate to="/dashboard" replace />} />
        </Route>
      </Routes>
    );
};

export default App;
