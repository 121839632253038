import useSecureAPI from "../hooks/usesecureapi";

const useCorrespondenceActionTrailService = () => {
  const secureAPI = useSecureAPI();



  const getActionTrailByCorrespondenceID = async (id: number) => {
    const response = await secureAPI.get(`ActionTrail/forcorrespondence/${id}`);
    return response.data;
  };
 

  return {
    getActionTrailByCorrespondenceID,
  
  };
};

export default useCorrespondenceActionTrailService;
