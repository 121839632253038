import React from "react";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";



const MenuItemDX = (props: any) => {

  const { i18n } = useTranslation()

  return <MenuItem   {...props} sx={{ ...props.sx, direction: i18n.language == "en" ? "ltr" : "rtl" }} />;
};

export default MenuItemDX;
