import useSecureAPI from "../hooks/usesecureapi";

const useUserService = () => {
  const secureAPI = useSecureAPI();

  const getUsers = async () => {
    const response = await secureAPI.get("User");
    return response.data;
  };

  const getActiveUsers = async () => {
    const response = await secureAPI.get("User/active");
    return response.data;
  };

  const getUserById = async (id: number) => {
    const response = await secureAPI.get(`User/${id}`);
    return response.data;
  };
  
  const addUser = async (data: any) => {
    const response = await secureAPI.post("User", data);
    return response.data;
  };

  const updateUser = async (id: number, data: any) => {
    const response = await secureAPI.put(`User`, data);
    return response.data;
  };

  const deleteUser = async (id: number) => {
    const response = await secureAPI.delete(`User/${id}`);
    return response.data;
  };

  return {
    getUsers,
    getUserById,
    addUser,
    updateUser,
    deleteUser,
    getActiveUsers
  };
};

export default useUserService;