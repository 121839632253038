import useSecureAPI from "../hooks/usesecureapi";

const useDomainTypeService = () => {
  const secureAPI = useSecureAPI();

  const getDomainTypes = async () => {
    const response = await secureAPI.get("DomainType");
    return response.data;
  };

  const addDomainType = async (data: any) => {
    const response = await secureAPI.post("DomainType", data);
    return response.data;
  };

  const updateDomainType = async (id: number, data: any) => {
    const response = await secureAPI.put(`DomainType`, data);
    return response.data;
  };

  const deleteDomainType = async (id: number) => {
    const response = await secureAPI.delete(`DomainType/${id}`);
    return response.data;
  };

  return {
    getDomainTypes,
    addDomainType,
    updateDomainType,
    deleteDomainType,
  };
};

export default useDomainTypeService;
