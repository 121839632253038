import { Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { usePDF } from "react-to-pdf";
import { useNotificationContext } from "../../../../context/notificationcontext";
import { statusColors, downloadPDF } from "../../../../shared/globals";
import PieChartDx from "../../../business/piechart";
import ButtonDX from "../../../controls/buttondx";
import FilterRange from "../../../controls/filterrange";
import GridDX from "../../../layout/griddx";
import StaticDataGridDX from "../../../layout/staticdatagriddx";
import Loading from "../../../loading";
import useDMSReportService from "../../../../shared/services/dmsreportservice";


const DMSStatistics = (props: any) => {
  const { filterFromDate, filterToDate, isPdfGenerating, setIsPdfGenerating } =
    props;
  const { t, i18n } = useTranslation();
  const { setError } = useNotificationContext();
  const { getDMSStatistics } = useDMSReportService();
  const [rows, setRows] = useState<any>([]);
  const [dataForPieChart, setDataForPieChart] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  const columns: GridColDef[] = [
    {
      field: "label",
      headerName: `${t("Name")}`,
      flex: 1,
      renderCell(params) {
        if (params.row.label === t("Total")) return <strong>{t("Total")}</strong>
      },
    },
    {
      field: "count",
      headerName: `${t("Count")}`,
      flex: 1,
      renderCell(params) {
        if (params.row.label === t("Total")) return <strong>{params.row.count}</strong>
      },
    },
  ];

  const getData = (formattedFromDate: any, formattedToDate: any) => {
    setIsLoading(true);
    getDMSStatistics(formattedFromDate, formattedToDate) //fetch user data from APi
      .then((res) => {
        let total = 0;
        let data = []
        for (const [key, value] of Object.entries(res)) {
          total += value as number;
          data.push({
            id: key,
            label: t(key),
            value: value as number,
            color: statusColors[Object.keys(res).indexOf(key)],
            count: value
          })
        }
        // let data = res.map((item: any, index: any) => {
        //   total += item.count;
        //   return {
        //     id: item.status,
        //     label: getCorrespondenceStatusValue(item.status),
        //     value: item.count,
        //     color: statusColors[index],
        //   };
        // });
        setDataForPieChart([
          ...data,
        ]);

        setRows([...data, { id: Math.random() * 10, count: total, label: t("Total") }]);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const APIdateFormater = (date: any) => {
    const thisDate = new Date(date);
    const year = date != null && thisDate?.getFullYear();
    const month =
      date != null && String(thisDate.getMonth() + 1).padStart(2, "0");
    const day = date != null && String(thisDate.getDate()).padStart(2, "0");
    return `${year}${month}${day}`;
  };
  useEffect(() => {
    let formattedFromDate =
      filterFromDate && filterFromDate != null
        ? APIdateFormater(filterFromDate)
        : null;
    let formattedToDate =
      filterToDate && filterToDate != null
        ? APIdateFormater(filterToDate)
        : null;
    if (formattedFromDate && formattedToDate) {
      getData(formattedFromDate, formattedToDate);
    }
  }, [i18n.language, filterFromDate, filterToDate]);

  const { toPDF, targetRef } = usePDF({
    filename: t("Statistics"),
    page: {
      margin: 10, // Set the margin in millimeters
      format: "a4", // You can choose 'a4' or other formats
      orientation: "portrait", // 'portrait' or 'landscape'
    },
  });

  return (
    <div>
      {isPdfGenerating && (
        <Loading styles={{ height: "100%", width: "100%", left: 0 }} />
      )}
      <div ref={targetRef}>
        <GridDX
          container
          xs={12}
          sx={{ marginBottom: 20, marginTop: 5, py: 2 }}
        >
          <GridDX item xs={12} sx={{ mt: 1,mb:2 }}>
            <Typography
              variant="h5"
              component="div"
              sx={{ flexGrow: 1, fontWeight: "bold" }}
            >
              {t("Statistics")}
            </Typography>
            {!isPdfGenerating && (
              <ButtonDX
                onClick={() => downloadPDF({ toPDF, setIsPdfGenerating })}
              >
                {t("Download Report")}
              </ButtonDX>
            )}
            {isPdfGenerating && (
              <FilterRange
                filterFromDate={filterFromDate}
                filterToDate={filterToDate}
              />
            )}
          </GridDX>
          <GridDX
            item
            md={6}
            xs={12}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <PieChartDx data={dataForPieChart} />
          </GridDX>
          <GridDX item md={6} xs={12}>
            <StaticDataGridDX
              rows={rows}
              columns={columns}
              getRowId={(row: any) => row.id}
              isLoading={isLoading}
            />
          </GridDX>
        </GridDX>
      </div>
    </div>
  );
};

export default DMSStatistics;
