import { Article, Close, Folder, Image } from "@mui/icons-material";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { IconButton, Icon, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../../context/authcontext";
import { useNotificationContext } from "../../../context/notificationcontext";
import { DateFormatter } from "../../../shared/globals";
import useDocumentService from "../../../shared/services/documentservice";
import useFolderService from "../../../shared/services/folderservice";
import BoxDX from "../../layout/boxdx";
import GridDX from "../../layout/griddx";
import Loading from "../../loading";

const SideBar = (props: any) => {
  const { t, i18n } = useTranslation();
  const { userData } = useAuthContext();
  const { calendarId, gregorianDateFormatId, hijriDateFormatId } = userData;
  const { setError } = useNotificationContext();
  const { getFolderByID } = useFolderService();
  const { getDocumentByID } = useDocumentService();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any>(null)

  useEffect(()=>{
    if (props.item.type === "document") getDocumentData();
    else getFolderData();
  }, [props.item])

  const getFolderData = async () => {
    setIsLoading(true);
    getFolderByID(props.item.id)
      .then((res) => setData(res))
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };
  
  const getDocumentData = async () => {
    setIsLoading(true);
    getDocumentByID(props.item.id)
      .then((res) => setData(res))
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const getDocumentIcon = (fileExtension: string) => {
    if (fileExtension === ".pdf") return (<PictureAsPdfIcon style={{ fontSize: "80px", color: "gray" }}/>);
    else if ([".jpg", ".jpeg", ".png"].includes(fileExtension)) return (<Image style={{ fontSize: "80px", color: "gray" }}/>);
    else return (<Article style={{ fontSize: "80px", color: "gray" }}/>);
  };

  return (
    <GridDX
      direction="column"
      width = {"100%"}
      height = {"100%"}
      sx = {{
        borderLeft: (i18n.language === "en" ? "1px solid #dcdcdc" : "0"),
        borderRight: (i18n.language === "en" ? 0 : "1px solid #dcdcdc")
      }}
    >
      <GridDX 
        item
        xs={12}
        justifyContent="end"
      >
        <BoxDX>
          <IconButton onClick={props.toClose}>
            <Close/>
          </IconButton>
        </BoxDX>
      </GridDX>
      {isLoading
      ? 
        <GridDX item xs={12} justifyContent="center">
          <Loading styles={{ height: "100%", width: "100%" }} />
        </GridDX>
      :
        <>
          <GridDX item xs={12} justifyContent="center">
            <Icon sx={{ width: 100, height: 100}}>
              { data?.fileExtension ?
                getDocumentIcon(data?.fileExtension)
                : <Folder style={{ fontSize: "80px", color: "gray" }}/>
              }
            </Icon>
          </GridDX>
          <GridDX item xs={12} direction="column">
            <Typography sx={{ mx: 2 }}>{`${t("English Name")}: ${data?.enName}`}</Typography>
            <Typography sx={{ mx: 2 }}>{`${t("Arabic Name")}: ${data?.arName}`}</Typography>
            {data?.createdDate ?
              <Typography sx={{ mx: 2 }}>
                {`${t("Creation Date")}: ${
                  DateFormatter(data?.createdDate, calendarId, gregorianDateFormatId, hijriDateFormatId)
                }`}
              </Typography>
              : <></>
            }
            <Typography sx={{ mx: 2 }}>{`${t("Created By")}: ${
                i18n.language === "en" ? data?.createdByUser.enFullName : data?.createdByUser.arFullName
              }`}
            </Typography>
            {data?.modifiedDate ?
              <Typography sx={{ mx: 2 }}>
                {`${t("Last Edit")}: ${
                  DateFormatter(data?.modifiedDate, calendarId, gregorianDateFormatId, hijriDateFormatId)
                }`}
              </Typography>
              : <></>
            }
            {data?.contentType ?
              <Typography sx={{ mx: 2 }}>
                {`${t("File Type")}: ${data?.contentType}`}
              </Typography>
              : <></>
            }
          </GridDX>
        </>
      }
    </GridDX>
  );
};

export default SideBar;