import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from "react";

const ConfigContext = createContext<any | null>(null);
const useConfigContext = () => useContext(ConfigContext);

interface AuxProps {
  children: ReactNode;
}

const ConfigProvider = ({ children }: AuxProps) => {
  const [data, setData] = useState<any | null>([]);
  const [configLoaded, setConfigLoaded] = useState<boolean>(false);

  const [MODULES, setModules] = useState<string[]>(["CTS"]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    fetch("/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        // console.log(response);
        if (response.status === 200) return response.json();
        else return "{}";
      })
      .then((myJson: any) => {
        console.log("config data: ", myJson);
        setData(myJson);
      });
  };

  useEffect(() => {
    if (data.modules) setModules(data.modules);
    setConfigLoaded(true);
  }, [data]);

  return (
    <ConfigContext.Provider
      value={{
        configLoaded,
        MODULES,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export { ConfigProvider, useConfigContext };
