import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const PageTitle = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const routeMap = new Map<string, string>([
    ["dashboard", t("Dashboard")],
    ["correspondence", t("Correspondence")],
    ["reports", t("Reports")],
    ["documents", t("Document Explorer")],
    ["settings", t("Settings")],
    ["entitytypes", t("Entity Types")],
    ["usergroup", t("User Groups")],
    ["privacylevels", t("Privacy Levels")],
    ["importancelevels", t("Importance Levels")],
    ["statustypes", t("Status Types")],
    ["userlist", t("Users List")],
    ["entities", t("Entities")],
    ["domaintypes", t("Domain Types")],
    ["deliverytypes", t("Delivery Types")],
    ["contacttypes", t("Contact Types")],
    ["referencetypes", t("Reference Types")],
    ["correspondencetypes", t("Correspondence Types")],
    ["category", t("Category")],
    ["users", t("Users")],
    ["userprofile", t("Profile")],
    ["statustypes", t("Status Types")],
    ["routinggroup", t("Routing Group")],
    ["cabinets", t("Cabinets")],
    ["structuretemplates", t("Structure Templates")],
    ["documentprofiles", t("Document Profiles")],
    ["documenttypes", t("Document Types")],
    ["documentcategories", t("Document Categories")],
    ["contenteditingrequests", t("Content Editing Requests")],
    ["documenteditor", t("Document Editor")],
    ["templates", t("Document Templates")],
    ["attachmentsearch", t("Attachment Search")],
    ["dmsreports", t("DMS Reports")],
  ]);

  const [pageHeading, setPageHeading] = useState("Dashboard");

  useEffect(() => {
    let pathName = location.pathname.substring(1);
    const idPath = pathName.indexOf("/");
    if (idPath != -1) {
      pathName = pathName.substring(0, idPath);
    }

    updatePageHeading(routeMap.get(pathName));
  }, [location.pathname, i18n.language]);

  const updatePageHeading = async (newPageHeading: string | undefined) => {
    setPageHeading(newPageHeading ?? "Page Title");
  };

  return (
    <Typography
      variant="h4"
      component="div"
      sx={{ flexGrow: 1, fontWeight: "bold", py: 2 }}
    >
      {pageHeading}
    </Typography>
  );
};

export default PageTitle;
