import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNotificationContext } from "../../../context/notificationcontext";
import AddEditModalDX from "../../business/addeditmodaldx";
import ButtonDX from "../../controls/buttondx";
import CheckBoxDX from "../../controls/checkboxdx";
import SelectListDX from "../../controls/selectlistdx";
import TextFieldDX from "../../controls/textfielddx";
import GridDX from "../../layout/griddx";
import Loading from "../../loading";
import {
  ArrowCircleDownOutlined,
  ArrowCircleUpOutlined,
  Delete,
} from "@mui/icons-material";
import useDocumentProfileService from "../../../shared/services/documentprofileservice";

const DocumentProfileModal = (props: any) => {
  const { t, i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";
  const { open, handleClose, documentProfileId, rows, setRows } = props;
  const { setError, setInfo } = useNotificationContext();
  const { getDocumentProfileByID, addDocumentProfile, updateDocumentProfile } =
    useDocumentProfileService();
  const defaultValues = {
    documentProfileId: 0,
    enName: "",
    arName: "",
    list: [],
  };

  const [documentProfileData, setDocumentProfileData] = useState(defaultValues);
  interface ListInterface {
    documentProfileId: number;
    documentProfileFieldId: number;
    enName: string;
    arName: string;
    isMandatory: boolean;
    displayOrder: number | null;
    fieldDataType: number | null;
    isDeleted: boolean | undefined;
  }
  const defaultListValues: ListInterface = {
    documentProfileId: documentProfileId || 0,
    documentProfileFieldId: 0,
    enName: "",
    arName: "",
    isMandatory: false,
    displayOrder: null,
    fieldDataType: 1,
    isDeleted: false,
  };

  // hard coded values for dropdowns
  const typeDropDownData = [
    { value: 1, text: t("Date") },
    { value: 2, text: t("Text") },
  ];

  const [list, setList] = useState([defaultListValues]);
  const [nonDeletedList, setNonDeletedList] = useState([defaultListValues]);

  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const [errors, setErrors] = useState<any>({});

  const validateForm = () => {
    const newErrors: any = {};

    if (documentProfileData.enName.length === 0)
      newErrors["enName"] = t("English name is required");
    if (documentProfileData.arName.length === 0)
      newErrors["arName"] = t("Arabic name is required");

    list.map((item, index) => {
      if (item.enName.length === 0)
        newErrors[`enName${index}`] = t("English name is required");
      if (item.arName.length === 0)
        newErrors[`arName${index}`] = t("Arabic name is required");
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = () => {
    let updatedList = [...nonDeletedList];
    updatedList = [...updatedList, ...list.filter((v) => v.isDeleted)];
    updatedList = updatedList.map((item, index) => {
      return {
        ...item,
        displayOrder: index + 1,
      };
    });

    if (validateForm()) {
      setIsLoading(true);

      documentProfileId
        ? updateDocumentProfile({
            ...documentProfileData,
            documentProfileFields: updatedList,
          })
            .then(() => {
              setInfo(t("Document profile updated successfully"));
              handleClose(true);
            })
            .catch((err) => setError(err))
            .finally(() => setIsLoading(false))
        : addDocumentProfile({
            ...documentProfileData,
            documentProfileFields: updatedList,
          })
            .then(() => {
              setInfo(t("Document profile created successfully"));
              handleClose(true);
            })
            .catch((err) => setError(err))
            .finally(() => setIsLoading(false));
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsChanged(true);
    setDocumentProfileData({
      ...documentProfileData,
      [name]: type == "checkbox" ? checked : value,
    });
  };

  const handleListItemInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { name, value, checked, type } = e.target;
    const updatedList = [...list];
    updatedList[index] = {
      ...updatedList[index],
      [name]: type === "checkbox" ? checked : value,
    };
    setList(updatedList);
  };

  const handleAddListItem = () => {
    setList((prev) => [...prev, defaultListValues]);
  };
  useEffect(() => {
    if (documentProfileId) {
      setIsLoading(true);
      getDocumentProfileByID(documentProfileId)
        .then((res) => {
          const {
            documentProfileFields,
            modifiedDate,
            modifiedBy,
            createdDate,
            createdBy,
            ...rest
          } = res;

          setDocumentProfileData({ ...rest });

          // sort according to display order
          documentProfileFields.sort(
            (a: any, b: any) => a.displayOrder - b.displayOrder
          );

          setList(documentProfileFields);
          //  filter the objects where 'isDeleted' key exist and its value is false
          setNonDeletedList(
            documentProfileFields.filter((e: any) => e.isDeleted !== true)
          );
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
  }, []);

  useEffect(() => {
    setNonDeletedList(list?.filter((e: any) => e.isDeleted !== true));
  }, [list]);

  const handleRemoveListItem = (index: number, id: number) => {
    if (!documentProfileId) {
      const updatedList = [...list];
      updatedList.splice(index, 1);
      setList(updatedList);
    } else {
      // Create a new array with the item removed
      let updatedList = list
        .map((item) =>
          item.documentProfileFieldId === 0
            ? null
            : item.documentProfileFieldId === id
            ? { ...item, isDeleted: true }
            : item
        )
        .filter((item) => item !== null);
      setList(updatedList as any);
    }
  };

  const handleClickUpward = (index: number) => {
    setNonDeletedList((prev) => {
      const current = [...prev];
      const temp = current[index];
      current[index] = current[index - 1];
      current[index - 1] = temp;
      return current;
    });
  };

  const handleClickDownward = (index: number) => {
    setNonDeletedList((prev) => {
      const current = [...prev];
      const temp = current[index];
      current[index] = current[index + 1];
      current[index + 1] = temp;
      return current;
    });
  };

  const ButtonWrapper = (props: any) => {
    const { disabled, onClick } = props;
    return (
      <button
        onClick={onClick}
        disabled={disabled}
        style={{
          border: "none",
          backgroundColor: "transparent",
          cursor: "pointer",
          color: disabled ? "#ccc" : "inherit",
        }}
      >
        {props.children}
      </button>
    );
  };
  return (
    <AddEditModalDX
      open={open}
      handleClose={handleClose}
      isSaving={isLoading}
      isChanged={isChanged}
      title={
        documentProfileId
          ? t("Edit Document Profile")
          : t("Create Document Profile")
      }
      maxWidth="lg"
      onSaveClick={onSave}
      onYesClick={handleClose}
    >
      {isLoading && (
        <Loading styles={{ height: "100%", width: "100%", left: 0 }} />
      )}
      <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
        <GridDX item xs={6}>
          <TextFieldDX
            label={t("English Name")}
            name="enName"
            value={documentProfileData.enName}
            onChange={handleInputChange}
            error={errors["enName"]}
          />
        </GridDX>
        <GridDX item xs={6}>
          <TextFieldDX
            label={t("Arabic Name")}
            name="arName"
            value={documentProfileData.arName}
            onChange={handleInputChange}
            error={errors["arName"]}
          />
        </GridDX>

        <GridDX
          container
          sx={{
            py: "20px",
            position: "relative",
            display: "flex",
            alignItems: "center",
          }}
        >
          <GridDX item xs={12}>
            <Typography
              variant="h6"
              component="div"
              sx={{
                flexGrow: 1,
                fontWeight: "bold",
                textAlign: "center",
                margin: "10px 0",
                width: "100%",
              }}
            >
              {t("PROFILE FIELDS")}
            </Typography>
          </GridDX>

          <GridDX item xs={6} sx={{ position: "absolute", right: 0 }}>
            <ButtonDX sx={{}} onClick={handleAddListItem}>
              {t("Add a Field")}
            </ButtonDX>
          </GridDX>
        </GridDX>

        {nonDeletedList.map((item, index) => {
          return (
            <GridDX
              container
              columnSpacing={1}
              rowSpacing={2}
              sx={{ p: 1 }}
              key={index}
            >
              <GridDX item xs={3}>
                <TextFieldDX
                  label={t("English Name")}
                  name="enName"
                  value={item.enName}
                  onChange={(e: any) => handleListItemInputChange(e, index)}
                  error={errors[`enName${index}`]}
                />
              </GridDX>
              <GridDX item xs={3}>
                <TextFieldDX
                  label={t("Arabic Name")}
                  name="arName"
                  value={item.arName}
                  onChange={(e: any) => handleListItemInputChange(e, index)}
                  error={errors[`arName${index}`]}
                />
              </GridDX>

              <GridDX item xs={2}>
                <SelectListDX
                  label={t("Type")}
                  items={typeDropDownData}
                  name={`fieldDataType`}
                  value={item.fieldDataType}
                  onChange={(e: any) => handleListItemInputChange(e, index)}
                  error={errors[`fieldDataType${index}`]}
                  InputLabelProps={{
                    shrink: item.fieldDataType !== null, // Set to true if there's a value just to handle label position
                  }}
                />
              </GridDX>

              <GridDX item xs={2}>
                <CheckBoxDX
                  name="isMandatory"
                  label={t("Mandatory")}
                  checked={item.isMandatory}
                  onChange={(e: any) => handleListItemInputChange(e, index)}
                />
              </GridDX>

              <GridDX
                item
                xs={1}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "primary.main",
                }}
              >
                <ButtonWrapper
                  onClick={() => handleClickUpward(index)}
                  disabled={index === 0}
                >
                  <ArrowCircleUpOutlined />
                </ButtonWrapper>
                <ButtonWrapper
                  disabled={index === nonDeletedList.length - 1}
                  onClick={() => handleClickDownward(index)}
                >
                  <ArrowCircleDownOutlined />
                </ButtonWrapper>
              </GridDX>
              <GridDX
                item
                xs={1}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "error.main",
                }}
              >
                <ButtonWrapper
                  disabled={false}
                  onClick={() =>
                    handleRemoveListItem(index, item.documentProfileFieldId)
                  }
                >
                  <Delete />
                </ButtonWrapper>
              </GridDX>
            </GridDX>
          );
        })}
      </GridDX>
    </AddEditModalDX>
  );
};

export default DocumentProfileModal;
