import { useEffect, useState } from "react";
import AddEditModalDX from "../business/addeditmodaldx";
import { useTranslation } from "react-i18next";
import TextFieldDX from "../controls/textfielddx";
import GridDX from "../layout/griddx";
import { useNotificationContext } from "../../context/notificationcontext";
import Loading from "../loading";
import Skeleton from "react-loading-skeleton";
import SelectListDX from "../controls/selectlistdx";
import useRoutingGroupService from "../../shared/services/routinggroupservice";
import useEntityService from "../../shared/services/entityservice";
import useUserService from "../../shared/services/userservices";
import Typography from "@mui/material/Typography";
import ButtonDX from "../controls/buttondx";
import CheckBoxDX from "../controls/checkboxdx";
import { ArrowCircleDownOutlined, ArrowCircleUpOutlined, Delete } from "@mui/icons-material";

const RoutingGroupModal = (props: any) => {
  const { t, i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";
  const { open, handleClose, routingGroupId } = props;
  const { setError, setInfo } = useNotificationContext();
  const { getRoutingGroupById, addRoutingGroup, updateRoutingGroup } =
    useRoutingGroupService();
  const { getEntity } = useEntityService();
  const { getActiveUsers } = useUserService();
  const defaultValues = {
    routingGroupId: 0,
    enName: "",
    arName: "",
    isWorkflow: false,
    routes: [],
  };

  const [routingGroupData, setRoutingGroupData] = useState(defaultValues);
  interface RouteDefaultValueInterface {
    routeId: number;
    routingGroupId: number;
    destinationTypeId: number;
    receiverTypeId: string | number | null;
    actionTypeId: number;
    userId: string | null | boolean;
    entityId: string | null | boolean;
    user: {} | string | null;
    entity: {} | string | null;
    routingGroup: {} | string | null;
    requireSignature: boolean;
  }
  const routeDefaultValue: RouteDefaultValueInterface = {
    routeId: 0,
    routingGroupId: 0,
    destinationTypeId: 1,
    actionTypeId: 1,
    receiverTypeId: null,
    userId: null,
    entityId: null,
    user: null,
    entity: null,
    routingGroup: null,
    requireSignature: false,
  };

  // hard coded values for dropdowns
  const destinationTypeDropDownData = [
    { value: 1, text: t("User") },
    { value: 2, text: t("Entity") },
  ];
  const receiverTypeDropDownData = [
    { value: 1, text: t("User") },
    { value: 2, text: t("Not a User") },
  ];
  const actionTypeDropDownData = [
    { value: 1, text: t("For Information") },
    { value: 2, text: t("For Approval") },
  ];
  const [routes, setRoutes] = useState([routeDefaultValue]);

  const [usersDropDownData, setUsersDropDownData] = useState<any>([]);
  const [internalEntitiesDropDownData, setInternalEntitiesDropDownData] =
    useState<any>([]);
  const [externalEntitiesDropDownData, setExternalEntitiesDropDownData] =
    useState<any>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    setIsLoading(true);
    const p1 = getActiveUsers();
    const p2 = getEntity();
    const p3 = routingGroupId ? getRoutingGroupById(routingGroupId) : null;
    Promise.all([p1, p2, p3])
      .then(([users, entities, routingGroup]) => {
        // all users dropdown options
        const dataForUsersDropDown = users.map((res: any) => ({
          text: languageIsEn ? res.enFullName : res.arFullName,
          value: res.id,
        }));
        setUsersDropDownData(dataForUsersDropDown);

        const dataForInternalEntitiesDropDown = entities
          .filter((res: any) => res.entityTypeId == 1)
          .map((res: any) => ({
            text: languageIsEn ? res.enName : res.arName,
            value: res.entityId,
          }));
        const dataForExternalEntitiesDropDown = entities
          .filter((res: any) => res.entityTypeId == 2)
          .map((res: any) => ({
            text: languageIsEn ? res.enName : res.arName,
            value: res.entityId,
          }));

        setInternalEntitiesDropDownData(dataForInternalEntitiesDropDown);
        setExternalEntitiesDropDownData(dataForExternalEntitiesDropDown);

        if (routingGroupId) {
          setRoutingGroupData(routingGroup);
          setRoutes(routingGroup.routes);
        }
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  }, []);

  const validateForm = () => {
    const newErrors: any = {};

    if (routingGroupData.enName.length === 0)
      newErrors["enName"] = t("English name is required");
    if (routingGroupData.arName.length === 0)
      newErrors["arName"] = t("Arabic name is required");

    routes.map((route, index) => {
      if (route.destinationTypeId === 1 && route.userId == null)
        newErrors[`userId${index}`] = t("Receiving user is required");
      if (route.destinationTypeId === 2 && route.entityId == null)
        newErrors[`entityId${index}`] = t("Receiving entity is required");
      if (route.destinationTypeId === 2 && route.receiverTypeId == null)
        newErrors[`receiverTypeId${index}`] = t("Receiver type is required");
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = async () => {
    if (!validateForm()) {
      return;
    }
    const routesWithSequence = routes.map((route: any, index: number) => {
      return {...route, sequenceNumber: index+1};
    });
    setIsLoading(true);
    const operation = routingGroupId
      ? updateRoutingGroup(routingGroupId, {
          ...routingGroupData,
          routes: routesWithSequence,
        })
      : addRoutingGroup({ ...routingGroupData, routes: routesWithSequence });
    operation
      .then(() => {
        setInfo(
          t(
            routingGroupId
              ? "Routing Group updated successfully"
              : "Routing Group created successfully"
          )
        );
        handleClose(true);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsChanged(true);
    setRoutingGroupData({
      ...routingGroupData,
      [name]: type == "checkbox" ? checked : value,
    });
  };

  const handleRouteInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { name, value, checked, type } = e.target;
    const updatedRoutes = [...routes];
    updatedRoutes[index] =
      name == "userId"
        ? {
            ...updatedRoutes[index],
            [name]: value,
            entityId: null,
            receiverTypeId: 1,
          }
        : name == "entityId"
        ? {
            ...updatedRoutes[index],
            entityId: value,
            userId: null,
          }
        : name == "receiverTypeId"
        ? {
            ...updatedRoutes[index],
            receiverTypeId: value,
            entityId: null,
          }
        : {
            ...updatedRoutes[index],
            [name]: type === "checkbox" ? checked : value,
          };
    setRoutes(updatedRoutes);
  };

  const handleAddTemplate = () => {
    setRoutes((prev) => [...prev, routeDefaultValue]);
  };

  const handleRemoveTemplate = (index: number) => {
    // Create a new array with the item removed
    const updatedRoutes = [...routes];
    updatedRoutes.splice(index, 1);

    // Set the updated array using setRoutes
    setRoutes(updatedRoutes);
  };

  const handleClickUpward = (index: number) => {
    setRoutes((prev) => {
      const current = [...prev];
      const temp = current[index];
      current[index] = current[index - 1];
      current[index - 1] = temp;
      return current;
    });
  };

  const handleClickDownward = (index: number) => {
    setRoutes((prev) => {
      const current = [...prev];
      const temp = current[index];
      current[index] = current[index + 1];
      current[index + 1] = temp;
      return current;
    });
  };

  const ButtonWrapper = (props: any) => {
    const { disabled, onClick } = props;
    return (
      <button
        onClick={onClick}
        disabled={disabled}
        style={{
          border: "none",
          backgroundColor: "transparent",
          cursor: "pointer",
          color: disabled ? "#ccc" : "inherit",
        }}
      >
        {props.children}
      </button>
    );
  };

  return (
    <AddEditModalDX
      open={open}
      handleClose={handleClose}
      isSaving={isLoading}
      isChanged={isChanged}
      title={
        routingGroupId ? t("Edit Routing Group") : t("Create Routing Group")
      }
      maxWidth="lg"
      onSaveClick={onSave}
      onYesClick={handleClose}
    >
      {isLoading && (
        <Loading styles={{ height: "100%", width: "100%", left: 0 }} />
      )}
      <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
        <GridDX item xs={6}>
          <TextFieldDX
            label={t("English Name")}
            name="enName"
            value={routingGroupData.enName}
            onChange={handleInputChange}
            error={errors["enName"]}
          />
        </GridDX>
        <GridDX item xs={6}>
          <TextFieldDX
            label={t("Arabic Name")}
            name="arName"
            value={routingGroupData.arName}
            onChange={handleInputChange}
            error={errors["arName"]}
          />
        </GridDX>
        <GridDX item xs={12}>
          <CheckBoxDX
            name="isWorkflow"
            label={t("Is workflow")}
            checked={routingGroupData.isWorkflow}
            onChange={handleInputChange}
          />
        </GridDX>

        { !(routingGroupId && isLoading) &&
          <GridDX
            container
            sx={{
              py: "20px",
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              component="div"
              sx={{
                flexGrow: 1,
                fontWeight: "bold",
                textAlign: "center",
                margin: "10px 0",
                width: "100%",
              }}
            >
              {t("ROUTE TEMPLATES")}
            </Typography>
            <GridDX item xs={6} sx={{ position: "absolute", right: 0 }}>
              <ButtonDX onClick={handleAddTemplate}>
                {t("Add Template")}
              </ButtonDX>
            </GridDX>
          </GridDX>
        }


        { !(routingGroupId && isLoading) &&
          (routes.map((route, index) => {
          return (
            <GridDX
              container
              columnSpacing={1}
              rowSpacing={2}
              sx={{ pt: 1, pb: 1 }}
              key={index}
            >
              {routingGroupData.isWorkflow &&
                <GridDX
                  item
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography textAlign={"center"}>
                    {`${index+1} > `}
                  </Typography>
                </GridDX>
              }
              <GridDX item xs={2}>
                <SelectListDX
                  label={t("Select Destination")}
                  items={destinationTypeDropDownData}
                  name={`destinationTypeId`}
                  value={route.destinationTypeId}
                  onChange={(e: any) => handleRouteInputChange(e, index)}
                  error={errors[`destinationTypeId${index}`]}
                  InputLabelProps={{
                    shrink: route.destinationTypeId !== null, // Set to true if there's a value just to handle label position
                  }}
                />
              </GridDX>
              {route.destinationTypeId == 2 && (
                <GridDX item xs={2}>
                  <SelectListDX
                    label={t("Select Receiver Type")}
                    items={receiverTypeDropDownData}
                    name="receiverTypeId"
                    value={route.receiverTypeId}
                    onChange={(e: any) => handleRouteInputChange(e, index)}
                    error={errors[`receiverTypeId${index}`]}
                    InputLabelProps={{
                      shrink: route.receiverTypeId !== null, // Set to true if there's a value just to handle label position
                    }}
                  />
                </GridDX>
              )}
              {route.destinationTypeId == 1 && (
                <GridDX item xs={3}>
                  <SelectListDX
                    label={t("Select Receiving User")}
                    items={usersDropDownData}
                    name="userId"
                    value={route.userId}
                    onChange={(e: any) => handleRouteInputChange(e, index)}
                    error={errors[`userId${index}`]}
                    InputLabelProps={{
                      shrink: route.userId !== null, // Set to true if there's a value just to handle label position
                    }}
                  />
                </GridDX>
              )}
              {route.destinationTypeId == 2 && (
                <GridDX item xs={5/2}>
                  <SelectListDX
                    label={t("Select Receiving Entity")}
                    items={
                      route.receiverTypeId == 1
                        ? internalEntitiesDropDownData
                        : externalEntitiesDropDownData
                    }
                    name="entityId"
                    value={route.entityId}
                    onChange={(e: any) => handleRouteInputChange(e, index)}
                    error={errors[`entityId${index}`]}
                    InputLabelProps={{
                      shrink: route.entityId !== null, // Set to true if there's a value just to handle label position
                    }}
                  />
                </GridDX>
              )}

              <GridDX item xs={2}>
                <SelectListDX
                  label={t("Select Route Type")}
                  items={actionTypeDropDownData}
                  name="actionTypeId"
                  value={route.actionTypeId}
                  onChange={(e: any) => handleRouteInputChange(e, index)}
                  error={errors[`actionTypeId${index}`]}
                  InputLabelProps={{
                    shrink: route.actionTypeId !== null, // Set to true if there's a value just to handle label position
                  }}
                />
              </GridDX>

              {route.actionTypeId == 2 && (
                <GridDX item xs={3/2}>
                  <CheckBoxDX
                    name="requireSignature"
                    label={t("Signature")}
                    checked={route.requireSignature}
                    onChange={(e: any) => handleRouteInputChange(e, index)}
                  />
                </GridDX>
              )}
              {routingGroupData.isWorkflow &&
                <GridDX
                  item
                  xs={1}
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    color: "primary.main",
                  }}
                >
                  <ButtonWrapper
                    onClick={() => handleClickUpward(index)}
                    disabled={index === 0}
                  >
                    <ArrowCircleUpOutlined />
                  </ButtonWrapper>
                  <ButtonWrapper
                    disabled={index === routes.length - 1}
                    onClick={() => handleClickDownward(index)}
                  >
                    <ArrowCircleDownOutlined />
                  </ButtonWrapper>
                </GridDX>
              }
              <GridDX
                item
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  color: "error.main",
                }}
              >
                <ButtonWrapper
                  disabled={false}
                  onClick={() => handleRemoveTemplate(index)}
                >
                  <Delete />
                </ButtonWrapper>
              </GridDX>
            </GridDX>
          );
        }))}
      </GridDX>
    </AddEditModalDX>
  );
};

export default RoutingGroupModal;
