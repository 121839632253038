import useSecureAPI from "../hooks/usesecureapi";

const useEntityService = () => {
  const secureAPI = useSecureAPI();

  const getEntity = async () => {
    const response = await secureAPI.get("Entity");
    return response.data;
  };
  const getEntityById = async (id: number) => {
    const response = await secureAPI.get(`Entity/${id}`);
    return response.data;
  };

  const addEntity = async (data: any) => {
    const response = await secureAPI.post("Entity", data);
    return response.data;
  };

  const updateEntity = async (id: number, data: any) => {
    const response = await secureAPI.put(`Entity`, data);
    return response.data;
  };

  const deleteEntity = async (id: number) => {
    const response = await secureAPI.delete(`Entity/${id}`);
    return response.data;
  };

  return {
    getEntity,
    getEntityById,
    addEntity,
    updateEntity,
    deleteEntity
  };
};

export default useEntityService;
